import { getSearchUnits } from "./api";

export const fetchSearchUnits = async (
  setLoading,
  name,
  page,
  setNextPage,
  currentData,
  setData,
  handleSetAlert
) => {
  try {
    setLoading(true);
    const { data, nextPage } = await getSearchUnits(name, page);
    setData([...currentData, ...data]);
    setNextPage(nextPage);
    setLoading(false);
  } catch (err) {
    setLoading(false);
    handleSetAlert("error", "Error fetching Units");
  }
};
