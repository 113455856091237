import React, {useState} from 'react';
import Button from '@material-ui/core/Button';
import Divider from '@material-ui/core/Divider';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import LibraryAddIcon from '@material-ui/icons/LibraryAdd';

export default function SimpleMenu(props) {
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      {props.typeClicked == "AddWallet" && 
        <div className={"optionsMenu " + props.addedClasses}>
          <Menu
            id="simple-menu"
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={handleClose}
          >
            <MenuItem className="optionsTitle" disabled>Add Wallet</MenuItem>
            <Divider/>
            <MenuItem className="option" onClick={handleClose}><LibraryAddIcon className="optionIcon" />&nbsp;&nbsp;Collect Full Set</MenuItem>
            {/* <MenuItem className="option" onClick={handleClose}><GroupAddIcon className="optionIcon" />&nbsp;&nbsp;Add to a Team</MenuItem>
            <MenuItem className="option" onClick={handleClose}><LoyaltyIcon className="optionIcon" />&nbsp;&nbsp;Watch</MenuItem>
            <MenuItem className="option" onClick={handleClose}><AssignmentIcon className="optionIcon" />&nbsp;&nbsp;See Details</MenuItem> */}
          </Menu>
        </div>
      }
      {props.typeClicked != "AddWallet" &&
        <div className={"optionsMenu " + props.addedClasses}>
          <Button className="simpleMenuButton" aria-controls="simple-menu" aria-haspopup="true" onClick={handleClick}>
            <MoreVertIcon/>
          </Button>
          <Menu
            id="simple-menu"
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={handleClose}
          >
            <MenuItem className="optionsTitle" disabled>{props.menuTitle}</MenuItem>
            <Divider/>
            <MenuItem className="option" onClick={handleClose}><LibraryAddIcon className="optionIcon" />&nbsp;&nbsp;Collect Full Set</MenuItem>
            {/* <MenuItem className="option" onClick={handleClose}><GroupAddIcon className="optionIcon" />&nbsp;&nbsp;Add to a Team</MenuItem>
            <MenuItem className="option" onClick={handleClose}><LoyaltyIcon className="optionIcon" />&nbsp;&nbsp;Watch</MenuItem>
            <MenuItem className="option" onClick={handleClose}><AssignmentIcon className="optionIcon" />&nbsp;&nbsp;See Details</MenuItem> */}
          </Menu>
        </div>
      }
    </>
  );
}