import * as React from 'react';
import {useState} from 'react';
import config from "../../../config"
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Moment from "moment";

import CustModal from './modal';


export default function DenseTable(props) {

  const [toggleModal, setToggleModal] = useState("hide");
  const [currentUnit, setCurrentUnit] = useState();

  const openModal = (unit) => {
    setCurrentUnit(unit)
    console.log(unit.name + " clicked")
    setToggleModal("show")
  };

  const closeModal = () => {
    console.log("closing modal")
    setToggleModal("hide")
  };

  const handleSortBy = (e) => {
    console.log("sort by " + e.target.text)
  }

  return (
    <>
    <CustModal unit={currentUnit} modalRef={toggleModal} hideModal={closeModal} />
    <TableContainer className="table-view-container" component={Paper}>
      <Table sx={{ minWidth: 650 }} size="small" aria-label="a dense table">
        <TableHead>
          <TableRow>
            <TableCell></TableCell>
            <TableCell onClick={() => handleSortBy} align="center">Name</TableCell>
            <TableCell align="center">Price</TableCell>
            <TableCell align="center">Rarity</TableCell>
            <TableCell align="center">Set</TableCell>
            <TableCell align="center">Release <br/> Date</TableCell>
            <TableCell align="center">Number <br/> Owned</TableCell>
            {/* <TableCell align="center">Category</TableCell> */}
          </TableRow>
        </TableHead>
        <TableBody>
          {props.units.map((unit) => (
            <TableRow
              key={unit.name}
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
              <TableCell align="right" className="pictd" onClick={() => openModal(unit)}>View</TableCell>
              {unit.name &&
                <TableCell align="left">{unit.name}</TableCell>
              }
              {!unit.name &&
                <TableCell align="left">No Name Found</TableCell>
              }
              {unit.prices[0] &&
                <TableCell align="left">${parseFloat(unit.prices[0].market).toFixed(2)}</TableCell>
              }
              {!unit.prices[0] &&
                <TableCell align="left">No Price Found</TableCell>
              }
              {unit.attributes[1] && unit.attributes[1].name == "Rarity" &&
                <TableCell align="left">{unit.attributes[1].value}</TableCell>
              }
              {!unit.attributes[1] || unit.attributes[1].name !== "Rarity" &&
                <TableCell align="left">No Rarity Found</TableCell>
              }
              {unit.set &&
                <TableCell align="left">{unit.set.name}</TableCell>
              }
              {!unit.set &&
                <TableCell align="left">No Set Name Found</TableCell>
              }
              {unit.set &&
                <TableCell align="left">{Moment(unit.set.releaseDate).format('MMM d, YYYY')}</TableCell>
              }
              {!unit.set &&
                <TableCell align="left">No Release Date Found</TableCell>
              }
              {unit.collectionUnitQty !== 0 &&
                <TableCell align="center">{unit.collectionUnitQty}</TableCell>
              }
              {!unit.collectionUnitQty &&
                <TableCell align="center">0</TableCell>
              }
              {/* {unit.game.name &&
                <TableCell align="left">{unit.game.name}</TableCell>
              }
              {!unit.game.name &&
                <TableCell align="left">No Category Found</TableCell>
              } */}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
    </>
  );
}
