import React from "react";
import { BeatLoader } from "react-spinners";
import { css } from "@emotion/react";

const CommonDotLoader = (props) => {
  const { loading, loadColor, size, margin, classAppend } = props;
  const override = css`
    margin-left: -1px;
    margin-top: -40px;
    margin-bottom: 20px;
    width: 90vw;
  `;
  return (
    <div className={classAppend}>
      <BeatLoader
        color={loadColor || "rgb(80,80,80)"}
        loading={loading}
        css={override}
      />
    </div>
  );
};

export default CommonDotLoader;
