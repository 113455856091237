import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { fetchMagicSetUnits, fetchEditSetData } from "./controller";
import { Button } from "@material-ui/core";
import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import Typography from "@material-ui/core/Typography";
import { useParams } from "react-router-dom";
import { RiAddBoxLine } from "react-icons/ri";
import config from "../../../config";
import { BeatLoader } from "react-spinners";

const MagicUnitsAdmin = (props) => {
  const { handleSetAlert } = props;
  const { setID } = useParams();
  const [loading, setLoading] = useState(false);
  const [nextLoading, setNextLoading] = useState(false);
  const [units, setUnits] = useState([]);
  const [name, setName] = useState();
  const [isActive, setIsActive] = useState(false);
  const [releaseDate, setReleaseDate] = useState(new Date());
  const [setTypes, setSetTypes] = useState([]);
  const [type, setType] = useState("");
  const [nextPage, setNextPage] = useState(1);
  const [loadColor, setLoadColor] = useState("#0051a2");

  useEffect(() => {
    fetchMagicSetUnits(
      setLoading,
      setID,
      units,
      setUnits,
      nextPage,
      setNextPage,
      handleSetAlert
    );
    fetchEditSetData(
      handleSetAlert,
      setLoading,
      setSetTypes,
      setID,
      setName,
      setType,
      setReleaseDate,
      setIsActive
    );
  }, []);

  const handleLoadMore = () => {
    if (nextPage) {
      fetchMagicSetUnits(
        setNextLoading,
        setID,
        units,
        setUnits,
        nextPage,
        setNextPage,
        handleSetAlert
      );
    }
  };

  return (
    <div className="homeContainer adminContainer">
      <div className="adminLinkList">
        <Breadcrumbs separator="›" aria-label="breadcrumb">
          <Link color="inherit" to={"/admin"}>
            Admin
          </Link>
          <Link color="inherit" to={"admin/mtg/sets"}>
            MTG
          </Link>
          <Typography color="textPrimary">{name} (cards)</Typography>
        </Breadcrumbs>
      </div>
      <h1>{name} Cards</h1>
      <Link to={`/admin/mtg/sets/${setID}/units/new`}>
        <Button className="newSetBtn" size="large" color="primary">
          <RiAddBoxLine /> &nbsp; Add Card
        </Button>
      </Link>
      <hr></hr>
      {loading && <h3>Loading...</h3>}
      {!loading && units.length > 0 && (
        <div className="editUnitContainer">
          {units.map((unit) => {
            return (
              <div className="editUnitCell" key={unit.id}>
                <p>{unit.name}</p>
                <Link to={`/admin/mtg/sets/${setID}/units/edit/${unit.id}`}>
                  <img
                    src={
                      unit.imageLink
                        ? `${config.mediaUrl}/${unit.imageLink}`
                        : ""
                    }
                  />
                  <br />
                  <Button
                    className="editUnitBtn"
                    variant="outlined"
                    size="medium"
                    color="primary"
                  >
                    Edit Card
                  </Button>
                </Link>
              </div>
            );
          })}
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
        </div>
      )}
      <BeatLoader color={loadColor} loading={nextLoading} />
      {nextPage && (
        <>
          <br />
          <br />
          <Button
            variant="outlined"
            size="medium"
            color="primary"
            onClick={handleLoadMore}
          >
            Load More...
          </Button>
          <br />
          <br />
          <br />
          <br />
          <br />
        </>
      )}
      {units.length == 0 && (
        <div>
          <br />
          <br />
          <h1>There are no cards currently in this set.</h1>
        </div>
      )}
      {units.length == 0 && (
        <div>
          <br />
          <br />
          <h1>There are currently no cards in this set.</h1>
        </div>
      )}
    </div>
  );
};

export default MagicUnitsAdmin;
