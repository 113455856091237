import React, { useState, useEffect } from "react";
import { fetchSetTypes } from "./controller";
import { insSet } from "./api";
import Button from "../../shared/common/button";
import Select from "../../shared/common/select";
import Input from "../../shared/common/input";
import DatePicker from "../../shared/common/date-picker";
import Breadcrumbs from "../../shared/common/breadcrumbs";

const NewMagicSet = (props) => {
  const { handleSetAlert } = props;
  const [loading, setLoading] = useState(false);
  const [setTypes, setSetTypes] = useState([]);
  const [name, setName] = useState("");
  const [type, setType] = useState("");
  const [releaseDate, setReleaseDate] = useState(new Date());

  useEffect(() => {
    fetchSetTypes(setLoading, setSetTypes);
  }, []);

  const handleSubmit = async () => {
    try {
      const values = {
        setTypeID: type,
        name,
        releaseDate,
      };
      await insSet(values);
      setName("");
      setType("");
      setReleaseDate(new Date());
      handleSetAlert("success", "Set Created!");
    } catch (err) {
      if (err.response.data.message) {
        handleSetAlert("warning", err.response.data.message);
      } else {
        handleSetAlert("error", err.message);
      }
    }
  };

  const breadcrumbData = [
    {
      name: "Admin",
      link: "/admin",
    },
    {
      name: "MTG",
      link: "/admin/mtg/sets",
    },
    {
      name: `${name} (set)`,
      color: "textPrimary",
    },
  ];

  return (
    <div className="homeContainer adminContainer newSetContainer">
      <div className="adminLinkList">
        <Breadcrumbs classAppend="newSet" data={breadcrumbData} />
      </div>
      <br />
      <h1 className="pageTitle">New MTG Set</h1>
      {loading && <h3>Loading...</h3>}
      {!loading && setTypes.length > 0 && (
        <div>
          <Select
            classAppend="newSetDrpDown"
            variant="outlined"
            idAppend="set-type"
            label="Set Type"
            value={type}
            data={setTypes}
            dataKey="id"
            dataValue="id"
            dataName="name"
            handleChange={(e) => setType(e.target.value)}
          />
          <br />
          <Input
            classAppend="newSetTextBox"
            idAppend="set-name"
            label="Set Name"
            variant="outlined"
            value={name}
            handleChange={(e) => setName(e.target.value)}
          />
          <center>
            <DatePicker
              classAppend="newSetDate"
              idAppend="release-date"
              variant="inline"
              label="Release Date"
              value={releaseDate}
              handleChange={(e) => setReleaseDate(e)}
              disableToolbar={true}
              KeyboardButtonProps={{
                "aria-label": "change date",
              }}
            />
            <br />
          </center>
          <Button
            variant="outlined"
            size="large"
            color="primary"
            handleClick={handleSubmit}
            className="newSetSubmit"
            text="Add Set"
            customStyle={{ color: "green", borderColor: "green" }}
          />
        </div>
      )}
    </div>
  );
};

export default NewMagicSet;
