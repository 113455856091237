import React, { useState } from "react";
// import http from "../../services/http";
// import config from "../../config";
import { Grid, Typography, TextField, Button } from "@material-ui/core";
import { useStripe, useElements, CardElement } from "@stripe/react-stripe-js";
import { insSubscription } from "../controller";

const AddSubscription = (props) => {
  const { handleSetUser, handleSetAlert } = props;
  const [loading, setLoading] = useState(false);
  const [billingName, setBillingName] = useState("");
  const stripe = useStripe();
  const elements = useElements();

  const handleSub = async (event) => {
    try {
      // We don't want to let default form submission happen here,
      // which would refresh the page.
      event.preventDefault();
      console.log("Button pushed");

      if (!stripe || !elements || billingName === "") {
        // Stripe.js has not yet loaded.
        // Make sure to disable form submission until Stripe.js has loaded.
        return;
      }

      setLoading(true);

      // Sripe Create payment method
      const payMethodObj = {
        type: "card",
        card: elements.getElement(CardElement),
        billing_details: {
          name: billingName,
        },
      };
      const { paymentMethod } = await stripe.createPaymentMethod(payMethodObj);

      await insSubscription(paymentMethod.id, handleSetUser);

      setLoading(false);
      handleSetAlert("success", "Subscription created. Thank you!");
    } catch (err) {
      if (err.response) {
        handleSetAlert("warning", err.response.data.message);
      } else {
        handleSetAlert("error", err.message);
      }
    }
  };

  const CARD_ELEMENT_OPTIONS = {
    style: {
      base: {
        "color": "#32325d",
        "fontFamily": '"Helvetica Neue", Helvetica, sans-serif',
        "fontSmoothing": "antialiased",
        "fontSize": "16px",
        "::placeholder": {
          color: "#aab7c4",
        },
      },
      invalid: {
        color: "#fa755a",
        iconColor: "#fa755a",
      },
    },
  };

  return (
    <div className="manage-subscription">
      <Grid
        container
        direction="column"
        justify="flex-start"
        alignItems="center"
        spacing={5}
      >
        <Grid item xs={12}>
          <Typography variant="h4">Manage Subscription</Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography>Sign up for Premium $2 per month</Typography>
        </Grid>
        <Grid item xs={12}>
          <TextField
            id="billing-name"
            label="Billing Name"
            variant="outlined"
            value={billingName}
            onChange={(e) => setBillingName(e.target.value)}
          />
        </Grid>
        <Grid item xs={12}>
          <form onSubmit={handleSub}>
            <label className="stripe-label">
              Card details
              <CardElement options={CARD_ELEMENT_OPTIONS} />
            </label>
            <button disabled={!stripe}>Checkout</button>
          </form>
        </Grid>
      </Grid>
    </div>
  );
};

export default AddSubscription;
