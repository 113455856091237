import React from "react";
import { TextField } from "@material-ui/core";

const CommonInput = (props) => {
  const {
    classAppend,
    idAppend,
    variant,
    customStyle,
    label,
    value,
    handleChange,
  } = props;
  const className = classAppend ? `cmn-ipt ${classAppend}` : "cmn-ipt";
  const id = idAppend ? `cmn-ipt-id-${idAppend}` : "cmn-ipt-id";
  return (
    <TextField
      className={className}
      id={id}
      variant={variant}
      style={customStyle}
      label={label}
      value={value}
      onChange={handleChange}
    />
  );
};

export default CommonInput;
