import * as React from "react";
import { useState, useEffect } from "react";
import * as _ from "lodash";
import Button from "../shared/common/button";
import SearchAutocomplete from "./search-autocomplete";
import { bulkAddUnits, getUnitsSearch } from "../shared/api";
import CustAutocomplete from "./custom-autocomplete";

const BulkAdd = (props) => {
  const { showBulkAdd, setShowBulkAdd, collection, setCollection } = props;

  const [nameAdd, setNameAdd] = useState();
  const [IDAdd, setIDAdd] = useState();
  const [qtyAdd, setQtyAdd] = useState();

  const [bulkAddList, setBulkAddList] = useState([]);
  const [unitsRefList, setUnitsRefList] = useState([]);

  const inputRef = React.useRef(null);

  const handleAddUnit = () => {
    const addRow = { name: nameAdd, id: IDAdd, qty: qtyAdd };
    console.log(addRow);
    setBulkAddList([...bulkAddList, addRow]);
    setNameAdd();
    setIDAdd("");
    setQtyAdd("");
  };

  const submitBulkAdd = async () => {
    if (bulkAddList) {
      const bulkAddBody = [];
      for (let i = 0; i < bulkAddList.length; i++) {
        bulkAddBody.push({
          unitID: bulkAddList[i].id,
          qty: bulkAddList[i].qty,
        });
        if (
          parseInt(
            _.findIndex(collection, { id: parseInt(bulkAddList[i].id) }) + 1
          )
        ) {
          const collIndex = _.findIndex(collection, {
            id: parseInt(bulkAddList[i].id),
          });
          collection[collIndex].collectionUnitQty =
            parseInt(collection[collIndex].collectionUnitQty) +
            parseInt(bulkAddList[i].qty);
        } else {
          const thisref = await getUnitsSearch(
            bulkAddList[i].name,
            1,
            setUnitsRefList
          );
          const listref = thisref.data;
          const refIndex = _.findIndex(listref, {
            id: parseInt(bulkAddList[i].id),
          });
          const addUnitRef = listref[refIndex];
          addUnitRef.collectionUnitQty = bulkAddList[i].qty;
          const collRef = [addUnitRef, ...collection];
          const finalCollRef = [...collRef].sort((a, b) =>
            a.name > b.name ? 1 : -1
          );
          setCollection(finalCollRef);
        }
      }
      bulkAddUnits(bulkAddBody, setCollection);
    } else {
      console.log("error bulk adding");
    }
    setShowBulkAdd(false);
    setBulkAddList([]);
    setNameAdd();
    setIDAdd("");
    setQtyAdd("");
  };

  const clearAll = () => {
    setBulkAddList([]);
    setNameAdd();
    setIDAdd("");
    setQtyAdd("");
  };

  const deleteTableRow = (index) => {
    const rows = [...bulkAddList];
    rows.splice(index, 1);
    setBulkAddList(rows);
  };

  return (
    <React.Fragment>
      <div
        className={showBulkAdd ? "bulk-add-bg" : "hide"}
        onClick={() => setShowBulkAdd(false)}
      ></div>
      <div className={showBulkAdd ? "bulk-add-container" : "hide"}>
        <table>
          <tr>
            <td>
              {/* <input
                onChange={(e) => {
                  setNameAdd(e.target.value);
                }}
                placeholder="Name..."
                type="text"
              /> */}
              <SearchAutocomplete
                classAppend={"bulk-add-search"}
                placeholder={"Search Unit Names..."}
                setNameAdd={setNameAdd}
                setIDAdd={setIDAdd}
                nameValue={nameAdd}
                inputRef={inputRef}
              />
              {/* <CustAutocomplete
                setNameAdd={setNameAdd}
                setIDAdd={setIDAdd}
                nameValue={nameAdd}
                inputRef={inputRef}
              /> */}
            </td>
            <td>
              <input
                onChange={(e) => {
                  setIDAdd(e.target.value);
                }}
                value={IDAdd}
                placeholder="ID..."
                type="text"
              />
            </td>
            <td>
              <input
                onChange={(e) => {
                  setQtyAdd(e.target.value);
                }}
                value={qtyAdd}
                placeholder="Quantity..."
                type="text"
                ref={inputRef}
              />
            </td>
            <td>
              <Button
                disabled={true}
                handleClick={() => handleAddUnit()}
                text={"Add"}
              />
            </td>
          </tr>
        </table>
        {bulkAddList.length > 0 && (
          <div className="content-table">
            <table>
              <tr>
                <th>Name</th>
                <th>ID</th>
                <th>Quantity</th>
                <th className="remove-head"></th>
              </tr>
              {bulkAddList.map((row, index) => {
                return (
                  <tr>
                    <td className="name-td">{row.name}</td>
                    <td className="id-td">{row.id}</td>
                    <td className="qty-td">{row.qty}</td>
                    <td className="remove-td">
                      <Button
                        classAppend={"bulk-add-remove"}
                        text={"Remove"}
                        handleClick={() => deleteTableRow(index)}
                      />
                    </td>
                  </tr>
                );
              })}
            </table>
          </div>
        )}
        {bulkAddList.length > 0 && (
          <React.Fragment>
            <Button
              classAppend={"bulk-add-clear"}
              text={"Clear All"}
              handleClick={clearAll}
            />
            <Button
              classAppend={"bulk-add-submit"}
              text={"Submit"}
              handleClick={submitBulkAdd}
            />
          </React.Fragment>
        )}
      </div>
    </React.Fragment>
  );
};

export default BulkAdd;
