import React, { useState, useEffect } from "react";
import CollectionHeader from "../collections/components/header";
import Tabs from "../shared/tabs";
import config from "../../config";
import PokemonUnits from "./units";
import PMHeader from "./components/header";
import DeckBuilder from "./deck-builder";
import TabHead from "./components/tab-header";
import PMLogo from "../../img/pokeLogo.png";

const Main = (props) => {
  const { handleSetAlert, user } = props;
  const [filtersObj, setFiltersObj] = useState([]);
  const [filtersChangeFlag, setFiltersChangeFlag] = useState();
  const [sortBy, setSortBy] = useState("Sales DESC");
  const [sortByChange, setSortByChange] = useState();
  const [cards, setCards] = useState();

  const [view, setView] = useState();
  const [activeTab, setActiveTab] = useState("Cards");

  useEffect(() => {
    if (!localStorage.getItem("viewPref")) {
      localStorage.setItem("viewPref", "grid");
      setView("grid");
    } else {
      setView(localStorage.getItem("viewPref"));
    }
  }, [view]);

  return (
    <div className="general-game-container">
      {/* <div className="set-list-container"> */}
      <TabHead setActiveTab={setActiveTab} activeTab={activeTab} />
      <PMHeader
        game="Pokemon"
        gameLogo={PMLogo}
        activeTab={activeTab}
        setActiveTab={setActiveTab}
        setView={setView}
        activeView={view}
        filtersObj={filtersObj}
        sortBy={sortBy}
        setSortBy={setSortBy}
        setFiltersObj={setFiltersObj}
        filtersChangeFlag={filtersChangeFlag}
        sortByChange={sortByChange}
        setSortByChange={setSortByChange}
        setFiltersChangeFlag={setFiltersChangeFlag}
      />
      <div className="tabContainer">
        {activeTab === "Cards" && (
          <div className="units-container">
            <PokemonUnits
              view={view}
              filtersChangeFlag={filtersChangeFlag}
              setFiltersChangeFlag={setFiltersChangeFlag}
              setFiltersObj={setFiltersObj}
              filtersObj={filtersObj}
              handleSetAlert={handleSetAlert}
              sortBy={sortBy}
              sortByChange={sortByChange}
              setSortByChange={setSortByChange}
              user={user}
            />
          </div>
        )}
        {activeTab === "Deck Builder" && (
          <div className="stocks-container mtg-stocks-container">
            <DeckBuilder
              view={view}
              filtersObj={filtersObj}
              handleSetAlert={handleSetAlert}
              filtersChangeFlag={filtersChangeFlag}
              setFiltersChangeFlag={setFiltersChangeFlag}
              sortBy={sortBy}
              sortByChange={sortByChange}
              setSortByChange={setSortByChange}
              setCards={setCards}
            />
          </div>
        )}
      </div>
      {/* </div> */}
    </div>
  );
};

export default Main;
