import React from "react";
import { Route, Redirect } from "react-router-dom";
import { authCheck, modCheck } from "../services/auth";

const ModRoute = (props) => {
  const { component: Component, path } = props;
  return (
    <Route
      path={path}
      render={() => {
        return authCheck() && modCheck() ? (
          <Component {...props} />
        ) : (
          <Redirect to="/login" />
        );
      }}
    />
  );
};

export default ModRoute;
