import React from "react";
import { ClipLoader } from "react-spinners";

const CommonCircleLoader = (props) => {
  const { loading, loadColor, size } = props;
  return (
    <ClipLoader
      color={loadColor || "#0051a2"}
      size={size || 95}
      loading={loading}
    />
  );
};

export default CommonCircleLoader;
