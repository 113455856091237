import React, { useState, useEffect } from "react";
import config from "../../config";
// import mtglogo from "./../../img/magicLogo.png";
import Button from "./common/button";
import { HiMinusSm, HiPlus } from "react-icons/hi";
import { IoMdArrowDropup, IoMdArrowDropdown } from "react-icons/io";
import { insCollectionUnit, delCollectionUnit } from "../collections/api";
import { authCheck } from "../../services/auth";
import noImage from "../../img/noImage.png";
import * as _ from "lodash";

const UnitMod = (props) => {
  const {
    onModalToggle,
    unit,
    trendFlag,
    trendDirection,
    handleSetAlert,
    units,
    setUnits,
    user,
    builderFlag,
    currentUnit,
  } = props;
  const isAuth = authCheck();

  const [qtyRef, setQtyRef] = useState(0);

  useEffect(() => {
    setQtyRef(unit.collectionUnitQty);
  }, []);

  const handleCollectionAdd = async (e) => {
    try {
      const idArray = e.currentTarget.id.split("-");
      const unitID = idArray[idArray.length - 1];
      const { qty } = await insCollectionUnit(unitID);
      handleUpdateHasUnit(unitID, qty);
      const newQty = qtyRef + 1;
      setQtyRef(newQty);
    } catch (err) {
      if (err.response) {
        handleSetAlert("warning", err.response.data.message);
      } else {
        handleSetAlert("error", err.message);
      }
    }
  };

  const handleCollectionRemove = async (e) => {
    try {
      const idArray = e.currentTarget.id.split("-");
      const unitID = idArray[idArray.length - 1];
      const { qty } = await delCollectionUnit(unitID);
      handleUpdateHasUnit(unitID, qty);
      if (qtyRef > 0) {
        const newQty = qtyRef - 1;
        setQtyRef(newQty);
      }
    } catch {
      // } catch (err) {
      // if (err.response) {
      //   handleSetAlert("warning", err.response.data.message);
      // } else {
      //   handleSetAlert("error", err.message);
      // }
      console.log("no units in qty");
    }
  };

  const handleUpdateHasUnit = (unitID, unitQty) => {
    const updUnits = [...units];
    const unitIndex = _.findIndex(updUnits, { id: parseInt(unitID) });
    updUnits[unitIndex].collectionUnitQty = unitQty;
    setUnits(updUnits);
  };

  return (
    <div className={isAuth ? "unit-mod" : "unit-mod no-user-mod"}>
      <div className="unit-header">
        {unit.game && (
          <p className={"gamecode " + unit.game.code}>
            <img src={`${config.mediaUrl}/${unit.game.imageLink}`} />
          </p>
        )}
        {unit.tcgp && (
          <a href={unit.tcgp ? unit.tcgp.link : "#"} target="_blank">
            <h3 className="unit-price">
              {unit.prices && unit.prices.length > 0
                ? `$${unit.prices[0].market.toFixed(2)}`
                : "$-"}
            </h3>
          </a>
        )}
        {trendFlag && trendDirection == "up" && (
          <p className="trendDets" style={{ color: "green" }}>
            <IoMdArrowDropup />
            {unit.movement.change}
          </p>
        )}
        {trendFlag && trendDirection == "down" && (
          <p className="trendDets" style={{ color: "red" }}>
            <IoMdArrowDropdown />
            {unit.movement.change}
          </p>
        )}
        <h2 className="tooltipped">
          {unit.cleanName || unit.name}
          <span>{unit.cleanName || unit.name}</span>
        </h2>
      </div>
      {unit.images && (
        <img
          className="unit-pic"
          onClick={onModalToggle}
          src={unit.images.small ? unit.images.small : noImage}
          onError={({ currentTarget }) => {
            currentTarget.onerror = null; // prevents looping
            currentTarget.src = noImage;
          }}
          // onError={(e)=>{e.target.onerror = null; e.target.src="./../../img/noImage.png"}}
          // alt={unit.name}
        />
      )}
      {!unit.images && (
        <img
          className="unit-pic"
          onClick={onModalToggle}
          src={unit.imageLink ? unit.imageLink : noImage}
          onError={({ currentTarget }) => {
            currentTarget.onerror = null; // prevents looping
            currentTarget.src = noImage;
          }}
          // onError={(e)=>{e.target.onerror = null; e.target.src="./../../img/noImage.png"}}
          // alt={unit.name}
        />
      )}
      {isAuth && (
        <div className="unit-button-container">
          {!builderFlag && (
            <Button
              classAppend={
                unit.collectionUnitQty > 0
                  ? "collection-remove"
                  : "collection-remove disable-remove"
              }
              idAppend={unit.id}
              variant="outlined"
              size="small"
              handleClick={handleCollectionRemove}
              text={"-"}
              // text={<HiMinusSm />}
            />
          )}
          {unit.collectionUnitQty > 0 && (
            <h4 className="unitqty">
              {trendFlag
                ? qtyRef > 0
                  ? qtyRef
                  : unit.collectionUnitQty
                : unit.collectionUnitQty}
            </h4>
          )}
          {!unit.collectionUnitQty && <h4 className="unitqty">0</h4>}
          <Button
            classAppend="collection-add"
            idAppend={unit.id}
            variant="outlined"
            size="small"
            handleClick={handleCollectionAdd}
            text={"+"}
            // text={<HiPlus />}
          />
        </div>
      )}
    </div>
  );
};

export default UnitMod;
