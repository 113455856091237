import React, { useState, useEffect } from "react";
import { Grid } from "@material-ui/core";
import Button from "../../shared/common/button";
import { IoGrid } from "react-icons/io5";
import { AiOutlineMenu } from "react-icons/ai";
import { headerStyles } from "../styles";
import AddWallet from "../addWallet";
import { BiWallet, BiPlus } from "react-icons/bi";
import Tabs from "../../shared/tabs";
import nftlogo from "../../../img/nft_logo.svg";

const useStyles = headerStyles;

const Header = (props) => {
  const { handleSetAlert } = props;
  const classes = useStyles();
  const [toggleFlag, setFlag] = useState(false);
  const [activeView, setActiveView] = useState("grid");
  const [showNewWallet, setShowNewWallet] = useState(false);
  const [wallets, setWallets] = useState([]);

  const tabData = [
    {
      name: "NFTs",
    },
    {
      name: "Prices",
    },
  ];

  useEffect(() => {}, [activeView]);

  const toggleSearchClass = () => {
    console.log("toggle");
    if (toggleFlag) {
      setFlag(false);
    } else {
      setFlag(true);
    }
  };

  const toggleView = (e) => {
    if (e.currentTarget.id == "cmn-btn-id-table" && activeView == "grid") {
      setActiveView("table");
      {
        props.setView("table");
      }
    } else if (
      e.currentTarget.id == "cmn-btn-id-grid" &&
      activeView == "table"
    ) {
      setActiveView("grid");
      {
        props.setView("grid");
      }
    }
  };

  return (
    <Grid item justify="center" sx={8}>
      <div className={"collHeader nft-header " + props.headerClasses}>
        {/* <img src={nftlogo} className={props.game + "Logo"} alt="" /> */}
        <svg className={props.game + "Logo"}>
          <path
            d="M56.07,129.49L0,97.12V32.37
            L56.07,0l56.07,32.37V97.12l-56.07,32.37
            ZM11,90.77l45.07,26.02,45.07-26.02V38.72
            L56.07,12.7,11,38.72v52.04Zm32.76-2.1
            h-10.36l-6.02-21.68c-.2-.63-.32-1.18-.56-2.51
            h-.24c.2,2.65,.24,3.41,.24,4.25v19.93h-9.44
            V42.18h11.55l3.94,14.29c.32,1.12,.6,2.37,1.43,6.69
            h.2c-.16-2.44-.2-3.35-.2-4.81v-16.17h9.44
            v46.49Zm25.62-31.22h-11.63v5.02h11.63v13.03
            h-11.63v13.17h-10V42.18h21.63v15.26
            Zm26.01,.63h-6.93v30.6h-10.36v-30.6h-6.77
            v-15.89h24.06v15.89Z"
          />
        </svg>
        {/* <Tabs
            classAppend="tabButtonsContainer"
            activeTab={props.activeTab}
            setActiveTab={props.setActiveTab}
            data={tabData}
          /> */}
        <Button
          variant="outlined"
          size="large"
          color="primary"
          handleClick={() =>
            showNewWallet ? setShowNewWallet(false) : setShowNewWallet(true)
          }
          classAppend={"addWalletBtn"}
          // text={"Add Wallet"}
          text={
            <>
              <BiPlus /> &nbsp; <BiWallet />
            </>
          }
        />
        {showNewWallet && (
          <AddWallet
            handleSetAlert={handleSetAlert}
            currentWallets={wallets}
            setWallets={setWallets}
            setShowNewWallet={setShowNewWallet}
          />
        )}
      </div>
    </Grid>
  );
};

export default Header;
