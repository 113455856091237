import React, { useState, useEffect } from "react";
import { IconButton } from "@material-ui/core";
import http from "../../../services/http";
import config from "../../../config";
import DeleteForeverIcon from "@material-ui/icons/DeleteForever";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import SimpleMenu from "../../navigation/simpleMenu";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import ArrowDropUpIcon from "@material-ui/icons/ArrowDropUp";
import CircularIndeterminate from "../../shared/loadingAnim";
import CircleLoader from "../../shared/common/loader-circle";

const CollectionSetItem = (props) => {
  const [setUnits, setSetUnits] = useState([]);
  const [loading, setLoading] = useState(false);
  const unitMax = 12;
  // const unitCount = 0;

//   useEffect(() => {
//     const fetchSets = async () => {
//       const getSetUnitsUrl = `${config.apiUrl + props.setUnitsURL}`;
//       const { data } = await http.get(getSetUnitsUrl);
//       const modList = data.slice(0, unitMax);
//       setSetUnits(modList);
//       if (props.profSet) {
//         setSetUnits(props.userUnits);
//       }
//       console.log(data);
//       if (data.length == 0) {
//         console.log("empty data");
//       } else {
//         console.log("theres data!");
//       }
//       setLoading(false);
//     };
//     fetchSets();
//   }, []);

  useEffect(() => {
    const fetchSets = async () => {
      const getSetUnitsUrl = `${config.apiUrl + props.setUnitsURL}`;
      const { data } = await http.get(getSetUnitsUrl);
      setSetUnits(data);
      if (props.profSet) {
        setSetUnits(props.userUnits);
      }
      console.log(data);
      if (data.length == 0) {
        console.log("empty data");
      } else {
        console.log("theres data!");
      }
      setLoading(false);
    };
    fetchSets();
  }, []);

  const handleDeleteUnit = () => {
    console.log("to do: handleDeleteUnit");
    //TODO
  };

  const relDate = String(props.relDate);
  const relDateFinal = relDate;

  return (
    <React.Fragment>
        {loading && 
        <CircleLoader loading={loading} />
        }
        {!loading &&
        <div className={setUnits.length ? "set-div" : "noneSet"}>
          {/* {(setUnits.length > 0 & setFilters.includes(props.setName))  &&  */}
          {true && (
            <div className="collectionItemContainer">
              <h2 className="setTitle">{props.setName}</h2>
              {/* <h6 className="relDate">Released: {relDateFinal}</h6> */}
              <div className="collectionUnitDiv">
                {setUnits.map((unit) => {
                  return (
                    <div className="unitContainer">
                      {/* {(unitFilters.includes(unit.name))  &&  */}
                      <div className="unit">
                        <div className="price">
                          <h3 className="upPrice">$5</h3>
                          <ArrowDropUpIcon className="priceArrow upArrow" />
                          <ArrowDropDownIcon className="priceArrow downArrow activeArrow" />
                        </div>
                        <h2 className="unitNameH2">{unit.name}</h2>
                        <img
                          className="unitImg"
                          src={
                            unit.imageLink
                              ? `${config.mediaUrl}/${unit.imageLink}`
                              : ""
                          }
                        />
                        <SimpleMenu unitName={unit.name} />
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          )}
        </div>
    }
    </React.Fragment>
  );
};

export default CollectionSetItem;
