import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
// import { fetchMagicSets } from "./controller";
import { Button } from "@material-ui/core";
import { RiAddBoxLine } from "react-icons/ri";
import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import Typography from "@material-ui/core/Typography";
import { BeatLoader } from "react-spinners";

const MagicSetsAdmin = (props) => {
  const { handleSetAlert } = props;
  const [loading, setLoading] = useState(false);
  const [nextLoading, setNextLoading] = useState(false);
  const [sets, setSets] = useState([]);
  const [nextPage, setNextPage] = useState(1);
  const loadColor = "#0051a2";

  // useEffect(() => {
  //   fetchMagicSets(
  //     setLoading,
  //     nextPage,
  //     sets,
  //     setSets,
  //     setNextPage,
  //     handleSetAlert
  //   );
  // }, []);

  const handleLoadMore = () => {
  //   if (nextPage) {
  //     fetchMagicSets(
  //       setNextLoading,
  //       nextPage,
  //       sets,
  //       setSets,
  //       setNextPage,
  //       handleSetAlert
  //     );
  //   }
  };

  // window.onscroll = () => {
  //   if (
  //     window.innerHeight + document.documentElement.scrollTop ===
  //       document.documentElement.offsetHeight &&
  //     nextPage
  //   ) {
  //     fetchMagicSets(
  //       setNextLoading,
  //       nextPage,
  //       sets,
  //       setSets,
  //       setNextPage,
  //       handleSetAlert
  //     );
  //   }
  // };

  return (
    <div className="homeContainer adminContainer">
      <div className="adminLinkList">
        <Breadcrumbs separator="›" aria-label="breadcrumb">
          <Link color="inherit" to={"/admin"}>
            Admin
          </Link>
          <Typography color="textPrimary">MTG</Typography>
        </Breadcrumbs>
      </div>
      <h2 className="adminGameHeader">MTG Admin</h2>
      <br />
      <Link to="/admin/mtg/sets/new">
        <Button className="newSetBtn" size="large" color="primary">
          <RiAddBoxLine /> &nbsp; Add New Set
        </Button>
      </Link>
      <br />
      {loading && <h3>Loading...</h3>}
      {!loading && sets.length > 0 && (
        <React.Fragment>
          <h2>Existing Sets</h2>
          <div className="editSetSetContainer">
            {sets.map((set) => {
              return (
                <div key={set.id} className="editSetContainer">
                  <p>
                    <b>{set.name}</b>
                  </p>
                  <p>{set.id}</p>
                  <Link to={`/admin/mtg/sets/edit/${set.id}`}>
                    <Button
                      variant="outlined"
                      size="medium"
                      color="primary"
                      className="editBtn"
                    >
                      Edit Set
                    </Button>
                  </Link>
                  <Link to={`/admin/mtg/sets/${set.id}/units`}>
                    <Button
                      variant="outlined"
                      size="medium"
                      color="primary"
                      className="editBtn"
                    >
                      Edit Cards
                    </Button>
                  </Link>
                </div>
              );
            })}
          </div>
          <BeatLoader color={loadColor} loading={nextLoading} />
          {nextPage && (
            <>
              <br />
              <br />
              <Button
                variant="outlined"
                size="medium"
                color="primary"
                onClick={handleLoadMore}
              >
                Load More...
              </Button>
              <br />
              <br />
              <br />
              <br />
              <br />
            </>
          )}
        </React.Fragment>
      )}
    </div>
  );
};

export default MagicSetsAdmin;
