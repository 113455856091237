import http from "../../../services/http";
import config from "../../../config";
import * as _ from "lodash";
import {
  getMagicSets,
  getMagicSetUnits,
  getMagicUnitByID,
} from "../../mtg/api";
import { getMagicUnitFormLists, getAllSets } from "./api";
import { mapMana, mapTypeLine, mapKeywords } from "./maps";

export const interceptorTest = async () => {
  try {
    const testUrl = `${config.apiUrl}/auth/interceptor-test`;
    const { data } = await http.get(testUrl);
    console.log(data);
  } catch (err) {
    console.log("UH OH", err);
  }
};

export const fetchMagicSets = async (
  setLoading,
  page,
  currentData,
  setData,
  setNextPage,
  handleSetAlert
) => {
  try {
    setLoading(true);
    const { data, nextPage } = await getMagicSets(page);
    setData([...currentData, ...data]);
    setNextPage(nextPage);
    setLoading(false);
  } catch (err) {
    setLoading(false);
    handleSetAlert("error", "Error fetching Sets");
  }
};

export const fetchSetTypes = async (setLoading, setData, handleSetAlert) => {
  try {
    setLoading(true);
    const setTypesUrl = `${config.apiUrl}/mtg/sets/types`;
    const { data } = await http.get(setTypesUrl);
    setData(data);
    setLoading(false);
  } catch (err) {
    setLoading(false);
    handleSetAlert("error", "Error fetching Set Types");
  }
};

export const fetchEditSetData = async (
  handleSetAlert,
  setLoading,
  setData,
  setID,
  setName,
  setType,
  setReleaseDate,
  setIsActive
) => {
  try {
    setLoading(true);
    const setTypesUrl = `${config.apiUrl}/mtg/sets/types`;
    const { data: setTypesData } = await http.get(setTypesUrl);
    setData(setTypesData);
    const setUrl = `${config.apiUrl}/mtg/sets/${setID}`;
    const { data: set } = await http.get(setUrl);
    const { setTypeID, name, releaseDate, isActive } = set;
    setName(name);
    setType(setTypeID);
    setReleaseDate(releaseDate ? releaseDate : new Date());
    setIsActive(isActive);
    setLoading(false);
  } catch (err) {
    setLoading(false);
    handleSetAlert("error", "Error fetching Edit Set data");
  }
};

export const fetchMagicSetUnits = async (
  setLoading,
  setID,
  currentData,
  setData,
  page,
  setNextPage,
  handleSetAlert
) => {
  try {
    setLoading(true);
    const { data, nextPage } = await getMagicSetUnits(setID, page);
    setData([...currentData, ...data]);
    setNextPage(nextPage);
    setLoading(false);
  } catch (err) {
    setLoading(false);
    handleSetAlert("error", "Error fetching Set Units");
  }
};

export const fetchMagicUnitFormLists = async (
  handleSetAlert,
  setLoading,
  setUnitTypeList,
  setSetList,
  setRarityList,
  setManaList,
  setSupertypeLineList,
  setTypeLineList,
  setSubtypeLineList,
  setKeywordList
) => {
  try {
    setLoading(true);
    const sets = await getAllSets();
    const {
      keywords,
      mana,
      rarity,
      typeLineSubtypes,
      typeLineSupertypes,
      typeLineTypes,
      unitTypes,
    } = await getMagicUnitFormLists();
    setUnitTypeList(unitTypes);
    setSetList(sets);
    setRarityList(rarity);
    setManaList(mana);
    setSupertypeLineList(typeLineSupertypes);
    setTypeLineList(typeLineTypes);
    setSubtypeLineList(typeLineSubtypes);
    setKeywordList(keywords);
    setLoading(false);
  } catch (err) {
    setLoading(false);
    handleSetAlert("error", "Error fetching Unit Form Lists");
  }
};

export const handleAddData = (newValue, data, setData, dataType) => {
  if (newValue !== null) {
    const dataExists = _.find(data, { id: newValue.id });
    if (!dataExists) {
      if (!newValue.id) {
        const maxID = _.maxBy(data, "id");
        newValue.id = maxID ? maxID.id + 1 : 1;
      }
      if (dataType === "manaCost" || dataType === "manaIdentity") {
        newValue.qty = 1;
      } else if (dataType === "keywords") {
        newValue.details = "";
      } else if (dataType === "abilities" || dataType === "flavorText") {
        newValue.text = "";
      }
      const maxSortPosition = _.maxBy(data, "sortPosition");
      newValue.sortPosition = maxSortPosition
        ? maxSortPosition.sortPosition + 1
        : data.length + 1;
      newValue.wasUpdated = true;
      const newData = [...data, newValue];
      setData(newData);
    }
  }
};

export const handleRemoveData = (e, data, setData) => {
  const currentData = [...data];
  const buttonIdArray = e.currentTarget.id.split("-");
  const dataID = parseInt(buttonIdArray[buttonIdArray.length - 1]);
  const dataIndex = _.findIndex(currentData, { id: dataID });
  const pullSortPos = currentData[dataIndex].sortPosition;
  _.pullAt(currentData, [dataIndex]);
  const dataBefore = _.filter(currentData, (b) => {
    return b.sortPosition < pullSortPos;
  });
  const dataAfter = _.filter(currentData, (a) => {
    return a.sortPosition > pullSortPos;
  });
  const dataAfterCorrected = dataAfter.map((ac) => {
    ac.sortPosition -= 1;
    return ac;
  });
  const newData = [...dataBefore, ...dataAfterCorrected];
  setData(newData);
};

export const handleUpdateData = (
  dataID,
  propertyName,
  propertyValue,
  data,
  setData
) => {
  const newData = [...data];
  const dataIndex = _.findIndex(newData, { id: dataID });
  newData[dataIndex][propertyName] = propertyValue;
  newData[dataIndex].wasUpdated = true;
  setData(newData);
};

export const fetchEditUnitData = async (
  handleSetAlert,
  setLoading,
  setUnitTypeList,
  setSetList,
  setRarityList,
  setManaList,
  setSupertypeLineList,
  setTypeLineList,
  setSubtypeLineList,
  setKeywordList,
  unitID,
  setImageLink,
  setName,
  setType,
  setSet,
  setRarity,
  setPower,
  setToughness,
  setCollectorNumber,
  setArtist,
  setMana,
  setTypeLine,
  setKeywords,
  setAbilities,
  setFlavorText,
  setManaIdentity
) => {
  try {
    setLoading(true);
    // Set Form Data
    const sets = await getAllSets();
    const {
      keywords: keywordsList,
      mana: manaList,
      rarity: rarityList,
      typeLineSubtypes,
      typeLineSupertypes,
      typeLineTypes,
      unitTypes,
    } = await getMagicUnitFormLists();
    setUnitTypeList(unitTypes);
    setSetList(sets);
    setRarityList(rarityList);
    setManaList(manaList);
    setSupertypeLineList(typeLineSupertypes);
    setTypeLineList(typeLineTypes);
    setSubtypeLineList(typeLineSubtypes);
    setKeywordList(keywordsList);

    // Set Values
    const {
      name,
      imageLink,
      collectorNumber,
      artist,
      power,
      toughness,
      set,
      rarity,
      type,
      abilities,
      flavorText,
      keywords,
      manaCost,
      typeLine,
      manaIdentity,
    } = await getMagicUnitByID(unitID);

    const manaMap = mapMana(manaCost);

    const typeLineMap = mapTypeLine(typeLine);

    const keywordsMap = mapKeywords(keywords);

    const manaIdentityMap = mapMana(manaIdentity);

    setImageLink(imageLink);
    setName(name);
    setType(type.id);
    setSet(set.id);
    setRarity(rarity.id);
    setPower(power);
    setToughness(toughness);
    setCollectorNumber(collectorNumber);
    setArtist(artist);
    const manaMapSorted = _.sortBy(manaMap, ["sortPosition"]);
    setMana(manaMapSorted);
    const typeLineMapSorted = _.sortBy(typeLineMap, ["sortPosition"]);
    setTypeLine(typeLineMapSorted);
    const keywordsMapSorted = _.sortBy(keywordsMap, ["sortPosition"]);
    setKeywords(keywordsMapSorted);
    const abilitiesSorted = _.sortBy(abilities, ["sortPosition"]);
    setAbilities(abilitiesSorted);
    const flavorTextSorted = _.sortBy(flavorText, ["sortPosition"]);
    setFlavorText(flavorTextSorted);
    const manaIdentitySorted = _.sortBy(manaIdentityMap, ["sortPosition"]);
    setManaIdentity(manaIdentitySorted);
    setLoading(false);
  } catch (err) {
    setLoading(false);
    handleSetAlert("error", "Error fetching Unit Form Lists");
  }
};

export const handleDeleteData = async (e, data, setData, handleSetAlert) => {
  try {
    // Decipher the event ID into relevant details
    const dataTypeArray = e.currentTarget.id.split("-");
    const dataAction = dataTypeArray[1];
    const dataType = dataTypeArray[2];
    const dataTypeID = dataTypeArray[3];
    const dataID = parseInt(dataTypeArray[4]);
    const dataPointIndex = _.findIndex(data, { id: dataID });
    if (dataAction === "del") {
      const delDataUrl = `${config.apiUrl}/mtg/units/${dataType}/${dataTypeID}`;
      await http.delete(delDataUrl);
    }
    const newData = [...data];
    _.pullAt(newData, [dataPointIndex]);
    const newDataSorted = _.sortBy(newData, ["sortPosition"]);
    setData(newDataSorted);
    handleSetAlert("success", "Delete Success!");
  } catch (err) {
    if (err.response) {
      handleSetAlert("warning", err.response.data.message);
    } else {
      handleSetAlert("error", err.message);
    }
  }
};

export const handleSaveData = async (e, data, setData, handleSetAlert) => {
  try {
    // Decipher the event ID into relevant details
    const dataTypeArray = e.currentTarget.id.split("-");
    const dataAction = dataTypeArray[1];
    const dataType = dataTypeArray[2];
    const dataTypeID = dataTypeArray[3];
    const dataID = parseInt(dataTypeArray[4]);

    // Get the data that we're going to save
    const dataPointIndex = _.findIndex(data, { id: dataID });
    const dataPoint = data[dataPointIndex];

    // Update / insert the data
    let newDataPoint;
    if (dataAction === "new") {
      const newDataReqBody = {};
      if (dataType === "manaCost" || dataType === "manaIdentity") {
        newDataReqBody.unitID = dataTypeID;
        newDataReqBody.manaColorID = dataPoint.id;
        newDataReqBody.qty = dataPoint.qty;
        newDataReqBody.sortPosition = dataPoint.sortPosition;
      } else if (dataType === "typeLines") {
        newDataReqBody.unitID = dataTypeID;
        newDataReqBody.typeLineID = dataPoint.id;
        newDataReqBody.sortPosition = dataPoint.sortPosition;
      } else if (dataType === "keywords") {
        newDataReqBody.unitID = dataTypeID;
        newDataReqBody.keywordID = dataPoint.id;
        newDataReqBody.details = dataPoint.details;
        newDataReqBody.sortPosition = dataPoint.sortPosition;
      } else if (dataType === "abilities" || dataType === "flavorText") {
        newDataReqBody.unitID = dataTypeID;
        newDataReqBody.text = dataPoint.text;
        newDataReqBody.sortPosition = dataPoint.sortPosition;
      } else {
        throw new Error("Invalid Data Type");
      }
      const newDataUrl = `${config.apiUrl}/mtg/units/${dataType}`;
      const { data } = await http.post(newDataUrl, newDataReqBody);
      newDataPoint = data;
    } else if (dataAction === "upd") {
      const updDataReqBody = {};
      if (dataType === "manaCost" || dataType === "manaIdentity") {
        updDataReqBody.qty = dataPoint.qty;
      } else if (dataType === "keywords") {
        updDataReqBody.details = dataPoint.details;
      } else if (dataType === "abilities" || dataType === "flavorText") {
        updDataReqBody.text = dataPoint.text;
      } else {
        throw new Error("Invalid Data Type");
      }
      const updDataUrl = `${config.apiUrl}/mtg/units/${dataType}/${dataTypeID}`;
      const { data } = await http.put(updDataUrl, updDataReqBody);
      newDataPoint = data;
    } else {
      throw new Error("Invalid Data Action");
    }

    // Update the state array
    _.pullAt(data, [dataPointIndex]);
    if (dataType === "manaCost" || dataType === "manaIdentity") {
      const [mappedData] = mapMana([newDataPoint]);
      newDataPoint = mappedData;
    } else if (dataType === "typeLines") {
      const [mappedData] = mapTypeLine([newDataPoint]);
      newDataPoint = mappedData;
    } else if (dataType === "keywords") {
      const [mappedData] = mapKeywords([newDataPoint]);
      newDataPoint = mappedData;
    }
    const newData = [...data, newDataPoint];
    const newDataSorted = _.sortBy(newData, ["sortPosition"]);
    setData(newDataSorted);
    handleSetAlert("success", "Save Success!");
  } catch (err) {
    if (err.response) {
      handleSetAlert("warning", err.response.data.message);
    } else {
      handleSetAlert("error", err.message);
    }
  }
};
