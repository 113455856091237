import http from "../../services/http";
import config from "../../config";
import * as _ from "lodash";
import * as qs from "querystring";

export const getSearchUnits = (name, page = 1) => {
  return new Promise(async (resolve, reject) => {
    try {
      const qsData = {
        name,
        page,
      };
      const qString = qs.encode(qsData);
      const unitsUrl = `${config.apiUrl}/units/search?${qString}`;
      console.log(unitsUrl);
      const { data, headers } = await http.get(unitsUrl);
      resolve({
        data,
        nextPage: headers["next-page"] === "null" ? null : headers["next-page"],
      });
    } catch (err) {
      reject(err);
    }
  });
};
