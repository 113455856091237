import http from "../../services/http";
import config from "../../config";
import * as _ from "lodash";

export const getWallets = () => {
  return new Promise(async (resolve, reject) => {
    try {
      const url = `${config.apiUrl}/nft/wallets`;
      const { data } = await http.get(url);
      resolve(data);
    } catch (err) {
      reject(err);
    }
  });
};

export const getNFTs = (walletID) => {
  return new Promise(async (resolve, reject) => {
    try {
      const url = `${config.apiUrl}/nft/${walletID}`;
      const { data } = await http.get(url);
      resolve(data);
    } catch (err) {
      reject(err);
    }
  });
};

export const getWalletTypes = () => {
  return new Promise(async (resolve, reject) => {
    try {
      const url = `${config.apiUrl}/nft/wallets/types`;
      const { data } = await http.get(url);
      resolve(data);
    } catch (err) {
      reject(err);
    }
  });
};

export const insWallet = (wallet) => {
  return new Promise(async (resolve, reject) => {
    try {
      const url = `${config.apiUrl}/nft/wallets`;
      const { data } = await http.post(url, wallet);
      resolve(data);
    } catch (err) {
      reject(err);
    }
  });
};

export const delWallet = (walletID) => {
  return new Promise(async (resolve, reject) => {
    try {
      const url = `${config.apiUrl}/nft/wallets/${walletID}`;
      await http.delete(url);
      resolve(true);
    } catch (err) {
      reject(err);
    }
  });
};
