import http from "../../services/http";
import config from "../../config";
import * as qs from "querystring";
import { concat } from "lodash";

export const insCollectionUnit = (unitID) => {
  return new Promise(async (resolve, reject) => {
    try {
      const insCollectionUnitUrl = `${config.apiUrl}/collections`;
      const { data } = await http.post(insCollectionUnitUrl, { unitID });
      resolve(data);
    } catch (err) {
      reject(err);
    }
  });
};

export const delCollectionUnit = (unitID) => {
  return new Promise(async (resolve, reject) => {
    try {
      const delCollectionUnitUrl = `${config.apiUrl}/collections/${unitID}`;
      const { data } = await http.delete(delCollectionUnitUrl);
      resolve(data);
    } catch (err) {
      reject(err);
    }
  });
};

export const insDeckUnit = (deckID, unitID, gameID) => {
  return new Promise(async (resolve, reject) => {
    try {
      const insUnitUrl = `${config.apiUrl}/${gameID}/decks/${deckID}/units/${unitID}`;
      console.log(insUnitUrl);
      const { data } = await http.post(insUnitUrl);
      resolve(data);
    } catch (err) {
      console.log("error");
      reject(err);
    }
  });
};

export const delDeckUnit = (deckID, unitID, gameID) => {
  return new Promise(async (resolve, reject) => {
    try {
      const delUnitUrl = `${config.apiUrl}/${gameID}/decks/${deckID}/units/${unitID}`;
      const { data } = await http.delete(delUnitUrl);
      resolve(data);
    } catch (err) {
      // console.log("error");
      reject(err);
    }
  });
};

export const newDeck = (gameID, body) => {
  return new Promise(async (resolve, reject) => {
    try {
      const newDeckUrl = `${config.apiUrl}/${gameID}/decks`;
      console.log(newDeckUrl);
      const { data } = await http.post(newDeckUrl, body);
      resolve(data);
    } catch (err) {
      console.log("error");
      reject(err);
    }
  });
};

export const delDeck = (gameID, deckID) => {
  return new Promise(async (resolve, reject) => {
    try {
      const delDeckUrl = `${config.apiUrl}/${gameID}/decks/${deckID}`;
      // console.log(delDeckUrl);
      const { data } = await http.delete(delDeckUrl);
      resolve(data);
    } catch (err) {
      reject(err);
    }
  });
};

export const getDeckUnits = (deckID, gameID) => {
  return new Promise(async (resolve, reject) => {
    try {
      const getDeckUnitsUrl = `${config.apiUrl}/${gameID}/decks/${deckID}/units`;
      const { data } = await http.get(getDeckUnitsUrl);
      resolve({ data });
    } catch (err) {
      // console.log("error");
      reject(err);
    }
  });
};

export const getUnitData = (setLoading, gameID, unitID) => {
  return new Promise(async (resolve, reject) => {
    try {
      setLoading(true);
      const getUnitsDataUrl = `${config.apiUrl}/${gameID}/units/${unitID}`;
      const { data } = await http.get(getUnitsDataUrl);
      resolve({ data });
      setLoading(false);
    } catch (err) {
      // console.log("error");
      reject(err);
    }
  });
};

// export const getGainers = () => {
//   return new Promise(async (resolve, reject) => {
//     try{
//       const moversUrl = `${config.apiUrl}/top-movers/collection`;
//       const {data} = await http.get(moversUrl);
//       resolve(data.gainers);
//     } catch (err){
//       reject(err);
//     }
//   });
// };

// export const getLosers = () => {
//   return new Promise(async (resolve, reject) => {
//     try{
//       const moversUrl = `${config.apiUrl}/top-movers/collection`;
//       const {data} = await http.get(moversUrl);
//       resolve(data.losers);
//     } catch (err){
//       reject(err);
//     }
//   });
// };

export const getMovers = () => {
  return new Promise(async (resolve, reject) => {
    try {
      const moversUrl = `${config.apiUrl}/top-movers/collection`;
      const { data } = await http.get(moversUrl);
      resolve(data);
    } catch (err) {
      reject(err);
    }
  });
};

export const getSorts = (gamecode) => {
  return new Promise(async (resolve, reject) => {
    try {
      const getFilterData = `${config.apiUrl}/units/filters/${gamecode}/manifest`;
      const { data } = await http.get(getFilterData);
      resolve(data.sorting);
    } catch (err) {
      reject(err);
    }
  });
};

export const getFilters = (gamecode) => {
  return new Promise(async (resolve, reject) => {
    try {
      const getFilterData = `${config.apiUrl}/units/filters/${gamecode}/manifest`;
      console.log(getFilterData);
      const { data } = await http.get(getFilterData);
      // console.log(data.filters)
      resolve(data.filters);
    } catch (err) {
      reject(err);
    }
  });
};

export const getFilterSets = (gameCode) => {
  return new Promise(async (resolve, reject) => {
    try {
      const url = `${config.apiUrl}/units/filters/sets/${gameCode}`;
      const { data } = await http.get(url);
      resolve(data);
    } catch (err) {
      reject(err);
    }
  });
};

export const getFilterRarities = (gameCode) => {
  return new Promise(async (resolve, reject) => {
    try {
      const url = `${config.apiUrl}/units/filters/rarities/${gameCode}`;
      const { data } = await http.get(url);
      resolve(data);
    } catch (err) {
      reject(err);
    }
  });
};

export const getUnits = (query, page) => {
  return new Promise(async (resolve, reject) => {
    try {
      const qsData = {
        page,
        ...query,
      };
      const qString = qs.encode(qsData);
      const url = `${config.apiUrl}/units?${qString}`;
      const { data, headers } = await http.get(url);
      resolve({
        data,
        nextPage: headers["next-page"] === "null" ? null : headers["next-page"],
      });
    } catch (err) {
      reject(err);
    }
  });
};

export const getFilteredUnits = (filterBody, gameCode, page) => {
  return new Promise(async (resolve, reject) => {
    try {
      console.log("running getfilteredunits");
      const qsData = {
        page,
      };
      const qString = qs.encode(qsData);
      const url = `${config.apiUrl}/units/${gameCode}?${qString}`;
      const { data, headers } = await http.post(url, filterBody);
      resolve({
        data,
        nextPage: headers["next-page"] === "null" ? null : headers["next-page"],
      });
    } catch (err) {
      reject(err);
    }
  });
};

export const bulkAddUnits = (bodyArg, setCollection) => {
  return new Promise(async (resolve, reject) => {
    try {
      const url = `${config.apiUrl}/collections/bulk`;
      const { data } = await http.post(url, bodyArg);
      resolve({
        data,
      });
    } catch (err) {
      reject(err);
    }
  });
};

export const getUnitsByGame = (gameCode, page = 1, filterObj) => {
  return new Promise(async (resolve, reject) => {
    try {
      const qsData = {
        page,
      };
      const qString = qs.encode(qsData);
      if (filterObj) {
        const url = `${config.apiUrl}/units/${gameCode}?${qString}`;
        const { data, headers } = await http.post(url, filterObj);
        resolve({
          data,
          nextPage:
            headers["next-page"] === "null" ? null : headers["next-page"],
        });
      } else {
        const url = `${config.apiUrl}/units/${gameCode}?${qString}`;
        const { data, headers } = await http.get(url);
        resolve({
          data,
          nextPage:
            headers["next-page"] === "null" ? null : headers["next-page"],
        });
      }
    } catch (err) {
      reject(err);
    }
  });
};

export const getUnitsSearch = (name, page = 1, setSearch, setSearchLoading) => {
  return new Promise(async (resolve, reject) => {
    try {
      setSearchLoading(true);
      const qsData = {
        name,
        page,
      };
      const qString = qs.encode(qsData);
      const url = `${config.apiUrl}/units/search?${qString}`;
      const { data, headers } = await http.get(url);
      // console.log("data:", data);
      setSearch(data);
      resolve({
        data,
        nextPage: headers["next-page"] === "null" ? null : headers["next-page"],
      });
      setSearchLoading(false);
      // resolve([...data]);
    } catch (err) {
      reject(err);
    }
  });
};

export const getDecks = (gamecode) => {
  return new Promise(async (resolve, reject) => {
    try {
      const getDecksUrl = `${config.apiUrl}/${gamecode}/decks/me`;
      const { data } = await http.get(getDecksUrl);
      resolve({ data });
    } catch (err) {
      reject(err);
    }
  });
};
