import React, { useState, useEffect } from "react";
import DotLoader from "../shared/common/loader-dot";
import Button from "../shared/common/button";
import { Grid } from "@material-ui/core";
import { fetchNFTs } from "./controller";
import config from "../../config";
import NFTMod from "./components/nft-module";
import { BiCopyAlt } from "react-icons/bi";
import Snackbar from '@mui/material/Snackbar';

const Wallet = (props) => {
  const { handleSetAlert, wallet } = props;
  const { id: walletID, type, nickname } = wallet;
  const [loading, setLoading] = useState(false);
  const [nfts, setNFTs] = useState([]);
  const [openSnackbar, setOpenSnackbar] = React.useState(false);

  useEffect(() => {
    fetchNFTs(setLoading, walletID, nickname, setNFTs, handleSetAlert);
    console.log(nfts)
  }, []);


  const handleClick = (wallet) => {
    navigator.clipboard.writeText(wallet.address ? wallet.address : "Address Coppied")
    setOpenSnackbar(true);
  };

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpenSnackbar(false);
  };

  return (
    <Grid container>
      <DotLoader loading={loading} />
      <Snackbar
        open={openSnackbar}
        autoHideDuration={4000}
        message="Address Copied to Clipboard"
        onClose={handleClose}
        // anchorOrigin={}
      />
      {!loading && (
        <div className={nfts.length ? "nft-wallet-container" : "noneSet"}>
          {/* <SimpleMenu menuTitle={setName} addedClasses="setOpsMenu"/> */}
          <img
            className="nft-logo"
            src={`${config.mediaUrl}/${type.imageLink}`}
          />
          <h2 className="wallet-title">
            {nickname ? nickname : `${type.name} Wallet`}
          </h2>
          <Button
            variant="outlined"
            size="small"
            color="primary"
            idAppend="address-btn"
            text={<BiCopyAlt/>}
            classAppend={"copy-address-btn"}
            disableRipple={false}
            handleClick={() => {handleClick(wallet)}}
          />
          <div className="nft-unit-div">
            {nfts.map((unit, index) => {
              console.log(unit)
              if (nfts.length === index + 1) {
                if (unit.nsfw) {
                  console.log(unit);
                }
                return (
                  <div key={index} className="nft-unit-container lastUnit">
                    <NFTMod
                      unit={unit}
                      pic={
                        unit.nsfw
                          ? `${config.mediaUrl}/${config.nsfwImageLink}`
                          : unit.imageLink
                      }
                      onModalToggle={() => props.openModal(unit)}
                      walletImage={`${config.mediaUrl}/${type.imageLink}`}
                    />
                  </div>
                );
              } else {
                return (
                  <div className="nft-unit-container">
                    <NFTMod
                      unit={unit}
                      pic={
                        unit.nsfw
                          ? `${config.mediaUrl}/${config.nsfwImageLink}`
                          : unit.imageLink
                      }
                      onModalToggle={() => props.openModal(unit)}
                      walletImage={`${config.mediaUrl}/${type.imageLink}`}
                    />
                  </div>
                );
              }
            })}
            {/* <CircleLoader loading={nextLoading} />
            <button
              onClick={handleLoadMoreUnits}
              className="loadUnitsBtn"
            >
            Load More...
            </button> */}
          </div>
        </div>
      )}
    </Grid>
  );
};

export default Wallet;
