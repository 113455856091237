import React, { useState } from "react";
import config from "../../../config";
import mtglogo from "../../../img/magicLogo.png";
import Button from "../../shared/common/button";
import { HiMinusSm, HiPlus } from "react-icons/hi";
import { insCollectionUnit, delCollectionUnit } from "../../collections/api";
import { authCheck } from "../../../services/auth";
import ethLogo from "../../../img/eth_logo.png";
import reactRouterDom from "react-router-dom";

const NFTMod = (props) => {
  const { unit, pic, walletImage } = props;
  return (
    <div className="nft-mod" onClick={props.onModalToggle}>
      <div className="unit-pic">
        <img
          src={pic}
        />
      </div>
      <h2 className="tooltipped">
        {unit.name}
      </h2>
      {unit.stats &&
        <React.Fragment>
          <img src={walletImage} alt="" />
          <p className="nft-price">
            {unit.stats.floorPrice}
          </p>
        </React.Fragment>
      }
      {/* <div className="nft-footer">
        <p className="price">
          <span>
            <img src={ethLogo} alt="" />
          </span>
          -
        </p>
      </div> */}
    </div>
  );
};

export default NFTMod;
