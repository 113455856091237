import { getMovers } from "../shared/api";
import { getCollection } from "../collections/api";

export const fetchMovers = async (setLoading, setGainers, setLosers) => {
  try {
    setLoading(true);
    const { gainers, losers } = await getMovers();
    setGainers(gainers);
    setLosers(losers);
    setLoading(false);
  } catch (err) {
    setLoading(false);
    console.log("error occured");
    //   handleSetAlert("error", "Error fetching Units");
  }
};

export const fetchMyCollectionUnits = async (
  setLoading,
  page,
  setNextPage,
  currentData,
  setData,
  handleSetAlert
) => {
  try {
    setLoading(true);
    const { data, nextPage } = await getCollection(page);
    setData([...currentData, ...data]);
    setNextPage(nextPage);
    setLoading(false);
  } catch (err) {
    setLoading(false);
    if (err.response) {
      handleSetAlert("warning", err.response.data.message);
    } else {
      handleSetAlert("error", err.message);
    }
  }
};
