import React, { useState, useEffect } from "react";
import http from "../../services/http";
import config from "../../config";
import CircularIndeterminate from "../shared/loadingAnim";
import CenteredTabs from "./hcTabs";

const HCMain = ({ modMode }) => {
  const [loading, setLoading] = useState(false);
  const [sets, setSets] = useState([]);

  useEffect(() => {
    const fetchSets = async () => {
      setLoading(true);
      const getSetsUrl = `${config.apiUrl}/sets`;
      const { data } = await http.get(getSetsUrl);
      setSets(data);
      setLoading(false);
    };

    fetchSets();
  }, []);

  const filterSet = (event) => {
    let sets = this.state.sets;
    let filteredSets = sets.filter((set) => set.setType == event.value);
    setSets();
  };

  return (
    <div className="sets-container hc-container">
      {/* <div className="setsBannerDiv">
        <img src="https://y101fm.com/images/articleimages/111616/2016-11-14-celeb-comic-heroes-rev-banner.jpg" alt=""/>
      </div> */}
      <div className="setHeader">
        <h1>HeroClix</h1>
      </div>
      {loading && (
        <React.Fragment>
          <br />
          <br />
          <br />
          <br />
          <CircularIndeterminate className="loadingAnim" />
        </React.Fragment>
      )}
      {!loading && (
        <div className="set-list-container">
          <CenteredTabs />
        </div>
      )}
    </div>
  );
};

export default HCMain;
