import http from "../../services/http";
import config from "../../config";
import * as qs from "querystring";
import * as _ from "lodash";
import { resolve } from "path";

export const getMyCollectionGames = () => {
  return new Promise(async (resolve, reject) => {
    try {
      const collectionUrl = `${config.apiUrl}/collections/me/games`;
      const { data } = await http.get(collectionUrl);
      resolve(data);
    } catch (err) {
      reject(err);
    }
  });
};

export const getMyCollectionUnits = (page = 1) => {
  return new Promise(async (resolve, reject) => {
    try {
      const qsData = {
        page,
      };
      const qString = qs.encode(qsData);
      const collectionUrl = `${config.apiUrl}/collections/me?${qString}`;
      const { data, headers } = await http.get(collectionUrl);
      resolve({
        data,
        nextPage: headers["next-page"] === "null" ? null : headers["next-page"],
      });
    } catch (err) {
      reject(err);
    }
  });
};

export const getMyCollectionGameSets = (gameID, page = 1) => {
  return new Promise(async (resolve, reject) => {
    try {
      const qsData = {
        page,
      };
      const qString = qs.encode(qsData);
      const setsUrl = `${config.apiUrl}/collections/me/${gameID}/sets?${qString}`;
      const { data, headers } = await http.get(setsUrl);
      const nullDates = data.filter((date) => !date.releaseDate);
      const trueDates = data.filter((date) => date.releaseDate);
      const dataSorted = _.orderBy(trueDates, ["releaseDate"], ["desc"]);
      const outData = {
        data: [...dataSorted, ...nullDates],
        nextPage: headers["next-page"] === "null" ? null : headers["next-page"],
      };
      resolve(outData);
    } catch (err) {
      reject(err);
    }
  });
};

export const getMyCollectionGameSetUnits = (gameID, setID, page = 1) => {
  return new Promise(async (resolve, reject) => {
    try {
      const qsData = {
        page,
      };
      const qString = qs.encode(qsData);
      const setUnitsUrl = `${config.apiUrl}/collections/me/${gameID}/${setID}/units?${qString}`;
      const { data, headers } = await http.get(setUnitsUrl);
      resolve({
        data,
        nextPage: headers["next-page"] === "null" ? null : headers["next-page"],
      });
    } catch (err) {
      reject(err);
    }
  });
};

export const insCollectionUnit = (unitID) => {
  return new Promise(async (resolve, reject) => {
    try {
      const insCollectionUnitUrl = `${config.apiUrl}/collections`;
      const { data } = await http.post(insCollectionUnitUrl, { unitID });
      resolve(data);
    } catch (err) {
      reject(err);
    }
  });
};

export const delCollectionUnit = (unitID) => {
  return new Promise(async (resolve, reject) => {
    try {
      const delCollectionUnitUrl = `${config.apiUrl}/collections/${unitID}`;
      const { data } = await http.delete(delCollectionUnitUrl);
      resolve(data);
    } catch (err) {
      reject(err);
    }
  });
};

export const getCollection = (page, gameFilter, sortBy) => {
  return new Promise(async (resolve, reject) => {
    try {
      let qsData = {
        game: gameFilter,
      };
      let qString = qs.encode(qsData);
      let setUnitsUrl = "";
      if (gameFilter == "All" || !gameFilter) {
        qString = "";
        setUnitsUrl = `${config.apiUrl}/collections/me`;
      } else {
        setUnitsUrl = `${config.apiUrl}/collections/me?${qString}`;
      }
      const { data, headers } = await http.get(setUnitsUrl);
      resolve({
        data,
        // nextPage: headers["next-page"] === "null" ? null : headers["next-page"],
      });
    } catch (err) {
      reject(err);
    }
  });
};
