import React from "react";
import { Link } from "react-router-dom";

const MtgAdmin = () => {
  return (
    <div className="homeContainer adminContainer">
      <div className="adminLinkList">
        <Link to={"/admin"}>
          <h4>Admin Home</h4>
        </Link>
        <p>&nbsp; | </p>
        <Link to={"/admin/mtg/sets"}>
          <h4>Magic Portal</h4>
        </Link>
      </div>
      <h2>MTG Admin</h2>
      <Link to="/admin/mtg/sets">Sets</Link>
    </div>
  );
};

export default MtgAdmin;
