import React, { useState, useEffect } from "react";
import http from "../../services/http";
import config from "../../config";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import CollectionHeader from "../collections/components/header";
import CollectionSetItem from "../collections/components/collectionSetItem";
import { Grid, Button } from "@material-ui/core";
import CircularIndeterminate from "../shared/loadingAnim";
import TeamBuilder from "./teamBuilder";
import AddIcon from "@material-ui/icons/Add";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-auto-tabpanel-${index}`}
      aria-labelledby={`scrollable-auto-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    "id": `scrollable-auto-tab-${index}`,
    "aria-controls": `scrollable-auto-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    width: "100%",
    backgroundColor: theme.palette.background.paper,
  },
}));

export default function CenteredTabs() {
  const classes = useStyles();
  const [value, setValue] = React.useState(0);
  const [sets, setSets] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const fetchSets = async () => {
      const getSetsUrl = `${config.apiUrl}/sets`;
      const { data } = await http.get(getSetsUrl);
      setSets(data);
    };

    fetchSets();
  }, []);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleUnits = (set) => {
    let userUnits = [];
    let setLength = set.length;
    for (let i = 0; i < setLength; i++) {
      if (set[i].hasUnit) {
        userUnits.append(set[i]);
      }
    }
    return userUnits;
  };

  return (
    <div className={classes.root + " profTabsContainer"}>
      <AppBar position="static" color="default">
        <Tabs
          value={value}
          onChange={handleChange}
          indicatorColor="primary"
          textColor="primary"
          variant="fullWidth"
          scrollButtons="auto"
          aria-label="scrollable auto tabs example"
        >
          <Tab label="Browse" {...a11yProps(0)} />
          <Tab label="My HeroClix" {...a11yProps(1)} />
          {/* <Tab label="My Teams" {...a11yProps(2)} /> */}
        </Tabs>
      </AppBar>
      <TabPanel value={value} index={0} className="hcMainSetContainer">
        {loading && (
          <React.Fragment>
            <br />
            <br />
            <br />
            <br />
            <CircularIndeterminate className="loadingAnim" />
          </React.Fragment>
        )}
        {!loading && (
          <div className="set-list-container">
            <CollectionHeader />
            <Grid container>
              {sets.map((set) => {
                const { id, setType, name, isModern, releaseDate } = set;
                const setTypeImage =
                  setType === "Marvel" ? "marvelLogo.png" : "dcLogo.webp";
                //   const setDetailLink = modMode
                //     ? `/admin/editSet/${id}`
                //     : `/set/${id}`;

                const setUnits = `/sets/${id}/units`;

                return (
                  <div className="set-div">
                    <CollectionSetItem
                      relDate={releaseDate}
                      setName={name}
                      addBtn={true}
                      delBtn={false}
                      setUnits={setUnits}
                      famImg={`${config.mediaUrl}/HCC/misc/${setTypeImage}`}
                    />
                    {/* <img
                    className="set-type-logo"
                    src={`${config.mediaUrl}/HCC/misc/${setTypeImage}`}
                  />
                  <Link to={setDetailLink}>
                    <h3>{name}</h3>
                  </Link>
                  <p>{dateFormat(releaseDate, "m/d/yy")}</p>
                  {isModern && <CheckIcon />} */}
                  </div>
                );
              })}
            </Grid>
            {/* </ul> */}
          </div>
        )}
      </TabPanel>
      <TabPanel value={value} index={1} className="hcMainSetContainer">
        {loading && (
          <React.Fragment>
            <br />
            <br />
            <br />
            <br />
            <CircularIndeterminate className="loadingAnim" />
          </React.Fragment>
        )}
        {!loading && (
          <div className="set-list-container">
            <CollectionHeader />
            <Grid container>
              {sets.map((set) => {
                const { id, setType, name, isModern, releaseDate } = set;
                const setTypeImage =
                  setType === "Marvel" ? "marvelLogo.png" : "dcLogo.webp";
                //   const setDetailLink = modMode
                //     ? `/admin/editSet/${id}`
                //     : `/set/${id}`;

                const setUnits = `/sets/${id}/units`;

                return (
                  <div className="set-div">
                    <CollectionSetItem
                      profSet={true}
                      userUnits={handleUnits(set)}
                      relDate={releaseDate}
                      setName={name}
                      addBtn={true}
                      delBtn={false}
                      setUnits={setUnits}
                      famImg={`${config.mediaUrl}/HCC/misc/${setTypeImage}`}
                    />
                    {/* <img
                    className="set-type-logo"
                    src={`${config.mediaUrl}/HCC/misc/${setTypeImage}`}
                  />
                  <Link to={setDetailLink}>
                    <h3>{name}</h3>
                  </Link>
                  <p>{dateFormat(releaseDate, "m/d/yy")}</p>
                  {isModern && <CheckIcon />} */}
                  </div>
                );
              })}
            </Grid>
            {/* </ul> */}
          </div>
        )}
      </TabPanel>
      {/* <TabPanel value={value} index={1}>
            <CollectionHeader/>
            {sets.map((set) => {
              const { id, setType, name, isModern, releaseDate } = set;
              const setTypeImage =
                setType === "Marvel" ? "marvelLogo.png" : "dcLogo.webp";

              const setUnits = `/sets/${id}/units`;

              return (
                <div className="set-div">
                  <CollectionSetItem profSet={true} userUnits={handleUnits(set)} relDate={releaseDate} setName={name} delBtn={true} setUnits={setUnits} famImg={`${config.mediaUrl}/HCC/misc/${setTypeImage}`}/>
                  <img
                    className="set-type-logo"
                    src={`${config.mediaUrl}/HCC/misc/${setTypeImage}`}
                  />
                  <Link to={setDetailLink}>
                    <h3>{name}</h3>
                  </Link>
                  <p>{dateFormat(releaseDate, "m/d/yy")}</p>
                  {isModern && <CheckIcon />}
                </div>
              );
            })}
        <CollectionHeader profSet={true} userUnits={handleUnits(set)}/>
      </TabPanel> */}
      <TabPanel className="hcTeamsTab" value={value} index={2}>
        <TeamBuilder />
      </TabPanel>
    </div>
  );
}
