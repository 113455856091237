import http from "../../../services/http";
import config from "../../../config";

export const insSet = (body) => {
  return new Promise(async (resolve, reject) => {
    try {
      const newSetUrl = `${config.apiUrl}/mtg/sets`;
      const { data } = await http.post(newSetUrl, body);
      resolve(data);
    } catch (err) {
      reject(err);
    }
  });
};

export const updToggleSetActive = (setID) => {
  return new Promise(async (resolve, reject) => {
    try {
      const toggleActiveUrl = `${config.apiUrl}/mtg/sets/${setID}/active/toggle`;
      await http.put(toggleActiveUrl);
      resolve(true);
    } catch (err) {
      reject(err);
    }
  });
};

export const updSet = (setID, body) => {
  return new Promise(async (resolve, reject) => {
    try {
      const updateSetUrl = `${config.apiUrl}/mtg/sets/${setID}`;
      const { data } = await http.put(updateSetUrl, body);
      resolve(data);
    } catch (err) {
      reject(err);
    }
  });
};

export const insUnit = (unit) => {
  return new Promise(async (resolve, reject) => {
    try {
      const {
        name,
        type: typeID,
        set: setID,
        rarity: rarityID,
        power,
        toughness,
        collectorNumber,
        artist,
        mana,
        typeLine,
        keywords,
        abilities,
        flavorText,
        manaIdentity,
      } = unit;

      const manaMap = mana.map((m) => {
        const { id: manaColorID, qty, sortPosition } = m;
        return {
          manaColorID,
          qty,
          sortPosition,
        };
      });

      const manaIdentityMap = manaIdentity.map((mi) => {
        const { id: manaColorID, qty, sortPosition } = mi;
        return {
          manaColorID,
          qty,
          sortPosition,
        };
      });

      const typeLineMap = typeLine.map((tl) => {
        const { id: typeLineID, sortPosition } = tl;
        return {
          typeLineID,
          sortPosition,
        };
      });

      const keywordsMap = keywords.map((k) => {
        const { id: keywordID, details, sortPosition } = k;
        return {
          keywordID,
          details,
          sortPosition,
        };
      });

      const abilitiesMap = abilities.map((a) => {
        const { text, sortPosition } = a;
        return {
          text,
          sortPosition,
        };
      });

      const flavorTextMap = flavorText.map((ft) => {
        const { text, sortPosition } = ft;
        return {
          text,
          sortPosition,
        };
      });

      const body = {
        name,
        typeID,
        setID,
        rarityID,
        power,
        toughness,
        collectorNumber,
        artist,
        manaCost: manaMap,
        typeLine: typeLineMap,
        keywords: keywordsMap,
        abilities: abilitiesMap,
        flavorText: flavorTextMap,
        manaIdentity: manaIdentityMap,
      };

      const newUnitUrl = `${config.apiUrl}/mtg/units`;
      const { data } = await http.post(newUnitUrl, body);
      resolve(data);
    } catch (err) {
      reject(err);
    }
  });
};

export const updUnitImage = (unitID, file) => {
  return new Promise(async (resolve, reject) => {
    try {
      const httpConfig = {
        headers: {
          "x-upload-path": "units",
        },
      };
      const data = new FormData();
      data.append("upload", file);
      const updImageUrl = `${config.apiUrl}/units/${unitID}/image`;
      const { data: imageData } = await http.put(updImageUrl, data, httpConfig);
      resolve(imageData);
    } catch (err) {
      reject(err);
    }
  });
};

export const getMagicUnitFormLists = () => {
  return new Promise(async (resolve, reject) => {
    try {
      const formListsUrl = `${config.apiUrl}/admin/mtg/forms/unit`;
      const { data } = await http.get(formListsUrl);
      resolve(data);
    } catch (err) {
      reject(err);
    }
  });
};

export const updMagicUnitDetails = (unitID, details) => {
  return new Promise(async (resolve, reject) => {
    try {
      const updUnitDetailsUrl = `${config.apiUrl}/mtg/units/${unitID}`;
      const { data } = await http.put(updUnitDetailsUrl, details);
      resolve(data);
    } catch (err) {
      reject(err);
    }
  });
};

export const getAllSets = () => {
  return new Promise(async (resolve, reject) => {
    try {
      const getAllSetsUrl = `${config.apiUrl}/mtg/sets/all`;
      const { data } = await http.get(getAllSetsUrl);
      resolve(data);
    } catch (err) {
      reject(err);
    }
  });
};
