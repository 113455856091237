import React, { useState, useEffect } from "react";
import { Grid } from "@material-ui/core";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import UpdateEmail from "./components/updateEmail";
import UpdateUsername from "./components/updateUsername";
import UpdatePassword from "./components/updatePassword";
import AddSubscription from "./components/addSubscription";
import CancelSubscription from "./components/cancelSubscription";
import config from "../../config";
import { premiumCheck, modCheck } from "../../services/auth";

const stripePromise = loadStripe(config.stripePublishableKey);

const UserSettings = (props) => {
  const { user, handleSetUser, handleSetAlert } = props;
  const [isPremium, setIsPremium] = useState(false);
  const [isMod, setIsMod] = useState(false);

  useEffect(() => {
    setIsPremium(premiumCheck());
    setIsMod(modCheck());
  }, []);

  return (
    <div className="user-settings">
      <Grid
        container
        direction="row"
        justify="flex-start"
        alignItems="center"
        spacing={5}
      >
        <Grid item xs={12}>
          <UpdateEmail
            user={user}
            handleSetUser={handleSetUser}
            handleSetAlert={handleSetAlert}
          />
        </Grid>
        <Grid item xs={12}>
          <UpdateUsername
            handleSetUser={handleSetUser}
            handleSetAlert={handleSetAlert}
          />
        </Grid>
        <Grid item xs={12}>
          <UpdatePassword handleSetAlert={handleSetAlert} />
        </Grid>
        {!isMod && (
          <Grid item xs={12}>
            <Elements stripe={stripePromise}>
              {isPremium ? (
                <CancelSubscription
                  handleSetUser={handleSetUser}
                  handleSetAlert={handleSetAlert}
                />
              ) : (
                <AddSubscription
                  handleSetUser={handleSetUser}
                  handleSetAlert={handleSetAlert}
                />
              )}
            </Elements>
          </Grid>
        )}
      </Grid>
    </div>
  );
};

export default UserSettings;
