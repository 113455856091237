import { getPokemonUnits } from "./api";
import {
  getUnitsByGame,
  getFilterSets,
  getSorts,
  getFilterRarities,
  getUnits,
  getFilters,
  getDecks,
  getDeckUnits,
  insertUnit,
  newDeck,
} from "../shared/api";
// import {  } from "../shared/api";
import { sortBy } from "lodash";

export const fetchUnits = async (
  setLoading,
  page,
  setNextPage,
  currentData,
  setData,
  handleSetAlert,
  filtersChangeFlag,
  setFiltersChangeFlag,
  filterObj,
  sortBy,
  sortByChange,
  setSortByChange
) => {
  try {
    setLoading(true);
    if (filtersChangeFlag || sortByChange) {
      const filterBod = {
        sort: sortBy,
        filters: filterObj,
      };
      const { data, nextPage } = await getUnitsByGame("PM", 1, filterBod);
      setData([...data]);
      setNextPage(nextPage);
      setLoading(false);
      setFiltersChangeFlag(false);
      setSortByChange(false);
    } else {
      if (parseInt(page) == 1) {
        const filterBod = {
          sort: sortBy,
          filters: filterObj,
        };
        const { data, nextPage } = await getUnitsByGame("PM", page, filterBod);
        setData([...data]);
        setNextPage(nextPage);
        setLoading(false);
        setFiltersChangeFlag(false);
      } else if (parseInt(page) >= 2) {
        const filterBod = {
          sort: sortBy,
          filters: filterObj,
        };
        const { data, nextPage } = await getUnitsByGame("PM", page, filterBod);
        setData([...currentData, ...data]);
        setNextPage(nextPage);
        setLoading(false);
        setFiltersChangeFlag(false);
      }
    }
  } catch (err) {
    setLoading(false);
    // handleSetAlert("error", "Error fetching Units");
  }
};

export const fetchDecks = async (setLoading, setDecks) => {
  try {
    setLoading(true);
    const { data } = await getDecks("pokemon");
    setDecks([...data]);
    setLoading(false);
  } catch (err) {
    setLoading(false);
    // handleSetAlert("error", "Error fetching Units");
  }
};

// export const fetchUnits = async (
//   setLoading,
//   page,
//   setNextPage,
//   currentData,
//   setData,
//   handleSetAlert
// ) => {
//   try {
//     const { data, nextPage } = await getUnitsByGame("PM", page, null);
//     setData([...currentData, ...data]);
//     setNextPage(nextPage);
//     setLoading(false);
//   } catch (err) {
//     setLoading(false);
//     // handleSetAlert("error", "Error fetching Units");
//   }
// };

export const fetchDeckUnits = async (setLoading, deckID, setDeckUnits) => {
  try {
    const { data } = await getDeckUnits(deckID, "pokemon");
    const sortedData = sortBy(data, "name");
    // console.log("DATA:", data);
    setDeckUnits(sortedData);
  } catch (err) {
    // handleSetAlert("error", "Error fetching Units");
  }
};

export const createNewDeck = async (
  currentData,
  deckName,
  setDecks,
  setLoading
) => {
  try {
    setLoading(true);
    const body = {
      name: deckName,
    };
    const { data } = await newDeck("pokemon", body);
    setLoading(false);
  } catch (err) {
    console.log("error");
    // handleSetAlert("error", "Error fetching Units");
  }
};

export const fetchPokemonUnits = async (
  setLoading,
  page,
  setNextPage,
  currentData,
  setData,
  handleSetAlert
) => {
  try {
    setLoading(true);
    const { data, nextPage } = await getPokemonUnits(page);
    setData([...currentData, ...data]);
    setNextPage(nextPage);
    setLoading(false);
  } catch (err) {
    setLoading(false);
    // handleSetAlert("error", "Error fetching Set Units");
  }
};

export const fetchPMFilterValues = async (
  setLoading,
  setFilterSets,
  setFilterRarities,
  handleSetAlert
) => {
  try {
    setLoading(true);
    const sets = await getFilterSets("PM");
    const rarities = await getFilterRarities("PM");
    setFilterSets(sets);
    setFilterRarities(rarities);
    setLoading(false);
  } catch (err) {
    setLoading(false);
    // handleSetAlert("error", "Error fetching filter data.");
  }
};

export const fetchFilteredUnits = async (
  setLoading,
  page,
  setNextPage,
  filters,
  category,
  currentData,
  setData,
  handleSetAlert
) => {
  try {
    let query = { game: category };
    console.log(filters);
    if (filters.setID) {
      query = { ...query, setID: filters.setID };
    }
    if (filters.rarityID) {
      query = { ...query, rarityID: filters.rarityID };
    }
    if (filters.releaseDateStart) {
      query = { ...query, releaseDateStart: filters.releaseDateStart };
    }
    if (filters.releaseDateEnd) {
      query = { ...query, releaseDateEnd: filters.releaseDateEnd };
    }
    if (filters.priceLow) {
      query = { ...query, priceLow: filters.priceLow };
    }
    if (filters.priceHigh) {
      query = { ...query, priceHigh: filters.priceHigh };
    }
    setLoading(true);
    const { data, nextPage } = await getUnits(query, (page = 1));
    setData([...data]);
    setNextPage(nextPage);
    setLoading(false);
  } catch (err) {
    setLoading(false);
    // handleSetAlert("error", "Error fetching Units");
  }
};

export const fetchFiltersList = async (
  setLoading,
  handleSetAlert,
  setFilters
) => {
  try {
    const data = await getFilters("PM");
    setFilters([...data]);
  } catch (err) {
    setLoading(false);
    // handleSetAlert("error", "Error fetching Units");
  }
};

export const fetchSortsList = async (
  setLoading,
  handleSetAlert,
  setSortsList
) => {
  try {
    const data = await getSorts("PM");
    setSortsList([...data]);
  } catch (err) {
    setLoading(false);
    handleSetAlert("error", "Error fetching Units");
  }
};
