import { getWallets, getNFTs, getWalletTypes } from "./api";

export const fetchWallets = async (setLoading, setData, handleSetAlert) => {
  try {
    setLoading(true);
    const wallets = await getWallets();
    setData(wallets);
    setLoading(false);
  } catch (err) {
    setLoading(false);
    handleSetAlert("error", "Error fetching Wallets");
  }
};

export const fetchNFTs = async (
  setLoading,
  walletID,
  walletNickname,
  setData,
  handleSetAlert
) => {
  try {
    setLoading(true);
    const nfts = await getNFTs(walletID);
    setData(nfts);
    setLoading(false);
  } catch (err) {
    setLoading(false);
    handleSetAlert("error", `Error fetching NFTs for ${walletNickname}`);
  }
};

export const fetchWalletTypes = async (setLoading, setData, handleSetAlert) => {
  try {
    setLoading(true);
    const walletTypes = await getWalletTypes();
    setData(walletTypes);
    setLoading(false);
  } catch (err) {
    setLoading(false);
    handleSetAlert("error", "Error fetching wallet types.");
  }
};
