import React from "react";
import { FormControl, InputLabel, Select, MenuItem } from "@material-ui/core";

const CommonSelect = (props) => {
  const {
    classAppend,
    variant,
    customStyle,
    idAppend,
    label,
    value,
    data,
    dataKey,
    dataValue,
    dataName,
    handleChange,
  } = props;
  const inputClassName = classAppend
    ? `cmn-sel-ipt ${classAppend}`
    : "cmn-sel-ipt";
  const labelClassName = classAppend
    ? `cmn-sel-lbl ${classAppend}`
    : "cmn-sel-lbl";
  const selectClassName = classAppend ? `cmn-sel ${classAppend}` : "cmn-sel";
  const labelID = `cmn-sel-lbl-id-${idAppend}`;
  return (
    <FormControl
      className={inputClassName}
      variant={variant}
      style={customStyle}
    >
      <InputLabel className={labelClassName} id={labelID}>
        {label}
      </InputLabel>
      <Select
        className={selectClassName}
        labelID={labelID}
        id={`cmn-sel-id-${idAppend}`}
        value={value}
        onChange={handleChange}
        label={label}
      >
        {data.map((d) => (
          <MenuItem key={d[dataKey]} value={d[dataValue]}>
            {d[dataName]}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default CommonSelect;
