import React, { useState, useEffect } from "react";
import { Button } from "@material-ui/core";
import { fetchGames } from "./controller";
import config from "../../config";
import { Link } from "react-router-dom";
import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import Typography from "@material-ui/core/Typography";

const Admin = () => {
  const [loading, setLoading] = useState(false);
  const [games, setGames] = useState([]);

  useEffect(() => {
    const getGames = async () => {
      setLoading(true);
      const gamesRes = await fetchGames();
      setGames(gamesRes);
      setLoading(false);
    };
    getGames();
  }, []);

  return (
    <div className="homeContainer adminContainer adminGamesContainer">
      <div className="adminLinkList">
        <Breadcrumbs separator="›" aria-label="breadcrumb">
          <Typography color="textPrimary">Admin</Typography>
        </Breadcrumbs>
      </div>
      {loading && <h3>Loading...</h3>}
      {!loading && games.length > 0 && (
        <div>
          <h2>Games</h2>
          {games.map((game) => {
            return (
              <Link key={game.id} to={game.adminLink + "/sets"}>
                <div className="adminGames">
                  <img
                    src={`${config.mediaUrl}/${game.imageLink}`}
                    alt={`${game.name} logo`}
                  />
                  {/* <p>Manage Game</p> */}
                  <br />
                  <Button variant="contained" to={game.adminLink + "/sets"}>
                    Enter Game Portal
                  </Button>
                  {/* <p>{game.name}</p> */}
                </div>
              </Link>
            );
          })}
        </div>
      )}
    </div>
  );
};

export default Admin;
