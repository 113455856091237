import React, { useState, useEffect } from "react";
import clsx from "clsx";
import { fade, makeStyles, useTheme } from "@material-ui/core/styles";
import {
  Drawer,
  Divider,
  List,
  ListItem,
  ListItemText,
  IconButton,
  ListItemIcon,
} from "@material-ui/core";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import CssBaseline from "@material-ui/core/CssBaseline";
import MenuIcon from "@material-ui/icons/Menu";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import DClogo from "../../img/dc_logoV2.png";
import qs from "querystring";
import SearchIcon from "@material-ui/icons/Search";
import InputBase from "@material-ui/core/InputBase";
import { authCheck, modCheck } from "../../services/auth";
import {
  FiLogOut,
  FiSettings,
  FiHome,
  FiUser,
  FiTool,
  FiLogIn,
} from "react-icons/fi";
import { VscFiles } from "react-icons/vsc";
import { BiCollection } from "react-icons/bi";
import mtglogo from "../../img/magicLogo.png";
import pokelogo from "../../img/pokeLogo.png";
import bgLogo from "../../img/boardgame_logo.png";

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100.5% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: 36,
  },
  hide: {
    display: "none",
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: "nowrap",
  },
  search: {
    "position": "relative",
    "borderRadius": theme.shape.borderRadius,
    "backgroundColor": fade(theme.palette.common.white, 0.15),
    "&:hover": {
      backgroundColor: fade(theme.palette.common.white, 0.25),
    },
    "marginRight": theme.spacing(2),
    "marginLeft": 0,
    "width": "100%",
    [theme.breakpoints.up("sm")]: {
      marginLeft: theme.spacing(3),
      width: "auto",
    },
  },
  searchIcon: {
    padding: theme.spacing(0, 2),
    height: "100%",
    position: "absolute",
    pointerEvents: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  drawerOpen: {
    width: drawerWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerClose: {
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: "hidden",
    width: theme.spacing(7) - 6,
    [theme.breakpoints.up("sm")]: {
      width: theme.spacing(9) + 1,
    },
  },
  toolbar: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
}));

export default function MiniDrawer(props) {
  const { user } = props;
  const classes = useStyles();
  const theme = useTheme();
  const [open, setOpen] = React.useState(false);
  const [search, setSearch] = React.useState("");
  const isAuth = authCheck();
  const isMod = modCheck(user.groupIDs);
  const [activePage, setActivePage] = useState("");

  useEffect(() => {
    const pathname = window.location.pathname;
    // const nameKey = toString(pathname).replace("/", "");

    setActivePage(pathname);
  }, []);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = ({ currentTarget }) => {
    setOpen(false);
    if (currentTarget.getAttribute("name") != "chevron") {
      const route = `/${currentTarget.getAttribute("name")}`;
      window.location = route;
      setActivePage(currentTarget.getAttribute("name"));
    }
  };

  const handleKeypress = (e) => {
    //it triggers by pressing the enter key
    if (e.key === "Enter" && search !== "") {
      const query = {
        name: search,
      };
      const qString = qs.stringify(query);
      // TODO: Find better way to redirect
      // return <Redirect to={`/search?${qString}`} />;
      window.location.href = `/search?${qString}`;
    }
  };

  return (
    <div className={classes.root + " mobileNav"}>
      <CssBaseline />
      {/* <PrimarySearchAppBar
        handleDrawerOpen={handleDrawerOpen}
        inboxCount={5}
        notficationCount={10}
      /> */}
      <AppBar
        position="fixed"
        className={
          "navBar " +
          clsx(classes.appBar, {
            [classes.appBarShift]: open,
          })
        }
      >
        <Toolbar>
          <img src={DClogo} className="logo-mobile" alt="" />
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            className={clsx(classes.menuButton, {
              [classes.hide]: open,
            })}
          >
            <MenuIcon />
          </IconButton>
          {/* <img src={DClogo} alt="" /> */}
          {/* <h3>Displaychase</h3> */}
          <div className={classes.search + " searchBox"}>
            <div className={classes.searchIcon}>
              <SearchIcon className="searchIcon" />
            </div>
            <InputBase
              placeholder="Search Displaychase..."
              classes={{
                root: classes.inputRoot,
                input: classes.inputInput,
              }}
              className={"searchPlaceholder"}
              inputProps={{ "aria-label": "search" }}
              onKeyPress={(e) => handleKeypress(e)}
              onChange={(e) => setSearch(e.target.value)}
            />
          </div>
        </Toolbar>
      </AppBar>
      <Drawer
        variant="permanent"
        className={
          clsx(classes.drawer, {
            [classes.drawerOpen]: open,
            [classes.drawerClose]: !open,
          }) + " drawerContainer"
        }
        classes={{
          paper: clsx({
            [classes.drawerOpen]: open,
            [classes.drawerClose]: !open,
          }),
        }}
      >
        <div className={classes.toolbar}>
          <IconButton onClick={handleDrawerClose} name="chevron">
            {theme.direction === "rtl" ? (
              <ChevronRightIcon className="chevBtn" />
            ) : (
              <ChevronLeftIcon className="chevBtn" />
            )}
          </IconButton>
        </div>
        {/* </div> */}
        {/* {isAuth && (
        <React.Fragment>
          <IconButton
            className="userBtn"
            name="profile"
            onClick={handleDrawerClose}
          >
            <ListItemIcon>
              <IoPersonCircleOutline className="drawerIcon" />
            </ListItemIcon>
            <ListItemText className="" primary={user.username} />
          </IconButton>
          <Divider />
        </React.Fragment>
      )} */}
        <p className="navUsername topDrawerList">{props.user.username}</p>
        <List>
          <ListItem
            className={activePage === "/" ? "activePage" : ""}
            button
            name=""
            key="home"
            onClick={handleDrawerClose}
          >
            <ListItemIcon>
              <FiHome className="drawerIcon" />
            </ListItemIcon>
            <ListItemText className="" primary="Home" />
          </ListItem>
          {isAuth && (
            <ListItem
              className={activePage === "/collection" ? "activePage" : ""}
              button
              name="collection"
              key="collection"
              onClick={handleDrawerClose}
            >
              <ListItemIcon>
                <BiCollection className="drawerIcon" />
              </ListItemIcon>
              <ListItemText primary="Collection" />
            </ListItem>
          )}
          {isAuth && (
            <ListItem
              className={activePage === "/nft" ? "activePage" : ""}
              button
              name="nft"
              key="nft"
              onClick={handleDrawerClose}
            >
              <ListItemIcon>
                <VscFiles className="drawerIcon" />
              </ListItemIcon>
              <ListItemText primary="NFTs" />
            </ListItem>
          )}
        </List>
        <Divider />
        <List>
          <ListItem
            className={activePage === "/mtg" ? "activePage" : ""}
            button
            name="mtg"
            key="mtg"
            onClick={handleDrawerClose}
          >
            <ListItemIcon>
              <img src={mtglogo} />
            </ListItemIcon>
            <ListItemText className="" primary="Magic: The Gathering" />
          </ListItem>
          <ListItem
            className={activePage === "/pokemon" ? "activePage" : ""}
            button
            name="pokemon"
            key="pokemon"
            onClick={handleDrawerClose}
          >
            <ListItemIcon>
              <img className="pokeLogo" src={pokelogo} />
            </ListItemIcon>
            <ListItemText className="" primary="Pokemon TCG" />
          </ListItem>
          <ListItem
            className={activePage === "/boardgames" ? "activePage" : ""}
            button
            name="boardgames"
            key="boardgames"
            onClick={handleDrawerClose}
          >
            <ListItemIcon>
              <img className="bgLogo" src={bgLogo} />
            </ListItemIcon>
            <ListItemText className="" primary="Board Games" />
          </ListItem>
        </List>

        {/* <List>
        <ListItem button name="hcMain" key="hcMain" onClick={handleDrawerClose}>
          <ListItemText className="" primary="&nbsp;&nbsp;HeroClix" />
        </ListItem>
      </List> */}
        {/* <Divider />
      <List>
        <ListItem button name="sets" key="sets" onClick={handleDrawerClose}>
          <ListItemText primary="Set Master" />
        </ListItem>
        <ListItem
          button
          name="teamBuilder"
          key="teamBuilder"
          onClick={handleDrawerClose}
        >
          <ListItemText primary="Team Builder" />
        </ListItem>
        <ListItem
          button
          name="marketplace"
          key="marketplace"
          onClick={handleDrawerClose}
        >
          <ListItemText primary="Marketplace" />
        </ListItem>
      </List> */}
        {isMod && (
          <React.Fragment>
            <Divider />
            <List>
              <ListItem
                button
                name="admin"
                key="admin"
                onClick={handleDrawerClose}
              >
                <ListItemIcon>
                  <FiTool className="drawerIcon" />{" "}
                </ListItemIcon>
                <ListItemText primary="Admin Portal" />
              </ListItem>
            </List>
          </React.Fragment>
        )}
        {isAuth && (
          <React.Fragment>
            <Divider />
            <List>
              <ListItem
                className={activePage === "/userSettings" ? "activePage" : ""}
                button
                name="userSettings"
                key="userSettings"
                onClick={handleDrawerClose}
              >
                <ListItemIcon>
                  <FiSettings className="drawerIcon" />
                </ListItemIcon>
                <ListItemText primary="Settings" />
              </ListItem>
              <ListItem
                button
                name="logout"
                key="logout"
                onClick={handleDrawerClose}
              >
                <ListItemIcon>
                  <FiLogOut className="drawerIcon" />
                </ListItemIcon>
                <ListItemText primary="Logout" />
              </ListItem>
            </List>
          </React.Fragment>
        )}
        <h2 className="appNote">
          MOBILE APP <br /> COMING SOON
        </h2>
        {!isAuth && (
          <React.Fragment>
            <Divider />
            <List>
              <ListItem
                className={activePage === "/login" ? "activePage" : ""}
                button
                name="login"
                key="login"
                onClick={handleDrawerClose}
              >
                <ListItemIcon>
                  <FiLogIn className="drawerIcon" />
                </ListItemIcon>
                <ListItemText primary="Login" />
              </ListItem>
            </List>
          </React.Fragment>
        )}
      </Drawer>
      <main className={classes.content}></main>
    </div>
  );
}
