import React, { useState, useEffect, useRef } from "react";
import { Grid } from "@material-ui/core";
import Button from "../../shared/common/button";
import SearchIcon from "@material-ui/icons/Search";
import InputBase from "@material-ui/core/InputBase";
import { IoGrid } from "react-icons/io5";
import { AiFillCaretDown, AiFillCaretUp, AiOutlineMenu } from "react-icons/ai";
import { HiArrowCircleRight } from "react-icons/hi";
import { headerStyles } from "../styles";
import { Autocomplete } from "@material-ui/lab";
import { TextField } from "@material-ui/core";
import Input from "../../shared/common/input";
import DatePicker from "../../shared/common/date-picker";
import { fetchFiltersList, fetchSortsList } from "../controller";
import Tabs from "../../shared/tabs";

const useStyles = headerStyles;

const Header = (props) => {
  const {
    handleSetAlert,
    setActiveTab,
    activeTab,
    setView,
    activeView,
    setFiltersObj,
    filtersObj,
    sortBy,
    setSortBy,
    setSortByChange,
    filtersChangeFlag,
    setFiltersChangeFlag,
  } = props;

  const classes = useStyles();

  const [toggleFlag, setFlag] = useState(false);
  const [loading, setLoading] = useState(false);
  const [filtersList, setFiltersList] = useState([]);
  const [sortsList, setSortsList] = useState([]);
  const [filters, setFilters] = useState([]);
  const [showFilters, setShowFilters] = useState("inactive");
  const [showSorts, setShowSorts] = useState("inactive");
  const [searchRef, setSearchRef] = useState("");

  // const tabData = [
  //   {
  //     name: "Cards",
  //   },
  //   {
  //     name: "Trends",
  //   },
  //   {
  //     name: "Decks",
  //   },
  // ];

  useEffect(() => {
    // fetchFiltersList(setLoading, handleSetAlert, setFiltersList);
    // fetchSortsList(setLoading, handleSetAlert, setSortsList);
  }, [activeView]);

  const toggleSearchClass = () => {
    if (toggleFlag) {
      setFlag(false);
    } else {
      setFlag(true);
    }
  };

  const handleFilter = (event, name) => {
    var filterValue = event.target.innerText;
    const filterName = name;

    console.log("SEARCHREF: " + searchRef);

    if (filterName == "ProductName") {
      filterValue = searchRef;
    }

    if (filters.length > 0) {
      for (var i = 0; i < filters.length; i++) {
        if (filters[i].name && filters[i].name == filterName) {
          if (filterValue) {
            console.log("changing exisiting");
            var newFilters = filters;
            newFilters[i] = { name: filterName, values: [filterValue] };
            setFilters(newFilters);
            break;
          } else {
            console.log("taking filter out");
            var newFilters = filters.filter((item) => item.name !== filterName);
            setFilters(newFilters);
            break;
          }
        } else {
          console.log("adding new");
          const newFilters = filters.concat({
            name: filterName,
            values: [filterValue],
          });
          setFilters(newFilters);
        }
      }
    } else {
      console.log("adding first");
      const newFilters = filters.concat({
        name: filterName,
        values: [filterValue],
      });
      setFilters(newFilters);
    }
  };

  const applySearch = () => {
    if (filters.length > 0) {
      for (var i = 0; i < filters.length; i++) {
        if (filters[i].name && filters[i].name == "ProductName") {
          if (searchRef !== "") {
            var updateFilters = filters;
            updateFilters[i] = { name: "ProductName", values: [searchRef] };
            setFilters(updateFilters);
            break;
          } else {
            var updateFilters = filters.filter(
              (item) => item.name !== "ProductName"
            );
            setFilters(updateFilters);
          }
        } else {
          const updateFilters = filters.concat({
            name: "ProductName",
            values: [searchRef],
          });
          setFilters(updateFilters);
        }
      }
    } else {
      const updateFilters = filters.concat({
        name: "ProductName",
        values: [searchRef],
      });
      console.log(updateFilters);
      setFilters(updateFilters);
    }
  };

  const toggleView = (e) => {
    if (e.currentTarget.id == "cmn-btn-id-table" && activeView == "grid") {
      {
        setView("table");
      }
      localStorage.setItem("viewPref", "table");
    } else if (
      e.currentTarget.id == "cmn-btn-id-grid" &&
      activeView == "table"
    ) {
      {
        setView("grid");
      }
      localStorage.setItem("viewPref", "grid");
    }
  };

  const clearFilters = () => {
    setFilters([]);
    setFiltersObj([]);
    setShowFilters("inactive");
  };

  const applyFilters = () => {
    if (showFilters == "inactive") {
      setShowFilters("active");
    } else {
      setFiltersObj(filters);
      setFiltersChangeFlag(true);
      setShowFilters("inactive");
    }
  };

  const applySorts = (sortVal) => {
    setSortBy(sortVal);
    setSortByChange(true);
    setShowSorts("inactive");
  };

  const toggleFilters = () => {
    if (showFilters == "inactive") {
      setShowFilters("active");
      setShowSorts("inactive");
    } else {
      // setFiltersObj(filters)
      // setFiltersChangeFlag(true)
      setShowFilters("inactive");
      setShowSorts("inactive");
    }
  };
  const toggleSorts = () => {
    if (showSorts == "inactive") {
      setShowSorts("active");
      setShowFilters("inactive");
    } else {
      // setFiltersObj(filters)
      // setFiltersChangeFlag(true)
      setShowSorts("inactive");
      setShowFilters("inactive");
    }
  };

  return (
    <Grid item justify="center" sx={8}>
      <div className={"collHeader no-cards-header " + props.headerClasses}>
        <img src={props.gameLogo} className={props.game + "Logo"} alt="" />
        {/* <div
          className={
            toggleFlag
              ? classes.search + " searchBox searchCollection focused"
              : classes.search + " searchBox searchCollection"
          }
          onFocus={toggleSearchClass}
          onBlur={toggleSearchClass}
        >
          <div className={classes.searchIcon}>
            <SearchIcon />
          </div>
          <InputBase
            placeholder={"Search " + props.game + "..."}
            classes={{
              root: classes.inputRoot,
              input: classes.inputInput,
            }}
            onChange={(e) => {setSearchRef(e.target.value)}}
            className={"searchPlaceholder"}
            inputProps={{ "aria-label": "search" }}
          />
          <div className="searchSend" onClick={applySearch}>
            <HiArrowCircleRight />
          </div>
        </div> */}
        <div className="head-btn-container">
          <button
            disabled
            className={"sort-btn disabled"}
            // className={sortBy ? "sort-btn" : "sort-btn active-sort-btn"}
            onClick={toggleSorts}
          >
            Sort By{" "}
            {showSorts == "inactive" ? <AiFillCaretUp /> : <AiFillCaretDown />}
          </button>
          <button
            disabled
            className={"filters-btn disabled"}
            // className={
            //   filtersObj ? "filters-btn" : "filters-btn active-filters-btn"
            // }
            onClick={toggleFilters}
          >
            Filters{" "}
            {showFilters == "inactive" ? (
              <AiFillCaretUp />
            ) : (
              <AiFillCaretDown />
            )}
          </button>
        </div>
        <div
          className={
            showSorts == "active"
              ? "show sorts-container pm-sorts"
              : "hide sorts-container pm-sorts"
          }
        >
          {/* <ul> */}
          {sortsList.map((sort, index) => (
            <React.Fragment>
              {!sort.value.includes("Price") && (
                <Button
                  key={index}
                  variant="none"
                  size="small"
                  color="primary"
                  // idAppend="grid"
                  text={sort.text}
                  classAppend={
                    sortBy == sort.value ? "active sortby-btn" : "sortby-btn"
                  }
                  handleClick={() => {
                    applySorts(sort.value);
                  }}
                />
              )}
            </React.Fragment>
          ))}
          {/* </ul> */}
        </div>
        {/* <Tabs
            classAppend="tabButtonsContainer"
            activeTab={activeTab}
            setActiveTab={setActiveTab}
            data={tabData}
          /> */}

        <span>
          {/* {filtersList.map((filter) => <div>{filter.name}</div>)} */}
        </span>
        {!loading && (
          <div
            className={"filters-container pm-filters-container " + showFilters}
          >
            {filtersList.map((filter, index) => (
              <React.Fragment>
                {filter.inputType == "SingleValue" && (
                  <Autocomplete
                    disablePortal
                    className="textInput filterInput"
                    id="auto-filters-set-mtg"
                    // options={filterSets}
                    options={filter.items}
                    getOptionLabel={(option) => option.text}
                    // onFocus={(e) => {handlePrevFilter(e, filter.name)}}
                    onChange={(e) => {
                      handleFilter(e, filter.name);
                    }}
                    // onInputChange={(event, value) => {
                    //   console.log("TEST", value);
                    // }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label={filter.displayName}
                        variant="outlined"
                      />
                    )}
                    // onChange={(e, nv) => {nv ? setSetIDRef(nv.id) : setSetIDRef(undefined)}}q
                  />
                )}
                {filter.inputType == "MultipleValues" && (
                  <Autocomplete
                    disablePortal
                    className="textInput filterInput"
                    // multiple
                    // value={filter.displayName == "Rarity" ?
                    // rarityRef : filter.displayName == "Energy Type" ?
                    // energyRef : typeRef}
                    id="auto-filters-set-mtg"
                    // options={filterSets}
                    options={filter.items}
                    getOptionLabel={(option) => option.text}
                    // onChange={handleMulti}
                    onChange={(e) => {
                      handleFilter(e, filter.name);
                    }}
                    // onChange={(e, value) => {handleMulti(e, value, filter.name)}}
                    // onBlur={(e) => {handleFilter(e, filter.name)}}
                    // onInputChange={(event, value) => {
                    //   console.log("TEST", event);
                    // }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label={filter.displayName}
                        variant="outlined"
                      />
                    )}
                    // onChange={(e, nv) => {nv ? setSetIDRef(nv.id) : setSetIDRef(undefined)}}q
                  />
                )}
                {/* {filter.inputType == "Text" && filter.displayName !== "Card Text" &&
                <TextField onBlur={(e) => {handleFilter(e, filter.name)}} className="textInput filterInput" id="outlined-basic" label={filter.displayName} variant="outlined" />
              } */}
                {filter.displayName == "Product Name" && (
                  <TextField
                    value={searchRef}
                    onChange={(e) => setSearchRef(e.target.value)}
                    onBlur={(e) => {
                      handleFilter(e, filter.name);
                    }}
                    className="textInput filterInput"
                    id="outlined-basic"
                    label={"Card Name"}
                    variant="outlined"
                  />
                )}
                {/* 
              PRICE FILTERS COMING, NEED TO CLARIFY TCGP API EXPECTATIONS
              {filter.inputType == "Price" &&
                <React.Fragment>
                  <span>
                  <Input
                    classAppend="priceLowInputMTG priceInput filterInput"
                    idAppend="price-low-mtg"
                    label="Price Low"
                    variant="outlined"
                    value={priceLowRef}
                    handleChange={(e) => {e ? setPriceLowRef(e.target.value) : setPriceLowRef(undefined)}}
                  />
                  <p>-</p> 
                  <Input
                    classAppend="priceHighInputMTG priceInput filterInput"
                    idAppend="price-high-mtg"
                    label="Price High"
                    variant="outlined"
                    value={priceHighRef}
                    handleChange={(e) => {e ? setPriceHighRef(e.target.value) : setPriceHighRef(undefined)}}
                  />
                  </span>
                </React.Fragment>
              } */}
                {/* <div>{filter.name}</div> */}
              </React.Fragment>
            ))}

            <br />
            <button
              onClick={clearFilters}
              className="clear-btn filter-action-btn"
            >
              Clear All
            </button>
            <button
              onClick={applyFilters}
              className="done-btn filter-action-btn"
            >
              Apply
            </button>
          </div>
        )}
        <div className="view-btn-container">
          <div className="grid-btn-container">
            <Button
              variant="outlined"
              size="small"
              color="primary"
              idAppend="grid"
              text={<IoGrid />}
              classAppend={
                activeView == "grid" ? "grid-btn active-view-btn" : "grid-btn"
              }
              handleClick={toggleView}
            />
          </div>
          <div className="table-btn-container">
            <Button
              variant="outlined"
              size="small"
              color="primary"
              idAppend="table"
              text={<AiOutlineMenu />}
              classAppend={
                activeView == "table"
                  ? "table-btn active-view-btn"
                  : "table-btn"
              }
              handleClick={toggleView}
            />
          </div>
        </div>
      </div>
    </Grid>
  );
};

export default Header;
