import React from "react";
import { Redirect } from "react-router-dom";

const Logout = (props) => {
  const { handleClearUser } = props;
  handleClearUser();
  return <Redirect to="/" />;
};

export default Logout;
