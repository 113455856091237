import React from "react";
import { Button } from "@material-ui/core";

const CommonButton = (props) => {
  const {
    classAppend,
    idAppend,
    size,
    color,
    variant,
    customStyle,
    handleClick,
    text,
    btntype,
    disableRipple,
  } = props;
  const className = classAppend ? `cmn-btn ${classAppend}` : "cmn-btn";
  const id = idAppend ? `cmn-btn-id-${idAppend}` : "cmn-btn-id";
  return (
    <Button
      className={className}
      id={id}
      size={size}
      color={color}
      variant={variant}
      style={customStyle}
      onClick={handleClick}
      type={btntype}
      disableRipple={true}
    >
      {text}
    </Button>
  );
};

export default CommonButton;
