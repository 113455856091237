import React, { useState } from "react";
import http from "../../../services/http";
import config from "../../../config";
import { Grid, Typography, TextField, Button } from "@material-ui/core";
import LockIcon from "@material-ui/icons/Lock";

const UpdatePassword = ({ user, handleSetAlert }) => {
  const [currentPassword, setCurrentPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");

  const handleUpdatePassword = async () => {
    try {
      const reqBody = {
        currentPassword,
        newPassword,
      };
      const reqUrl = `${config.apiUrl}/users/updatePassword`;
      await http.put(reqUrl, reqBody);
      handleSetAlert("success", "Password successfully updated.");
    } catch (err) {
      handleSetAlert("error", err.response.data.message);
    }
  };

  return (
    <div className="update-password">
      <Grid
        container
        direction="column"
        justify="flex-start"
        alignItems="center"
        spacing={5}
      >
        <Grid
          container
          item
          direction="row"
          justify="flex-start"
          alignItems="center"
        >
          <Grid item xs={12}>
            <h2>
              <LockIcon className="settingsIcon" />
              Update Password
            </h2>
          </Grid>
        </Grid>
        <Grid
          container
          item
          direction="row"
          justify="space-evenly"
          alignItems="center"
          className="settingsBox"
        >
          <TextField
            id="current-password"
            label="Current Password"
            variant="outlined"
            type="password"
            onChange={(e) => setCurrentPassword(e.target.value)}
          />
        </Grid>
        <Grid
          container
          item
          direction="row"
          justify="space-evenly"
          alignItems="center"
          className="settingsBox"
        >
          <TextField
            id="new-password"
            label="New Password"
            variant="outlined"
            type="password"
            onChange={(e) => setNewPassword(e.target.value)}
          />
        </Grid>
        <Grid
          container
          item
          direction="row"
          justify="space-evenly"
          alignItems="center"
          className="settingsBox"
        >
          <Button className="settingsBtn" onClick={handleUpdatePassword}>
            Update Password
          </Button>
        </Grid>
      </Grid>
    </div>
  );
};

export default UpdatePassword;
