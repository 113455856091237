import React, { useState, useEffect } from "react";
import SearchUnits from "./units";
import qs from "querystring";

const Main = (props) => {
  const { location, handleSetAlert } = props;
  const { name } = qs.parse(location.search.substring(1));
  console.log(name);
  return (
    <div className="general-game-container search-page-container">
      <div className="units-container">
        <h1 className="searchhead">{'Results for "' + name + '"'}</h1>
        <SearchUnits name={name} handleSetAlert={handleSetAlert} />
      </div>
    </div>
  );
};

export default Main;
