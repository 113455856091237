import React, { Component } from "react";
import CollectionHeader from "../collections/components/header";
import { IconButton, Button } from "@material-ui/core";
import Fab from "@material-ui/core/Fab";
import AddIcon from "@material-ui/icons/Add";
import Team from "./teamTemplate";

const TeamBuilder = (props) => {
  return (
    <div className="teamBuilderContainer">
      <CollectionHeader />
      <Fab color="primary" aria-label="add" className="newTeamBtn">
        <AddIcon />
      </Fab>
    </div>
  );
};

export default TeamBuilder;
