import React, { useState } from "react";
import http from "../../../services/http";
import config from "../../../config";
import { Grid, Typography, TextField, Button } from "@material-ui/core";
import EmailIcon from "@material-ui/icons/Email";

const UpdateEmail = ({ user, handleSetUser, handleSetAlert }) => {
  const [currentEmail, setCurrentEmail] = useState("");
  const [newEmail, setNewEmail] = useState("");

  const handleUpdateEmail = async () => {
    try {
      if (currentEmail.toLowerCase().trim() !== user.email) {
        throw new Error("Invalid current email");
      }
      const reqBody = {
        newEmail,
      };
      const reqUrl = `${config.apiUrl}/users/updateEmail`;
      const res = await http.put(reqUrl, reqBody);
      const authToken = res.headers[config.authHeader];
      handleSetUser(authToken);
      handleSetAlert("success", "Email successfully updated.");
    } catch (err) {
      const errMsg = err.hasOwnProperty("response")
        ? err.response.data.message
        : err.message;
      handleSetAlert("error", errMsg);
    }
  };

  return (
    <div className="update-email">
      <Grid container direction="column" justify="flex-start" spacing={5}>
        <Grid container item direction="row" justify="flex-start">
          <Grid item xs={12}>
            <h2>
              <EmailIcon className="settingsIcon" />
              Update Email
            </h2>
          </Grid>
        </Grid>
        <Grid
          container
          item
          direction="row"
          justify="space-evenly"
          className="settingsBox"
        >
          <TextField
            id="current-email"
            label="Current Email"
            variant="outlined"
            onChange={(e) => setCurrentEmail(e.target.value)}
          />
        </Grid>
        <Grid
          container
          item
          direction="row"
          justify="space-evenly"
          className="settingsBox"
        >
          <TextField
            id="new-email"
            label="New Email"
            variant="outlined"
            onChange={(e) => setNewEmail(e.target.value)}
          />
        </Grid>
        <Grid
          container
          item
          direction="row"
          justify="space-evenly"
          className="settingsBox"
        >
          <Button className="settingsBtn" onClick={handleUpdateEmail}>
            Update Email
          </Button>
        </Grid>
      </Grid>
    </div>
  );
};

export default UpdateEmail;
