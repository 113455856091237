import React, { useState, useEffect } from "react";
import {
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Paper,
  Breadcrumbs,
  Typography,
  TextareaAutosize,
} from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import { useParams, Link } from "react-router-dom";
import {
  fetchMagicUnitFormLists,
  handleAddData,
  handleRemoveData,
  handleUpdateData,
} from "./controller";
import { insUnit, updUnitImage } from "./api";
import * as _ from "lodash";

const NewMagicUnit = (props) => {
  const { handleSetAlert } = props;
  let { setID } = useParams();
  setID = parseInt(setID);
  const [loading, setLoading] = useState(false);

  // Form Data
  const [unitTypeList, setUnitTypeList] = useState([]);
  const [setList, setSetList] = useState([]);
  const [rarityList, setRarityList] = useState([]);
  const [manaList, setManaList] = useState([]);
  const [supertypeLineList, setSupertypeLineList] = useState([]);
  const [typeLineList, setTypeLineList] = useState([]);
  const [subtypeLineList, setSubtypeLineList] = useState([]);
  const [keywordList, setKeywordList] = useState([]);

  // Form Values
  const [imageFile, setImageFile] = useState();
  const [name, setName] = useState();
  const [type, setType] = useState();
  const [set, setSet] = useState(setID);
  const [rarity, setRarity] = useState("");
  const [power, setPower] = useState();
  const [toughness, setToughness] = useState();
  const [collectorNumber, setCollectorNumber] = useState();
  const [artist, setArtist] = useState();
  const [mana, setMana] = useState([]);
  const [typeLine, setTypeLine] = useState([]);
  const [keywords, setKeywords] = useState([]);
  const [abilities, setAbilities] = useState([]);
  const [flavorText, setFlavorText] = useState([]);
  const [manaIdentity, setManaIdentity] = useState([]);

  useEffect(() => {
    fetchMagicUnitFormLists(
      handleSetAlert,
      setLoading,
      setUnitTypeList,
      setSetList,
      setRarityList,
      setManaList,
      setSupertypeLineList,
      setTypeLineList,
      setSubtypeLineList,
      setKeywordList
    );
  }, [handleSetAlert]);

  const handleSubmit = async () => {
    try {
      const newUnit = {
        name,
        type,
        set,
        rarity,
        power,
        toughness,
        collectorNumber,
        artist,
        mana,
        typeLine,
        keywords,
        abilities,
        flavorText,
      };
      const { id: newUnitID } = await insUnit(newUnit);
      if (imageFile) {
        await updUnitImage(newUnitID, imageFile);
      }
      handleSetAlert("success", "Unit Created!");
      window.location = `/admin/mtg/sets/${setID}/units`;
    } catch (err) {
      if (err.response.data.message) {
        handleSetAlert("warning", err.response.data.message);
      } else {
        handleSetAlert("error", err.message);
      }
    }
  };

  return (
    <div className="homeContainer adminContainer addUnitContainer">
      <div className="adminLinkList">
        <Breadcrumbs separator="›" aria-label="breadcrumb">
          <Link color="inherit" to={"/admin"}>
            Admin
          </Link>
          <Link color="inherit" to={"/admin/mtg/sets"}>
            MTG
          </Link>
          <Link color="inherit" to={`/admin/mtg/sets/${set}/units`}>
            {setList.length > 0
              ? setList[_.findIndex(setList, { id: setID })].name + " Cards"
              : "Set"}
          </Link>
          <Typography color="textPrimary">Add Card</Typography>
        </Breadcrumbs>
      </div>
      <h1>
        Add Card to{" "}
        {setList.length > 0
          ? setList[_.findIndex(setList, { id: setID })].name
          : "Set"}
      </h1>
      {loading && <h3>Loading...</h3>}
      {!loading && (
        <div>
          <h3>Card Image</h3>
          <input
            type="file"
            className="imageInput"
            name="Card Image Upload"
            onChange={(e) => setImageFile(e.target.files[0])}
          />
          <h3>Card Metadata</h3>
          <TextField
            className="addCardInput"
            id="unit-name"
            label="Unit Name"
            variant="outlined"
            value={name}
            onChange={(e) => setName(e.target.value)}
          />
          <br />
          <FormControl className="addCardInput" variant="outlined">
            <InputLabel id="type-selector-label">Type</InputLabel>
            <Select
              labelId="type-selector-label"
              id="type-selector"
              value={type}
              onChange={(e) => setType(e.target.value)}
              label="Type"
            >
              {unitTypeList.map((tMap) => (
                <MenuItem key={tMap.id} value={tMap.id}>
                  {tMap.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <br />
          <FormControl className="addCardInput" variant="outlined">
            <InputLabel id="set-selector-label">Set</InputLabel>
            <Select
              labelId="set-selector-label"
              id="set-selector"
              value={set}
              onChange={(e) => setSet(e.target.value)}
              label="Set"
            >
              {setList.map((sMap) => (
                <MenuItem key={sMap.id} value={sMap.id}>
                  {sMap.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <br />
          <FormControl className="addCardInput" variant="outlined">
            <InputLabel id="rarity-selector-label">Rarity</InputLabel>
            <Select
              labelId="rarity-selector-label"
              id="rarity-selector"
              value={rarity}
              onChange={(e) => setRarity(e.target.value)}
              label="Rarity"
            >
              {rarityList.map((rMap) => (
                <MenuItem key={rMap.id} value={rMap.id}>
                  {rMap.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <br />
          <TextField
            className="addCardInput"
            id="unit-power"
            label="Power"
            variant="outlined"
            value={power}
            onChange={(e) => setPower(e.target.value)}
          />
          <br />
          <TextField
            className="addCardInput"
            id="unit-toughness"
            label="Toughness"
            variant="outlined"
            value={toughness}
            onChange={(e) => setToughness(e.target.value)}
          />
          <br />
          <TextField
            className="addCardInput"
            id="unit-collector-number"
            label="Collector Number"
            variant="outlined"
            value={collectorNumber}
            onChange={(e) => setCollectorNumber(e.target.value)}
          />
          <br />
          <TextField
            className="addCardInput"
            id="unit-artist"
            label="Artist"
            variant="outlined"
            value={artist}
            onChange={(e) => setArtist(e.target.value)}
          />
          <br />
          <h3>Mana</h3>
          <TableContainer component={Paper}>
            <Table aria-label="Unit Mana">
              <TableBody>
                {mana.map((manaMap) => (
                  <TableRow key={manaMap.id}>
                    <TableCell component="th" scope="row">
                      {manaMap.colorName}
                    </TableCell>
                    <TableCell>{manaMap.name}</TableCell>
                    <TableCell>
                      <TextField
                        id={`mana-qty-${manaMap.id}`}
                        label="Quantity"
                        variant="outlined"
                        value={_.find(mana, { id: manaMap.id }).qty}
                        onChange={(e) =>
                          handleUpdateData(
                            manaMap.id,
                            "qty",
                            e.target.value,
                            mana,
                            setMana
                          )
                        }
                      />
                    </TableCell>
                    <TableCell>{manaMap.sortPosition}</TableCell>
                    <TableCell>
                      <Button
                        id={`btn-rmv-mana-${manaMap.id}`}
                        variant="outlined"
                        size="medium"
                        style={{ color: "red", borderColor: "red" }}
                        onClick={(e) => handleRemoveData(e, mana, setMana)}
                      >
                        Remove
                      </Button>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          <center>
            <Autocomplete
              id="unit-mana-picker"
              options={manaList}
              getOptionLabel={(option) => `${option.colorName}: ${option.name}`}
              style={{ width: 400 }}
              onChange={(event, newValue) => {
                handleAddData(newValue, mana, setMana, "manaCost");
              }}
              renderInput={(params) => (
                <TextField {...params} label="Mana" variant="outlined" />
              )}
            />
          </center>
          <br />
          <h3>Mana Identity</h3>
          <TableContainer component={Paper}>
            <Table aria-label="Unit Mana Identity">
              <TableBody>
                {manaIdentity.map((manaIdentityMap) => (
                  <TableRow key={manaIdentityMap.id}>
                    <TableCell component="th" scope="row">
                      {manaIdentityMap.colorName}
                    </TableCell>
                    <TableCell>{manaIdentityMap.name}</TableCell>
                    <TableCell>
                      <TextField
                        id={`manaIdentity-qty-${manaIdentityMap.id}`}
                        label="Quantity"
                        variant="outlined"
                        value={
                          _.find(manaIdentity, { id: manaIdentityMap.id }).qty
                        }
                        onChange={(e) =>
                          handleUpdateData(
                            manaIdentityMap.id,
                            "qty",
                            e.target.value,
                            manaIdentity,
                            setManaIdentity
                          )
                        }
                      />
                    </TableCell>
                    <TableCell>{manaIdentityMap.sortPosition}</TableCell>
                    <TableCell>
                      <Button
                        id={`btn-rmv-manaIdentity-${manaIdentityMap.id}`}
                        variant="outlined"
                        size="medium"
                        style={{ color: "red", borderColor: "red" }}
                        onClick={(e) =>
                          handleRemoveData(e, manaIdentity, setManaIdentity)
                        }
                      >
                        Remove
                      </Button>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          <center>
            <Autocomplete
              id="unit-mana-picker"
              options={manaList}
              getOptionLabel={(option) => `${option.colorName}: ${option.name}`}
              style={{ width: 400 }}
              onChange={(event, newValue) => {
                handleAddData(
                  newValue,
                  manaIdentity,
                  setManaIdentity,
                  "manaIdentity"
                );
              }}
              renderInput={(params) => (
                <TextField {...params} label="Mana" variant="outlined" />
              )}
            />
          </center>
          <h3>Type Line</h3>
          <TableContainer component={Paper}>
            <Table aria-label="Unit Type Line">
              <TableBody>
                {typeLine.map((tlMap) => (
                  <TableRow key={tlMap.id}>
                    <TableCell component="th" scope="row">
                      {tlMap.typeLineTypeName}
                    </TableCell>
                    <TableCell>{tlMap.name}</TableCell>
                    <TableCell>{tlMap.sortPosition}</TableCell>
                    <TableCell>
                      <Button
                        id={`btn-rmv-typeline-${tlMap.id}`}
                        variant="outlined"
                        size="medium"
                        style={{ color: "red", borderColor: "red" }}
                        onClick={(e) =>
                          handleRemoveData(e, typeLine, setTypeLine)
                        }
                      >
                        Remove
                      </Button>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          <center>
            <Autocomplete
              id="unit-supertype-picker"
              options={supertypeLineList}
              getOptionLabel={(option) => `${option.name}`}
              style={{ width: 400 }}
              onChange={(event, newValue) => {
                handleAddData(newValue, typeLine, setTypeLine, "typeLine");
              }}
              renderInput={(params) => (
                <TextField {...params} label="Supertype" variant="outlined" />
              )}
            />
            <Autocomplete
              id="unit-type-picker"
              options={typeLineList}
              getOptionLabel={(option) => `${option.name}`}
              style={{ width: 400 }}
              onChange={(event, newValue) => {
                handleAddData(newValue, typeLine, setTypeLine, "typeLine");
              }}
              renderInput={(params) => (
                <TextField {...params} label="Type" variant="outlined" />
              )}
            />
            <Autocomplete
              id="unit-subtype-picker"
              options={subtypeLineList}
              getOptionLabel={(option) => `${option.name}`}
              style={{ width: 400 }}
              onChange={(event, newValue) => {
                handleAddData(newValue, typeLine, setTypeLine, "typeLine");
              }}
              renderInput={(params) => (
                <TextField {...params} label="Subtype" variant="outlined" />
              )}
            />
          </center>
          <h3>Keywords</h3>
          <TableContainer component={Paper}>
            <Table aria-label="Unit Keywords">
              <TableBody>
                {keywords.map((keywordMap) => (
                  <TableRow key={keywordMap.id}>
                    <TableCell>{keywordMap.name}</TableCell>
                    <TableCell>
                      <TextareaAutosize
                        id={`keywords-detail-${keywordMap.id}`}
                        label="Details"
                        variant="outlined"
                        value={_.find(keywords, { id: keywordMap.id }).details}
                        onChange={(e) =>
                          handleUpdateData(
                            keywordMap.id,
                            "details",
                            e.target.value,
                            keywords,
                            setKeywords
                          )
                        }
                      />
                    </TableCell>
                    <TableCell>{keywordMap.sortPosition}</TableCell>
                    <TableCell>
                      <Button
                        id={`btn-rmv-keyword-${keywordMap.id}`}
                        variant="outlined"
                        size="medium"
                        style={{ color: "red", borderColor: "red" }}
                        onClick={(e) =>
                          handleRemoveData(e, keywords, setKeywords)
                        }
                      >
                        Remove
                      </Button>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          <br />
          <center>
            <Autocomplete
              id="unit-keyword-picker"
              options={keywordList}
              getOptionLabel={(option) => `${option.name}`}
              style={{ width: 400 }}
              onChange={(event, newValue) => {
                handleAddData(newValue, keywords, setKeywords, "keywords");
              }}
              renderInput={(params) => (
                <TextField {...params} label="Keyword" variant="outlined" />
              )}
            />
          </center>
          <h3>Abilities</h3>
          <TableContainer component={Paper}>
            <Table aria-label="Unit Abilities">
              <TableBody>
                {abilities.map((abilitiesMap) => (
                  <TableRow key={abilitiesMap.id}>
                    <TableCell>
                      <TextareaAutosize
                        id={`abilities-text-${abilitiesMap.id}`}
                        label="Text"
                        variant="outlined"
                        value={_.find(abilities, { id: abilitiesMap.id }).text}
                        onChange={(e) =>
                          handleUpdateData(
                            abilitiesMap.id,
                            "text",
                            e.target.value,
                            abilities,
                            setAbilities
                          )
                        }
                      />
                    </TableCell>
                    <TableCell>{abilitiesMap.sortPosition}</TableCell>
                    <TableCell>
                      <Button
                        id={`btn-rmv-ability-${abilitiesMap.id}`}
                        variant="outlined"
                        size="medium"
                        style={{ color: "red", borderColor: "red" }}
                        onClick={(e) =>
                          handleRemoveData(e, abilities, setAbilities)
                        }
                      >
                        Remove
                      </Button>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          <br />
          <Button
            variant="outlined"
            size="large"
            color="primary"
            onClick={() => {
              handleAddData({}, abilities, setAbilities, "abilities");
            }}
          >
            Add Ability
          </Button>
          <h3>Flavor Text</h3>
          <TableContainer component={Paper}>
            <Table aria-label="Unit Abilities">
              <TableBody>
                {flavorText.map((flavorTextMap) => (
                  <TableRow key={flavorTextMap.id}>
                    <TableCell>
                      <TextareaAutosize
                        id={`flavorText-text-${flavorTextMap.id}`}
                        label="Text"
                        variant="outlined"
                        multiline
                        value={
                          _.find(flavorText, { id: flavorTextMap.id }).text
                        }
                        onChange={(e) =>
                          handleUpdateData(
                            flavorTextMap.id,
                            "text",
                            e.target.value,
                            flavorText,
                            setFlavorText
                          )
                        }
                      />
                    </TableCell>
                    <TableCell>{flavorTextMap.sortPosition}</TableCell>
                    <TableCell>
                      <Button
                        id={`btn-rmv-flavorText-${flavorTextMap.id}`}
                        variant="outlined"
                        size="medium"
                        style={{ color: "red", borderColor: "red" }}
                        onClick={(e) =>
                          handleRemoveData(e, flavorText, setFlavorText)
                        }
                      >
                        Remove
                      </Button>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          <Button
            variant="outlined"
            size="large"
            color="primary"
            onClick={() => {
              handleAddData({}, flavorText, setFlavorText, "flavorText");
            }}
          >
            Add Flavor Text
          </Button>
          <br />
          <Button
            className="addUnitBtn"
            variant="outlined"
            size="large"
            style={{ color: "green", borderColor: "green" }}
            onClick={handleSubmit}
          >
            Add Card
          </Button>
        </div>
      )}
    </div>
  );
};

export default NewMagicUnit;
