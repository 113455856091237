import * as React from "react";
import { useState, useEffect, useRef, useCallback } from "react";
import config from "../../../config";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import Moment from "moment";
import CustModal from "./modal";
import { insCollectionUnit, delCollectionUnit } from "../../collections/api";
import { authCheck } from "../../../services/auth";

export default function DenseTable(props) {
  const { units, handleSetAlert, handleUpdateHasUnit } = props;

  const [toggleModal, setToggleModal] = useState("hide");
  const [currentUnit, setCurrentUnit] = useState();
  const [hasMore, setHasMore] = useState(true);
  const [loading, setLoading] = useState(false);
  const isAuth = authCheck();

  const openModal = (unit) => {
    setCurrentUnit(unit);
    console.log(unit.name + " clicked");
    setToggleModal("show");
  };

  const handleCollectionAdd = async (id) => {
    try {
      const unitID = id;
      const { qty } = await insCollectionUnit(unitID);
      handleUpdateHasUnit(unitID, qty);
    } catch (err) {
      if (err.response) {
        handleSetAlert("warning", err.response.data.message);
      } else {
        handleSetAlert("error", err.message);
      }
    }
  };

  const handleCollectionRemove = async (id) => {
    try {
      const unitID = id;
      const { qty } = await delCollectionUnit(unitID);
      handleUpdateHasUnit(unitID, qty);
    } catch (err) {
      if (err.response) {
        handleSetAlert("warning", err.response.data.message);
      } else {
        handleSetAlert("error", err.message);
      }
    }
  };

  const observer = useRef();
  const lastUnitRef = useCallback(
    (node) => {
      if (loading) return;
      if (observer.current) observer.current.disconnect();
      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting && hasMore == true) {
          console.log("add units");
          props.loadMore();
        }
      });
      if (node) observer.current.observe(node);
    },
    [loading, hasMore, props.units]
  );

  const closeModal = () => {
    console.log("closing modal");
    setToggleModal("hide");
  };

  const handleSortBy = (e) => {
    console.log("sort by " + e.target.text);
  };

  return (
    <>
      <CustModal
        unit={currentUnit}
        modalRef={toggleModal}
        hideModal={closeModal}
      />
      <TableContainer className="table-view-container" component={Paper}>
        <Table sx={{ minWidth: 650 }} size="small" aria-label="a dense table">
          <TableHead>
            <TableRow>
              <TableCell></TableCell>
              <TableCell onClick={() => handleSortBy} align="center">
                Card ID
              </TableCell>
              <TableCell onClick={() => handleSortBy} align="center">
                Name
              </TableCell>
              <TableCell align="center">Price</TableCell>
              {isAuth && <TableCell align="center">Owned</TableCell>}
              <TableCell align="center">Rarity</TableCell>
              {/* <TableCell align="center">Set</TableCell> */}
              <TableCell align="center">Type/Subtype</TableCell>
              {/* <TableCell align="center">Release <br/> Date</TableCell> */}
              {/* <TableCell align="center">Category</TableCell> */}
            </TableRow>
          </TableHead>
          <TableBody>
            {units.map((unit, index) => {
              if (units.length === index + 1) {
                return (
                  <TableRow
                    key={unit.name}
                    ref={lastUnitRef}
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                    className="lastRow"
                  >
                    <TableCell
                      align="center"
                      className="pictd"
                      onClick={() => openModal(unit)}
                    >
                      View
                    </TableCell>
                    <TableCell align="center">
                      {!unit.attributes[1] ||
                      unit.attributes[1].name !== "Number"
                        ? "No ID Found"
                        : unit.attributes[1].value}
                    </TableCell>
                    <TableCell align="center">
                      {unit.cleanName ? unit.cleanName : "No Name Found"}
                    </TableCell>
                    <TableCell align="center">
                      $
                      {unit.prices[0]
                        ? parseFloat(unit.prices[0].market).toFixed(2)
                        : "Still Gathering Price Data"}
                    </TableCell>

                    {isAuth && unit.collectionUnitQty !== 0 && (
                      <TableCell align="center">
                        <button onClick={handleCollectionRemove}>-</button>
                        <span className="qtySpan">
                          {unit.collectionUnitQty}
                        </span>
                        <button onClick={handleCollectionAdd}>+</button>
                      </TableCell>
                    )}
                    {isAuth && !unit.collectionUnitQty && (
                      <TableCell align="center">
                        <button disabled>-</button>
                        <span className="qtySpan">0</span>
                        <button onClick={handleCollectionAdd}>+</button>
                      </TableCell>
                    )}
                    <TableCell align="center">
                      {unit.attributes[0] && unit.attributes[0].name == "Rarity"
                        ? unit.attributes[0].value
                        : "No Rarity Found"}
                    </TableCell>
                    {/* {unit.groupName &&
                  <TableCell align="center">{unit.groupName}</TableCell>
                }
                {!unit.groupName &&
                  <TableCell align="center">No Set Name Found</TableCell>
                } */}
                    <TableCell align="center">
                      {unit.attributes[2] &&
                      unit.attributes[2].name == "SubType" &&
                      unit.attributes[2].value !== ""
                        ? unit.attributes[2].value
                        : "No Type Data Found"}
                    </TableCell>
                  </TableRow>
                );
              } else {
                return (
                  <TableRow
                    key={unit.name}
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell
                      align="center"
                      className="pictd"
                      onClick={() => openModal(unit)}
                    >
                      View
                    </TableCell>
                    <TableCell align="center">
                      {!unit.attributes[1] ||
                      unit.attributes[1].name !== "Number"
                        ? "No ID Found"
                        : unit.attributes[1].value}
                    </TableCell>
                    <TableCell align="center">
                      {unit.cleanName ? unit.cleanName : "No Name Found"}
                    </TableCell>
                    <TableCell align="center">
                      $
                      {unit.prices[0]
                        ? parseFloat(unit.prices[0].market).toFixed(2)
                        : "Still Gathering Price Data"}
                    </TableCell>

                    {isAuth && unit.collectionUnitQty !== 0 && (
                      <TableCell align="center">
                        <button onClick={handleCollectionRemove}>-</button>
                        <span className="qtySpan">
                          {unit.collectionUnitQty}
                        </span>
                        <button onClick={handleCollectionAdd}>+</button>
                      </TableCell>
                    )}
                    {isAuth && !unit.collectionUnitQty && (
                      <TableCell align="center">
                        <button disabled>-</button>
                        <span className="qtySpan">0</span>
                        <button onClick={handleCollectionAdd}>+</button>
                      </TableCell>
                    )}
                    <TableCell align="center">
                      {unit.attributes[0] && unit.attributes[0].name == "Rarity"
                        ? unit.attributes[0].value
                        : "No Rarity Found"}
                    </TableCell>
                    {/* {unit.groupName &&
                  <TableCell align="center">{unit.groupName}</TableCell>
                }
                {!unit.groupName &&
                  <TableCell align="center">No Set Name Found</TableCell>
                } */}
                    <TableCell align="center">
                      {unit.attributes[2] &&
                      unit.attributes[2].name == "SubType" &&
                      unit.attributes[2].value !== ""
                        ? unit.attributes[2].value
                        : "No Type Data Found"}
                    </TableCell>
                  </TableRow>
                );
              }
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
}

// import * as React from 'react';
// import {useState} from 'react';
// import config from "../../../config"
// import Table from '@material-ui/core/Table';
// import TableBody from '@material-ui/core/TableBody';
// import TableCell from '@material-ui/core/TableCell';
// import TableContainer from '@material-ui/core/TableContainer';
// import TableHead from '@material-ui/core/TableHead';
// import TableRow from '@material-ui/core/TableRow';
// import Paper from '@material-ui/core/Paper';
// import Moment from "moment";
// import CustModal from './modal';

// export default function DenseTable(props) {

//   const [toggleModal, setToggleModal] = useState("hide");
//   const [currentUnit, setCurrentUnit] = useState();

//   const openModal = (unit) => {
//     setCurrentUnit(unit)
//     console.log(unit.name + " clicked")
//     setToggleModal("show")
//   };

//   const closeModal = () => {
//     console.log("closing modal")
//     setToggleModal("hide")
//   };

//   const handleSortBy = (e) => {
//     console.log("sort by " + e.target.text)
//   }

//   return (
//     <>
//     <CustModal unit={currentUnit} modalRef={toggleModal} hideModal={closeModal} />
//     <TableContainer className="table-view-container" component={Paper}>
//       <Table sx={{ minWidth: 650 }} size="small" aria-label="a dense table">
//         <TableHead>
//           <TableRow>
//             <TableCell></TableCell>
//             <TableCell onClick={() => handleSortBy} align="center">Card ID</TableCell>
//             <TableCell onClick={() => handleSortBy} align="center">Name</TableCell>
//             <TableCell align="center">Price</TableCell>
//             <TableCell align="center">Owned</TableCell>
//             <TableCell align="center">Rarity</TableCell>
//             <TableCell align="center">Set</TableCell>
//             <TableCell align="center">Type/Subtype</TableCell>
//             {/* <TableCell align="center">Release <br/> Date</TableCell> */}
//             {/* <TableCell align="center">Category</TableCell> */}
//           </TableRow>
//         </TableHead>
//         <TableBody>
//           {props.units.map((unit) => (
//             <TableRow
//               key={unit.name}
//               sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
//             >
//               <TableCell align="center" className="pictd" onClick={() => openModal(unit)}>View</TableCell>
//               {unit.attributes[1].value &&
//                 <TableCell align="center">{unit.attributes[1].value}</TableCell>
//               }
//               {!unit.attributes[1].value &&
//                 <TableCell align="center">No ID Found</TableCell>
//               }
//               {unit.name &&
//                 <TableCell align="center">{unit.cleanName}</TableCell>
//               }
//               {!unit.name &&
//                 <TableCell align="center">No Name Found</TableCell>
//               }
//               {unit.prices[0] &&
//                 <TableCell align="center">${parseFloat(unit.prices[0].market).toFixed(2)}</TableCell>
//               }
//               {!unit.prices[0] &&
//                 <TableCell align="center">Still Gathering Price Data</TableCell>
//               }
//               {unit.collectionUnitQty !== 0 &&
//                 <TableCell align="center"><button>-</button>&nbsp;{unit.collectionUnitQty}&nbsp;<button>+</button></TableCell>
//               }
//               {!unit.collectionUnitQty &&
//                 <TableCell align="center"><button>-</button>&nbsp;0&nbsp;<button>+</button></TableCell>
//               }
//               {unit.attributes[0] &&
//               <TableCell align="center">{unit.attributes[0].value}</TableCell>
//                 // <TableCell align="center">{(unit) => {
//                 //   if(unit.attributes[0].value == "R"){
//                 //     return "Rare"
//                 //   } else if(unit.attributes[0].value == "M") {
//                 //     return "Mythic"
//                 //   } else if(unit.attributes[0].value == "U") {
//                 //     return "Uncommon"
//                 //   } else if(unit.attributes[0].value == "C") {
//                 //     return "Common"
//                 //   } else if(unit.attributes[0].value == "P") {
//                 //     return "Promo"
//                 //   } else if(unit.attributes[0].value == "L") {
//                 //     return "Land"
//                 //   } else if(unit.attributes[0].value == "S") {
//                 //     return "Special"
//                 //   } else {
//                 //     return unit.attributes[0].value
//                 //   }
//                 // }
//                 //   }</TableCell>
//               }
//               {!unit.attributes[0] &&
//                 <TableCell align="center">No Rarity Found</TableCell>
//               }
//               {unit.group &&
//                 <TableCell align="center">{unit.group.name}</TableCell>
//               }
//               {!unit.group &&
//                 <TableCell align="center">No Set Name Found</TableCell>
//               }
//               {unit.attributes[2] &&
//                 <TableCell align="center">{unit.attributes[2].value}</TableCell>
//               }
//               {!unit.attributes[2] &&
//                 <TableCell align="center">No Type Data Found</TableCell>
//               }
//               {/* {unit.set &&
//                 <TableCell align="left">{Moment(unit.set.releaseDate).format('MMM d, YYYY')}</TableCell>
//               }
//               {!unit.set &&
//                 <TableCell align="left">No Release Date Found</TableCell>
//               } */}
//               {/* {unit.game.name &&
//                 <TableCell align="left">{unit.game.name}</TableCell>
//               }
//               {!unit.game.name &&
//                 <TableCell align="left">No Category Found</TableCell>
//               } */}
//             </TableRow>
//           ))}
//         </TableBody>
//       </Table>
//     </TableContainer>
//     </>
//   );
// }
