import React, { useState, useEffect } from "react";
import { Grid } from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import { TextField } from "@material-ui/core";
import Box from "@mui/material/Box";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";

const TabHead = (props) => {
  const { handleSetAlert, setActiveTab, activeTab } = props;
  const [loading, setLoading] = useState(false);

  const handleChange = (event) => {
    setActiveTab(event.target.value);
  };

  return (
    <Grid item justify="center" sx={8}>
      <div className="tab-head-container">
        <Box sx={{ minWidth: 120 }}>
          <FormControl fullWidth>
            <Select
              id="simple-select"
              value={activeTab}
              onChange={handleChange}
              disableUnderline
              className="tab-selection"
            >
              <MenuItem value={"Cards"}>MTG Cards</MenuItem>
              <MenuItem value={"Deck Builder"}>Deck Builder</MenuItem>
            </Select>
          </FormControl>
        </Box>
      </div>
    </Grid>
  );
};

export default TabHead;
