import React, { useState } from "react";
import { Redirect } from "react-router-dom";
import {
  Grid,
  Typography,
  TextField,
  Button,
  Snackbar,
} from "@material-ui/core";
import MuiAlert from "@material-ui/lab/Alert";
import http from "../../services/http";
import config from "../../config";
import { authCheck } from "../../services/auth";
// import bgimg from "./../../img/dc_login.png"
import Register from "./register";
import { IoIosArrowForward } from "react-icons/io";

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const Login = (props) => {
  const { handleSetUser } = props;
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [regClasses, setRegClasses] = useState("register-container");
  const [showAlert, setShowAlert] = useState(false);
  const [alertSeverity, setAlertSeverity] = useState("");
  const [alert, setAlert] = useState("");

  if (authCheck()) {
    return <Redirect to="/" />;
  }

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setShowAlert(false);
  };

  const handleLoginClick = async () => {
    try {
      const reqBody = {
        email,
        password,
      };
      const reqUrl = `${config.apiUrl}/auth`;
      const res = await http.post(reqUrl, reqBody);
      const authToken = res.headers[config.authHeader];
      handleSetUser(authToken);
      return <Redirect to="/" />;
    } catch (err) {
      setAlertSeverity("error");
      setAlert(err.response.data.message);
      setShowAlert(true);
    }
  };

  const handleKeypress = (e) => {
    //it triggers by pressing the enter key
    if (e.key === "Enter") {
      handleLoginClick();
    }
  };

  return (
    <React.Fragment>
      <div className="login-page">
        {/* <img className="loginBG" src={bgimg} alt="" /> */}
        <Grid
          container
          direction="column"
          justify="flex-start"
          alignItems="center"
          spacing={5}
          className="loginContainer"
        >
          <Grid
            container
            item
            direction="row"
            justify="flex-start"
            alignItems="center"
          >
            <Grid item xs={12}>
              <Typography variant="h2">Login</Typography>
            </Grid>
          </Grid>
          <Grid
            container
            item
            direction="row"
            justify="space-evenly"
            alignItems="center"
          >
            <TextField
              id="login-email"
              label="Email"
              variant="outlined"
              onChange={(e) => setEmail(e.target.value)}
              onKeyPress={handleKeypress}
            />
          </Grid>
          <Grid
            container
            item
            direction="row"
            justify="space-evenly"
            alignItems="center"
          >
            <TextField
              id="login-password"
              label="Password"
              variant="outlined"
              type="password"
              onChange={(e) => setPassword(e.target.value)}
              onKeyPress={(e) => handleKeypress(e)}
            />
          </Grid>
          <br />
          <Grid
            container
            item
            direction="row"
            justify="space-evenly"
            alignItems="center"
          >
            <Button
              variant="outlined"
              size="large"
              color="primary"
              onClick={handleLoginClick}
            >
              Login
            </Button>
          </Grid>
          {/* TODO: Alpha */}
          {/* <span>- &nbsp; or &nbsp; -</span>
          <Grid
            container
            item
            direction="row"
            justify="space-evenly"
            alignItems="center"
          >
            <Button
              variant="outlined"
              size="large"
              color="primary"
              onClick={() => {
                window.location = "/register";
              }}
              btntype="submit"
            >
              Register &nbsp;
              <IoIosArrowForward />
            </Button>
          </Grid> */}
        </Grid>
        <Snackbar
          open={showAlert}
          autoHideDuration={6000}
          onClose={handleClose}
        >
          <Alert onClose={handleClose} severity={alertSeverity}>
            {alert}
          </Alert>
        </Snackbar>
      </div>
    </React.Fragment>
  );
};

export default Login;
