import React, { useState } from "react";
import {
  createNewDeck,
  fetchDecks,
  fetchDeckUnits,
  fetchUnits,
} from "../controller";
import * as _ from "lodash";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import UnitMod from "../../shared/unit-module";
import Button from "../../shared/common/button";
import { MdLibraryAdd, MdOutlineCancelPresentation } from "react-icons/md";
import { HiOutlineDotsHorizontal, HiOutlineTrash } from "react-icons/hi";
import { IoCloseSharp } from "react-icons/io5";
import { TbNewSection } from "react-icons/tb";
import { BiExit } from "react-icons/bi";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
// import { concat } from "lodash";
import TextField from "@mui/material/TextField";
import { delDeck } from "../../shared/api";

const DBDecks = (props) => {
  const {
    loading,
    setLoading,
    decks,
    setDecks,
    deckUnits,
    setDeckUnits,
    selectMode,
    setSelectMode,
    setCardAddList,
    selectIndicator,
    setSelectIndicator,
    handleDeckClick,
    handleToggleDeckView,
    setCurrentDeckUnits,
    setCurrentDeckID,
    currentDeckID,
    setCurrentDeckName,
    showDecks,
    setShowDecks,
    handleDeleteDeck,
  } = props;

  const [newDeckToggle, setNewDeckToggle] = useState(false);
  const [newDeckName, setNewDeckName] = useState("");

  const handleNewDeck = () => {
    if (newDeckToggle) {
      console.log("Already creating new deck!");
    } else {
      setNewDeckToggle(true);
    }
  };

  const handleDeckUnits = async (id) => {
    fetchDeckUnits(setLoading, id, setCurrentDeckUnits);
  };

  const handleSubmitNewDeck = () => {
    console.log("creating deck: ", newDeckName);
    createNewDeck(decks, newDeckName, setDecks, setLoading);
    setNewDeckToggle(false);
    setNewDeckName("");
  };

  const handleCancelNewDeck = () => {
    setNewDeckName("");
    setNewDeckToggle(false);
  };

  return (
    <div className={showDecks ? "decks-container" : "hide"}>
      <h1>Your Decks</h1>
      <Button
        classAppend={" hideDecksBtn"}
        text={<IoCloseSharp />}
        handleClick={() =>
          showDecks ? setShowDecks(false) : setShowDecks(true)
        }
      />
      <List>
        {decks.map((deck, index) => {
          return (
            <div className="deck">
              {/* <Button
                text={<HiOutlineTrash />}
                handleClick={() => {
                  handleDeleteDeck("mtg", deck.id);
                  if (deck.id == currentDeckID) {
                    setCurrentDeckUnits([]);
                    setShowDecks(true);
                    setCurrentDeckID();
                    setCurrentDeckName();
                  }
                }}
                classAppend={"delete-deck-btn"}
              /> */}

              {/* <Button
                text={<HiOutlineDotsHorizontal />}
                handleClick={}
                classAppend={"morebtn"}
              /> */}
              {/* <Button
                text={<MdLibraryAdd />}
                handleClick={() => {
                  selectMode
                    ? selectIndicator == deck.id
                      ? setSelectMode(false)
                      : setSelectMode(true)
                    : setSelectMode(true);
                  setSelectIndicator(deck.id);
                  setCardAddList([]);
                }}
                classAppend={
                  selectMode
                    ? selectIndicator == deck.id
                      ? "bulk-add-btn active-bulk-add"
                      : "bulk-add-btn"
                    : "bulk-add-btn"
                }
              /> */}
              <ListItem
                onClick={() => {
                  handleToggleDeckView(deck.id);
                  setCurrentDeckID(deck.id);
                  setCurrentDeckName(deck.name);
                  setShowDecks(false);
                  handleDeckUnits(deck.id);
                }}
              >
                {deck.name && <span className="deck-name">{deck.name}</span>}
                <span className="qty">
                  {/* {async () => getDeckUnits(deck.id, "mtg").length} */}
                </span>
              </ListItem>
            </div>
          );
        })}
        <div className={newDeckToggle ? "deck new-deck-container" : "hide"}>
          <ListItem>
            <TextField
              hiddenLabel
              id="filled-hidden-label-small"
              placeholder="Untitled Deck"
              size="small"
              value={newDeckName}
              onChange={(e) => {
                setNewDeckName(e.target.value);
              }}
            />
            <div className="submit-deck-btns">
              <Button
                classAppend={"submit-btn"}
                text={<BiExit />}
                handleClick={handleSubmitNewDeck}
              />
              <Button
                classAppend={"cancel-btn"}
                text={<MdOutlineCancelPresentation />}
                handleClick={handleCancelNewDeck}
              />
            </div>
          </ListItem>
        </div>
        <Button
          text={_.concat(<TbNewSection />, "New Deck")}
          handleClick={handleNewDeck}
          classAppend={"new-deck-btn"}
        />
      </List>
      {/* )} */}
    </div>
  );
};

export default DBDecks;
