import React, { useState, useEffect } from "react";
import config from "../../config";
import mtglogo from "./../../img/magicLogo.png";
import Button from "./common/button";
import { insCollectionUnit, delCollectionUnit } from "../collections/api";
import { authCheck } from "../../services/auth";
import noImage from "../../img/noImage.png";
import { fetchUnitDetails } from "../mtg/controller";

const UnitMod = (props) => {
  const { onModalToggle, unit, gamecode, unitQty } = props;
  const isAuth = authCheck();

  return (
    <div className={isAuth ? "unit-mod" : "unit-mod no-user-mod"}>
      <div className="unit-header">
        {unit.game && (
          <p className={"gamecode " + unit.game.code}>
            <img src={`${config.mediaUrl}/${unit.game.imageLink}`} />
          </p>
        )}
        {/* {newUnitQty < 0 && unitID == unit.id && ( */}
        <h3 className="unit-price">{unitQty}</h3>
        <h2 className="tooltipped">
          {unit.cleanName || unit.name}
          <span>{unit.cleanName || unit.name}</span>
        </h2>
      </div>
      {unit.images && (
        <img
          className="unit-pic"
          onClick={onModalToggle}
          src={unit.images.small ? unit.images.small : noImage}
          onError={({ currentTarget }) => {
            currentTarget.onerror = null; // prevents looping
            currentTarget.src = noImage;
          }}
          // onError={(e)=>{e.target.onerror = null; e.target.src="./../../img/noImage.png"}}
          // alt={unit.name}
        />
      )}
      {!unit.images && (
        <img
          className="unit-pic"
          onClick={onModalToggle}
          src={unit.imageLink ? unit.imageLink : noImage}
          onError={({ currentTarget }) => {
            currentTarget.onerror = null; // prevents looping
            currentTarget.src = noImage;
          }}
          // onError={(e)=>{e.target.onerror = null; e.target.src="./../../img/noImage.png"}}
          // alt={unit.name}
        />
      )}
      {/* <div className="unit-button-container">
        <Button
          classAppend={
            unit.collectionUnitQty > 0
              ? "collection-remove"
              : "collection-remove disable-remove"
          }
          idAppend={unit.id}
          variant="outlined"
          size="small"
          // handleClick={unit.collectionUnitQty > 0 ? handleCollectionRemove : ""}
          text={"-"}
          // text={<HiMinusSm />}
        />
        {unit.collectionUnitQty && (
          <h4 className="unitqty">{unit.collectionUnitQty}</h4>
        )}
        <Button
          classAppend="collection-add"
          idAppend={unit.id}
          variant="outlined"
          size="small"
          // handleClick={handleCollectionAdd}
          text={"+"}
          // text={<HiPlus />}
        />
      </div> */}
    </div>
  );
};

export default UnitMod;
