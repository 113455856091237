import React from "react";
import { Breadcrumbs, Typography } from "@material-ui/core";
import { Link } from "react-router-dom";

const CommonBreadcrumbs = (props) => {
  const { classAppend, separator, label, data } = props;
  const className = classAppend ? `cmn-bdc ${classAppend}` : "cmn-bdc";
  return (
    <Breadcrumbs
      className={className}
      separator={separator || "›"}
      aria-label={label || "breadcrumb"}
    >
      {data.map((d) => {
        return d.link ? (
          <Link color={d.color || "inherit"} to={d.link}>
            {d.name}
          </Link>
        ) : (
          <Typography color={d.color || "inherit"}>{d.name}</Typography>
        );
      })}
    </Breadcrumbs>
  );
};

export default CommonBreadcrumbs;
