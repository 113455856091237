import React, { useState, useEffect } from "react";
import {
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Button,
  Table,
  TableHead,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Paper,
  Breadcrumbs,
  Typography,
} from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import { useParams, Link } from "react-router-dom";
import {
  handleAddData,
  handleUpdateData,
  fetchEditUnitData,
  handleSaveData,
  handleDeleteData,
} from "./controller";
import { updUnitImage, updMagicUnitDetails } from "./api";
import * as _ from "lodash";
import config from "../../../config";

const EditMagicUnit = (props) => {
  const { handleSetAlert } = props;
  let { setID, unitID } = useParams();
  setID = parseInt(setID);
  unitID = parseInt(unitID);
  const [loading, setLoading] = useState(false);

  // Form Data
  const [unitTypeList, setUnitTypeList] = useState([]);
  const [setList, setSetList] = useState([]);
  const [rarityList, setRarityList] = useState([]);
  const [manaList, setManaList] = useState([]);
  const [supertypeLineList, setSupertypeLineList] = useState([]);
  const [typeLineList, setTypeLineList] = useState([]);
  const [subtypeLineList, setSubtypeLineList] = useState([]);
  const [keywordList, setKeywordList] = useState([]);

  // Form Values
  const [imageFile, setImageFile] = useState();
  const [imageLink, setImageLink] = useState();
  const [name, setName] = useState();
  const [type, setType] = useState();
  const [set, setSet] = useState(setID);
  const [rarity, setRarity] = useState("");
  const [power, setPower] = useState();
  const [toughness, setToughness] = useState();
  const [collectorNumber, setCollectorNumber] = useState();
  const [artist, setArtist] = useState();
  const [mana, setMana] = useState([]);
  const [manaIdentity, setManaIdentity] = useState([]);
  const [typeLine, setTypeLine] = useState([]);
  const [keywords, setKeywords] = useState([]);
  const [abilities, setAbilities] = useState([]);
  const [flavorText, setFlavorText] = useState([]);

  useEffect(() => {
    fetchEditUnitData(
      handleSetAlert,
      setLoading,
      setUnitTypeList,
      setSetList,
      setRarityList,
      setManaList,
      setSupertypeLineList,
      setTypeLineList,
      setSubtypeLineList,
      setKeywordList,
      unitID,
      setImageLink,
      setName,
      setType,
      setSet,
      setRarity,
      setPower,
      setToughness,
      setCollectorNumber,
      setArtist,
      setMana,
      setTypeLine,
      setKeywords,
      setAbilities,
      setFlavorText,
      setManaIdentity
    );
  }, [handleSetAlert, unitID]);

  const handleUpdateImage = async () => {
    try {
      if (imageFile) {
        const { imageLink: newImageLink } = await updUnitImage(
          unitID,
          imageFile
        );
        setImageLink(newImageLink);
        setImageFile();
        handleSetAlert("success", "Unit Image Updated!");
      }
    } catch (err) {
      if (err.response.data.message) {
        handleSetAlert("warning", err.response.data.message);
      } else {
        handleSetAlert("error", err.message);
      }
    }
  };

  const handleUpdateMetadata = async () => {
    try {
      const unitMetadata = {
        typeID: type,
        setID: set,
        rarityID: rarity,
        power,
        toughness,
        collectorNumber,
        artist,
      };
      await updMagicUnitDetails(unitID, unitMetadata);
      handleSetAlert("success", "Unit Metadata Updated!");
    } catch (err) {
      if (err.response.data.message) {
        handleSetAlert("warning", err.response.data.message);
      } else {
        handleSetAlert("error", err.message);
      }
    }
  };

  return (
    <div className="homeContainer adminContainer masterEditUnitContainer">
      <div className="adminLinkList">
        <Breadcrumbs separator="›" aria-label="breadcrumb">
          <Link color="inherit" to={"/admin"}>
            Admin
          </Link>
          <Link color="inherit" to={"/admin/mtg/sets"}>
            MTG
          </Link>
          <Link color="inherit" to={`/admin/mtg/sets/${set}/units`}>
            {setList.length > 0
              ? setList[_.findIndex(setList, { id: setID })].name + " Cards"
              : "Set"}
          </Link>
          <Typography color="textPrimary">{name}</Typography>
        </Breadcrumbs>
      </div>
      <h1 className="unitPageTitle">{name}</h1>
      {loading && <h3>Loading...</h3>}
      {!loading && (
        <div className="editUnitPageContainer">
          <div className="unitGridContainer">
            <div className="picLeftUnit">
              {/* <h3>Unit Image</h3> */}
              <img
                className="editUnitImg"
                src={imageLink ? `${config.mediaUrl}/${imageLink}` : ""}
                alt="Unit"
              />
              <br />
              <br />
              <h5>Update the Card Image Below</h5>
              <input
                type="file"
                name="Card Image Upload"
                onChange={(e) => setImageFile(e.target.files[0])}
              />
              <br />
              <br />
              <Button
                variant="outlined"
                size="large"
                style={{ color: "green", borderColor: "green" }}
                onClick={handleUpdateImage}
                // className="unitSubmitBtn"
              >
                Save Image Update
              </Button>
            </div>
            <div className="metaRightUnit">
              <h3>Card Metadata</h3>
              <TextField
                id="unit-name"
                label="Card Name"
                variant="outlined"
                value={name}
                onChange={(e) => setName(e.target.value)}
                className="unitInput"
              />
              <br />
              <br />
              <FormControl variant="outlined" className="unitInput">
                <InputLabel id="type-selector-label">Type</InputLabel>
                <Select
                  labelId="type-selector-label"
                  id="type-selector"
                  value={type}
                  onChange={(e) => setType(e.target.value)}
                  label="Type"
                >
                  {unitTypeList.map((tMap) => (
                    <MenuItem key={tMap.id} value={tMap.id}>
                      {tMap.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <br />
              <br />
              <FormControl variant="outlined" className="unitInput">
                <InputLabel id="set-selector-label">Set</InputLabel>
                <Select
                  labelId="set-selector-label"
                  id="set-selector"
                  value={set}
                  onChange={(e) => setSet(e.target.value)}
                  label="Set"
                >
                  {setList.map((sMap) => (
                    <MenuItem key={sMap.id} value={sMap.id}>
                      {sMap.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <br />
              <br />
              <FormControl variant="outlined" className="unitInput">
                <InputLabel id="rarity-selector-label">Rarity</InputLabel>
                <Select
                  labelId="rarity-selector-label"
                  id="rarity-selector"
                  value={rarity}
                  onChange={(e) => setRarity(e.target.value)}
                  label="Rarity"
                >
                  {rarityList.map((rMap) => (
                    <MenuItem key={rMap.id} value={rMap.id}>
                      {rMap.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <br />
              <br />
              <TextField
                className="unitInput"
                id="unit-power"
                label="Power"
                variant="outlined"
                value={power}
                onChange={(e) => setPower(e.target.value)}
              />
              <br />
              <br />
              <TextField
                className="unitInput"
                id="unit-toughness"
                label="Toughness"
                variant="outlined"
                value={toughness}
                onChange={(e) => setToughness(e.target.value)}
              />
              <br />
              <br />
              <TextField
                className="unitInput"
                id="unit-collector-number"
                label="Collector Number"
                variant="outlined"
                value={collectorNumber}
                onChange={(e) => setCollectorNumber(e.target.value)}
              />
              <br />
              <br />
              <TextField
                className="unitInput"
                id="unit-artist"
                label="Artist"
                variant="outlined"
                value={artist}
                onChange={(e) => setArtist(e.target.value)}
              />
              <br />
              <br />
              <Button
                variant="outlined"
                size="large"
                style={{ color: "green", borderColor: "green" }}
                onClick={handleUpdateMetadata}
                // className="unitSubmitBtn"
              >
                Save Metadata Update
              </Button>
            </div>
          </div>
          <br />
          <br />
          <br />
          <h3 className="unitPageHeader">Mana</h3>
          <Typography variant="subtitle1">{`Converted Mana Cost: ${_.sumBy(
            mana,
            "qty"
          )}`}</Typography>
          <br />
          <TableContainer
            className="editUnitTableContainer manaTable"
            component={Paper}
          >
            <Table aria-label="Unit Mana">
              <TableHead>
                <TableRow>
                  <TableCell align="center">Mana Type</TableCell>
                  {/* <TableCell align="center">Mana Type</TableCell> */}
                  <TableCell align="center">Quantity</TableCell>
                  <TableCell align="right"></TableCell>
                  <TableCell align="right"></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {mana.map((manaMap) => (
                  <TableRow key={manaMap.id}>
                    <TableCell>
                      {manaMap.colorName + ": " + manaMap.name}
                    </TableCell>
                    {/* <TableCell>{manaMap.name}</TableCell> */}
                    <TableCell>
                      <TextField
                        className="unitTableInput"
                        id={`mana-qty-${manaMap.id}`}
                        label="Quantity"
                        variant="outlined"
                        value={_.find(mana, { id: manaMap.id }).qty}
                        onChange={(e) =>
                          handleUpdateData(
                            manaMap.id,
                            "qty",
                            e.target.value,
                            mana,
                            setMana
                          )
                        }
                      />
                    </TableCell>
                    {/* <TableCell>{manaMap.sortPosition}</TableCell> */}
                    <TableCell>
                      {manaMap.wasUpdated && (
                        <Button
                          id={
                            manaMap.unitManaID
                              ? `btn-upd-manaCost-${manaMap.unitManaID}-${manaMap.id}`
                              : `btn-new-manaCost-${unitID}-${manaMap.id}`
                          }
                          variant="outlined"
                          size="medium"
                          style={{ color: "green", borderColor: "green" }}
                          onClick={(e) =>
                            handleSaveData(e, mana, setMana, handleSetAlert)
                          }
                        >
                          Save
                        </Button>
                      )}
                    </TableCell>
                    <TableCell>
                      <Button
                        className="unitTableBtn"
                        id={
                          manaMap.unitManaID
                            ? `btn-del-manaCost-${manaMap.unitManaID}-${manaMap.id}`
                            : `btn-rmv-manaCost-${unitID}-${manaMap.id}`
                        }
                        variant="outlined"
                        size="medium"
                        style={{ color: "red", borderColor: "red" }}
                        onClick={(e) =>
                          handleDeleteData(e, mana, setMana, handleSetAlert)
                        }
                      >
                        Delete
                      </Button>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          <br />
          <center>
            <Autocomplete
              id="unit-mana-picker"
              options={manaList}
              getOptionLabel={(option) => `${option.colorName}: ${option.name}`}
              style={{ width: 300 }}
              onChange={(event, newValue) => {
                handleAddData(newValue, mana, setMana, "manaCost");
              }}
              renderInput={(params) => (
                <TextField {...params} label="Mana" variant="outlined" />
              )}
            />
          </center>
          <br />
          <br />
          <br />
          <h3 className="unitPageHeader">Mana Identity</h3>
          <br />
          <TableContainer
            className="editUnitTableContainer manaIdentityTable"
            component={Paper}
          >
            <Table aria-label="Unit Mana Identity">
              <TableHead>
                <TableRow>
                  <TableCell align="center">Mana Type</TableCell>
                  {/* <TableCell align="center">Mana Type</TableCell> */}
                  <TableCell align="center">Quantity</TableCell>
                  <TableCell align="right"></TableCell>
                  <TableCell align="right"></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {manaIdentity.map((manaIdentityMap) => (
                  <TableRow key={manaIdentityMap.id}>
                    <TableCell>
                      {manaIdentityMap.colorName + ": " + manaIdentityMap.name}
                    </TableCell>
                    {/* <TableCell>{manaIdentityMap.name}</TableCell> */}
                    <TableCell>
                      <TextField
                        className="unitTableInput"
                        id={`mana-identity-qty-${manaIdentityMap.id}`}
                        label="Quantity"
                        variant="outlined"
                        value={_.find(mana, { id: manaIdentityMap.id }).qty}
                        onChange={(e) =>
                          handleUpdateData(
                            manaIdentityMap.id,
                            "qty",
                            e.target.value,
                            manaIdentity,
                            setManaIdentity
                          )
                        }
                      />
                    </TableCell>
                    {/* <TableCell>{manaIdentityMap.sortPosition}</TableCell> */}
                    <TableCell>
                      {manaIdentityMap.wasUpdated && (
                        <Button
                          id={
                            manaIdentityMap.unitManaID
                              ? `btn-upd-manaIdentity-${manaIdentityMap.unitManaID}-${manaIdentityMap.id}`
                              : `btn-new-manaIdentity-${unitID}-${manaIdentityMap.id}`
                          }
                          variant="outlined"
                          size="medium"
                          style={{ color: "green", borderColor: "green" }}
                          onClick={(e) =>
                            handleSaveData(
                              e,
                              manaIdentity,
                              setManaIdentity,
                              handleSetAlert
                            )
                          }
                        >
                          Save
                        </Button>
                      )}
                    </TableCell>
                    <TableCell>
                      <Button
                        className="unitTableBtn"
                        id={
                          manaIdentityMap.unitManaID
                            ? `btn-del-manaIdentity-${manaIdentityMap.unitManaID}-${manaIdentityMap.id}`
                            : `btn-rmv-manaIdentity-${unitID}-${manaIdentityMap.id}`
                        }
                        variant="outlined"
                        size="medium"
                        style={{ color: "red", borderColor: "red" }}
                        onClick={(e) =>
                          handleDeleteData(
                            e,
                            manaIdentity,
                            setManaIdentity,
                            handleSetAlert
                          )
                        }
                      >
                        Delete
                      </Button>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          <br />
          <center>
            <Autocomplete
              id="unit-mana-picker"
              options={manaList}
              getOptionLabel={(option) => `${option.colorName}: ${option.name}`}
              style={{ width: 300 }}
              onChange={(event, newValue) => {
                handleAddData(
                  newValue,
                  manaIdentity,
                  setManaIdentity,
                  "manaCost"
                );
              }}
              renderInput={(params) => (
                <TextField {...params} label="Mana" variant="outlined" />
              )}
            />
          </center>
          <br />
          <br />
          <br />
          <h3 className="unitPageHeader">Type Line</h3>
          <TableContainer component={Paper}>
            <Table aria-label="Unit Type Line">
              <TableBody>
                {typeLine.map((tlMap) => (
                  <TableRow key={tlMap.id}>
                    <TableCell component="th" scope="row">
                      {tlMap.typeLineTypeName}
                    </TableCell>
                    <TableCell>{tlMap.name}</TableCell>
                    {/* <TableCell>{tlMap.sortPosition}</TableCell> */}
                    <TableCell>
                      {tlMap.wasUpdated && (
                        <Button
                          id={
                            tlMap.unitTypeLineID
                              ? `btn-upd-typeLines-${tlMap.unitTypeLineID}-${tlMap.id}`
                              : `btn-new-typeLines-${unitID}-${tlMap.id}`
                          }
                          variant="outlined"
                          size="medium"
                          style={{ color: "green", borderColor: "green" }}
                          onClick={(e) =>
                            handleSaveData(
                              e,
                              typeLine,
                              setTypeLine,
                              handleSetAlert
                            )
                          }
                        >
                          Save
                        </Button>
                      )}
                    </TableCell>
                    <TableCell>
                      <Button
                        id={
                          tlMap.unitTypeLineID
                            ? `btn-del-typeLines-${tlMap.unitTypeLineID}-${tlMap.id}`
                            : `btn-rmv-typeLines-${unitID}-${tlMap.id}`
                        }
                        variant="outlined"
                        size="medium"
                        style={{ color: "red", borderColor: "red" }}
                        onClick={(e) =>
                          handleDeleteData(
                            e,
                            typeLine,
                            setTypeLine,
                            handleSetAlert
                          )
                        }
                      >
                        Delete
                      </Button>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          <br />
          <center>
            <Autocomplete
              id="unit-supertype-picker"
              options={supertypeLineList}
              getOptionLabel={(option) => `${option.name}`}
              style={{ width: 300 }}
              onChange={(event, newValue) => {
                handleAddData(
                  { ...newValue, isNew: true },
                  typeLine,
                  setTypeLine,
                  "typeLine"
                );
              }}
              renderInput={(params) => (
                <TextField {...params} label="Supertype" variant="outlined" />
              )}
            />
          </center>
          <br />
          <center>
            <Autocomplete
              id="unit-type-picker"
              options={typeLineList}
              getOptionLabel={(option) => `${option.name}`}
              style={{ width: 300 }}
              onChange={(event, newValue) => {
                handleAddData(
                  { ...newValue, isNew: true },
                  typeLine,
                  setTypeLine,
                  "typeLine"
                );
              }}
              renderInput={(params) => (
                <TextField {...params} label="Type" variant="outlined" />
              )}
            />
          </center>
          <br />
          <center>
            <Autocomplete
              id="unit-subtype-picker"
              options={subtypeLineList}
              getOptionLabel={(option) => `${option.name}`}
              style={{ width: 300 }}
              onChange={(event, newValue) => {
                handleAddData(
                  { ...newValue, isNew: true },
                  typeLine,
                  setTypeLine,
                  "typeLine"
                );
              }}
              renderInput={(params) => (
                <TextField {...params} label="Subtype" variant="outlined" />
              )}
            />
          </center>
          <br />
          <br />
          <br />
          <h3 className="unitPageHeader">Keywords</h3>
          <TableContainer className="editUnitTableContainer" component={Paper}>
            <Table aria-label="Unit Keywords">
              <TableBody>
                {keywords.map((keywordMap) => (
                  <TableRow key={keywordMap.id}>
                    <TableCell>{keywordMap.name}</TableCell>
                    <TableCell>
                      <TextField
                        id={`keywords-detail-${keywordMap.id}`}
                        label="Details"
                        variant="outlined"
                        className="textboxUnit"
                        multiline
                        value={_.find(keywords, { id: keywordMap.id }).details}
                        onChange={(e) =>
                          handleUpdateData(
                            keywordMap.id,
                            "details",
                            e.target.value,
                            keywords,
                            setKeywords
                          )
                        }
                      />
                    </TableCell>
                    {/* <TableCell>{keywordMap.sortPosition}</TableCell> */}
                    <TableCell>
                      {keywordMap.wasUpdated && (
                        <Button
                          id={
                            keywordMap.unitKeywordID
                              ? `btn-upd-keywords-${keywordMap.unitKeywordID}-${keywordMap.id}`
                              : `btn-new-keywords-${unitID}-${keywordMap.id}`
                          }
                          variant="outlined"
                          size="medium"
                          style={{ color: "green", borderColor: "green" }}
                          onClick={(e) =>
                            handleSaveData(
                              e,
                              keywords,
                              setKeywords,
                              handleSetAlert
                            )
                          }
                        >
                          Save
                        </Button>
                      )}
                    </TableCell>
                    <TableCell>
                      <Button
                        id={
                          keywordMap.unitKeywordID
                            ? `btn-del-keywords-${keywordMap.unitKeywordID}-${keywordMap.id}`
                            : `btn-rmv-keywords-${unitID}-${keywordMap.id}`
                        }
                        variant="outlined"
                        size="medium"
                        style={{ color: "red", borderColor: "red" }}
                        onClick={(e) =>
                          handleDeleteData(
                            e,
                            keywords,
                            setKeywords,
                            handleSetAlert
                          )
                        }
                      >
                        Delete
                      </Button>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          <br />
          <center>
            <Autocomplete
              id="unit-keyword-picker"
              options={keywordList}
              getOptionLabel={(option) => `${option.name}`}
              style={{ width: 300 }}
              onChange={(event, newValue) => {
                handleAddData(
                  { ...newValue, isNew: true },
                  keywords,
                  setKeywords,
                  "keywords"
                );
              }}
              renderInput={(params) => (
                <TextField {...params} label="Keyword" variant="outlined" />
              )}
            />
          </center>
          <br />
          <br />
          <br />
          <h3 className="unitPageHeader">Abilities</h3>
          <TableContainer className="editUnitTableContainer" component={Paper}>
            <Table aria-label="Unit Abilities">
              <TableBody>
                {abilities.map((abilitiesMap) => (
                  <TableRow key={abilitiesMap.id}>
                    <TableCell>
                      <TextField
                        id={`abilities-text-${abilitiesMap.id}`}
                        label="Text"
                        variant="outlined"
                        className="textboxUnit"
                        value={_.find(abilities, { id: abilitiesMap.id }).text}
                        onChange={(e) =>
                          handleUpdateData(
                            abilitiesMap.id,
                            "text",
                            e.target.value,
                            abilities,
                            setAbilities
                          )
                        }
                      />
                    </TableCell>
                    {/* <TableCell>{abilitiesMap.sortPosition}</TableCell> */}
                    <TableCell>
                      {abilitiesMap.wasUpdated && (
                        <Button
                          id={
                            !abilitiesMap.isNew
                              ? `btn-upd-abilities-${abilitiesMap.id}-${abilitiesMap.id}`
                              : `btn-new-abilities-${unitID}-${abilitiesMap.id}`
                          }
                          variant="outlined"
                          size="medium"
                          style={{ color: "green", borderColor: "green" }}
                          onClick={(e) =>
                            handleSaveData(
                              e,
                              abilities,
                              setAbilities,
                              handleSetAlert
                            )
                          }
                        >
                          Save
                        </Button>
                      )}
                    </TableCell>
                    <TableCell>
                      <Button
                        id={
                          !abilitiesMap.isNew
                            ? `btn-del-abilities-${abilitiesMap.id}-${abilitiesMap.id}`
                            : `btn-rmv-abilities-${unitID}-${abilitiesMap.id}`
                        }
                        variant="outlined"
                        size="medium"
                        style={{ color: "red", borderColor: "red" }}
                        onClick={(e) =>
                          handleDeleteData(
                            e,
                            abilities,
                            setAbilities,
                            handleSetAlert
                          )
                        }
                      >
                        Delete
                      </Button>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          <br />
          <Button
            variant="outlined"
            size="large"
            color="primary"
            onClick={() => {
              handleAddData(
                { isNew: true },
                abilities,
                setAbilities,
                "abilities"
              );
            }}
          >
            Add Ability
          </Button>
          <br />
          <br />
          <br />
          <h3 className="unitPageHeader">Flavor Text</h3>
          <TableContainer className="editUnitTableContainer" component={Paper}>
            <Table aria-label="Unit Abilities">
              <TableBody>
                {flavorText.map((flavorTextMap) => (
                  <TableRow key={flavorTextMap.id}>
                    <TableCell>
                      <TextField
                        id={`flavorText-text-${flavorTextMap.id}`}
                        label="Text"
                        variant="outlined"
                        className="textboxUnit"
                        multiline
                        value={
                          _.find(flavorText, { id: flavorTextMap.id }).text
                        }
                        onChange={(e) =>
                          handleUpdateData(
                            flavorTextMap.id,
                            "text",
                            e.target.value,
                            flavorText,
                            setFlavorText
                          )
                        }
                      />
                    </TableCell>
                    {/* <TableCell>{flavorTextMap.sortPosition}</TableCell> */}
                    <TableCell>
                      {flavorTextMap.wasUpdated && (
                        <Button
                          id={
                            !flavorTextMap.isNew
                              ? `btn-upd-flavorText-${flavorTextMap.id}-${flavorTextMap.id}`
                              : `btn-new-flavorText-${unitID}-${flavorTextMap.id}`
                          }
                          variant="outlined"
                          size="medium"
                          style={{ color: "green", borderColor: "green" }}
                          onClick={(e) =>
                            handleSaveData(
                              e,
                              flavorText,
                              setFlavorText,
                              handleSetAlert
                            )
                          }
                        >
                          Save
                        </Button>
                      )}
                    </TableCell>
                    <TableCell>
                      <Button
                        id={
                          !flavorTextMap.isNew
                            ? `btn-del-flavorText-${flavorTextMap.id}-${flavorTextMap.id}`
                            : `btn-rmv-flavorText-${unitID}-${flavorTextMap.id}`
                        }
                        variant="outlined"
                        size="medium"
                        style={{ color: "red", borderColor: "red" }}
                        onClick={(e) =>
                          handleDeleteData(
                            e,
                            flavorText,
                            setFlavorText,
                            handleSetAlert
                          )
                        }
                      >
                        Delete
                      </Button>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          <br />
          <Button
            variant="outlined"
            size="large"
            color="primary"
            onClick={() => {
              handleAddData(
                { isNew: true },
                flavorText,
                setFlavorText,
                "flavorText"
              );
            }}
          >
            Add Flavor Text
          </Button>
        </div>
      )}
    </div>
  );
};

export default EditMagicUnit;
