import React, { useState } from "react";
import { Redirect, useLocation } from "react-router-dom";
import {
  Grid,
  Typography,
  TextField,
  Button,
  Snackbar,
} from "@material-ui/core";
import MuiAlert from "@material-ui/lab/Alert";
import http from "../../services/http";
import config from "../../config";
import { authCheck } from "../../services/auth";
import { IoIosArrowBack } from "react-icons/io";
import qs from "querystring";
// import bgimg from "./../../img/dc_login.png"

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const Register = (props) => {
  const { handleSetUser } = props;
  const { search } = useLocation();
  const queryParams = qs.decode(search.slice(1));
  const [token, setToken] = useState(queryParams.token);
  const [email, setEmail] = useState(queryParams.email);
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [showAlert, setShowAlert] = useState(false);
  const [alertSeverity, setAlertSeverity] = useState("");
  const [alert, setAlert] = useState("");

  if (authCheck()) {
    return <Redirect to="/" />;
  }

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setShowAlert(false);
  };

  const handleKeypress = (e) => {
    //it triggers by pressing the enter key
    if (e.key === "Enter") {
      handleRegisterClick();
    }
  };

  const handleRegisterClick = async () => {
    try {
      const reqBody = {
        token,
        email,
        username,
        password,
      };
      const reqUrl = `${config.apiUrl}/users`;
      const res = await http.post(reqUrl, reqBody);
      const authToken = res.headers[config.authHeader];
      handleSetUser(authToken);
      return <Redirect to="/" />;
    } catch (err) {
      setAlertSeverity("error");
      setAlert(err.response.data.message);
      setShowAlert(true);
    }
  };

  return (
    <React.Fragment>
      {/* <img className="loginBG" src={bgimg} alt="" /> */}
      <div className="register-container active-register">
        <Grid
          container
          direction="column"
          justify="flex-start"
          alignItems="center"
          spacing={5}
          className={props.regClasses}
        >
          <Grid
            container
            item
            direction="row"
            justify="flex-start"
            alignItems="center"
          >
            <Grid item xs={12}>
              <Typography variant="h2">Register</Typography>
            </Grid>
          </Grid>
          <Grid
            container
            item
            direction="row"
            justify="space-evenly"
            alignItems="center"
          >
            <TextField
              id="Invite-token"
              label="Invitation Token"
              variant="outlined"
              // TODO: Alpha
              // onChange={(e) => setEmail(e.target.value)}
              value={queryParams.token}
              disabled
            />
          </Grid>
          <Grid
            container
            item
            direction="row"
            justify="space-evenly"
            alignItems="center"
          >
            <TextField
              id="Register-email"
              label="Email"
              variant="outlined"
              // TODO: Alpha
              // onChange={(e) => setEmail(e.target.value)}
              value={queryParams.email}
              disabled
            />
          </Grid>
          <Grid
            container
            item
            direction="row"
            justify="space-evenly"
            alignItems="center"
          >
            <TextField
              id="Register-username"
              label="Username"
              variant="outlined"
              onChange={(e) => setUsername(e.target.value)}
            />
          </Grid>
          <Grid
            container
            item
            direction="row"
            justify="space-evenly"
            alignItems="center"
          >
            <TextField
              id="Register-password"
              label="Password"
              variant="outlined"
              type="password"
              onKeyPress={(e) => handleKeypress(e)}
              onChange={(e) => setPassword(e.target.value)}
            />
          </Grid>
          <Grid
            container
            item
            direction="row"
            justify="space-evenly"
            alignItems="center"
          >
            <Button
              variant="outlined"
              size="large"
              color="primary"
              onClick={handleRegisterClick}
            >
              Register
            </Button>
          </Grid>
          <span>- &nbsp; or &nbsp; -</span>
          <Grid
            container
            item
            direction="row"
            justify="space-evenly"
            alignItems="center"
          >
            <Button
              variant="outlined"
              size="large"
              color="primary"
              onClick={() => {
                window.location = "/login";
              }}
            >
              <IoIosArrowBack />
              &nbsp; Login
            </Button>
          </Grid>
        </Grid>
      </div>
      <Snackbar open={showAlert} autoHideDuration={6000} onClose={handleClose}>
        <Alert onClose={handleClose} severity={alertSeverity}>
          {alert}
        </Alert>
      </Snackbar>
    </React.Fragment>
  );
};

export default Register;
