import React, { useState, useEffect } from "react";
import * as _ from "lodash";
import { fetchMyCollectionUnits } from "./controller";
import DotLoader from "../shared/common/loader-dot";
import UnitMod from "../shared/unit-module";
import CollectionHeader from "./components/header";
import CustModal from "../shared/common/modal";
import DeckBuilder from "../mtg/deck-builder";
import BulkAdd from "./bulk-add";

const CollectionView = (props) => {
  const { handleSetAlert, user } = props;

  const [loading, setLoading] = useState(false);
  const [nextLoading, setNextLoading] = useState(false);
  const [collection, setCollection] = useState([]);
  const [toggleModal, setToggleModal] = useState("hide");
  const [currentUnit, setCurrentUnit] = useState();
  const [nextPage, setNextPage] = useState(1);

  const [filtersObj, setFiltersObj] = useState([]);
  const [filtersChangeFlag, setFiltersChangeFlag] = useState();
  const [gameFilter, setGameFilter] = useState();
  const [sortBy, setSortBy] = useState();
  const [showBulkAdd, setShowBulkAdd] = useState(false);

  const [view, setView] = useState("grid");
  const [activeTab, setActiveTab] = useState("Collection");

  useEffect(() => {
    setLoading(true);

    fetchMyCollectionUnits(
      setLoading,
      nextPage,
      setNextPage,
      gameFilter,
      sortBy,
      collection,
      setCollection,
      handleSetAlert
    );
  }, [gameFilter, sortBy]);
  // }, [filtersObj, filtersChangeFlag, activeTab, sortBy, sortByChange]);

  const handleLoadMoreUnits = () => {
    fetchMyCollectionUnits(
      setNextLoading,
      nextPage,
      setNextPage,
      gameFilter,
      collection,
      setCollection,
      handleSetAlert
    );
  };

  const handleUpdateHasUnit = (unitID, unitQty) => {
    const updUnits = [...collection];
    const unitIndex = _.findIndex(updUnits, { id: parseInt(unitID) });
    updUnits[unitIndex].collectionUnitQty = unitQty;
    setCollection(updUnits);
  };

  const openModal = (unit) => {
    setCurrentUnit(unit);
    console.log(unit.name + " clicked");
    setToggleModal("show");
  };
  const closeModal = () => {
    console.log("closing modal");
    setToggleModal("hide");
  };

  const handleView = (currentView) => {
    setView(currentView);
  };

  return (
    <div>
      <CustModal
        unit={currentUnit}
        modalRef={toggleModal}
        hideModal={closeModal}
        handleSetAlert={handleSetAlert}
        collection={collection}
        handleUpdateHasUnit={handleUpdateHasUnit}
      />
      <BulkAdd
        showBulkAdd={showBulkAdd}
        setShowBulkAdd={setShowBulkAdd}
        setLoading={setLoading}
        collection={collection}
        setCollection={setCollection}
      />
      {/* <TabHead setActiveTab={setActiveTab} activeTab={activeTab} /> */}
      <CollectionHeader
        headerClasses="collection-page-header"
        game="Collection"
        setFiltersObj={setFiltersObj}
        gameFilter={gameFilter}
        setGameFilter={setGameFilter}
        filtersChangeFlag={filtersChangeFlag}
        setFiltersChangeFlag={setFiltersChangeFlag}
        onHandleView={handleView}
        activeTab={activeTab}
        setActiveTab={setActiveTab}
        view={view}
        setView={setView}
        sortBy={sortBy}
        setSortBy={setSortBy}
        setShowBulkAdd={setShowBulkAdd}
      />
      {/* {view == "table" &&
        <DenseTable className="mtgTable" units={collection}/>
      } */}
      {activeTab == "Collection" && (
        <>
          {view == "grid" && (
            // {!loading && (
            <>
              <div className="tabContainer"></div>
              {/* {collectionView.length === 0 && (
              <h1><br/><br/>Add units to your collection!</h1>
            )} */}
              {collection.length > 0 && (
                <React.Fragment>
                  <div className="collection-wrapper">
                    <div className="collection-container collectionUnitDiv">
                      {collection.map((unit) => {
                        return (
                          <div key={unit.id} className="unitContainer">
                            <UnitMod
                              unit={unit}
                              currentUnit={currentUnit}
                              units={collection}
                              setUnits={setCollection}
                              id={unit.id}
                              name={unit.name}
                              handleUpdateHasUnit={handleUpdateHasUnit}
                              onModalToggle={() => openModal(unit)}
                            />
                          </div>
                        );
                      })}
                    </div>
                  </div>
                  {/* <CircleLoader loading={nextLoading} /> */}
                  {/* {nextPage && (
                  <Button
                    variant="contained"
                    classAppend={"loadUnitsBtn"}
                    text="Load More..."
                    handleClick={handleLoadMoreUnits}
                  />
                )} */}
                </React.Fragment>
              )}
            </>
          )}
        </>
      )}
      {activeTab == "Deck Builder" && (
        <>
          <DeckBuilder />
        </>
      )}
      <DotLoader margin={"5"} loading={loading} />
    </div>
  );
};

export default CollectionView;
