import config from "../config";
import _ from "lodash";
import jwtDecode from "jwt-decode";

export const authCheck = () => {
  const auth = localStorage.getItem(config.authHeader);
  return auth ? true : false;
};

export const premiumCheck = () => {
  if (!localStorage.getItem(config.authHeader)) {
    return false;
  }
  const { groupIDs: userGroupIDs } = jwtDecode(
    localStorage.getItem(config.authHeader)
  );
  if (userGroupIDs === []) {
    return false;
  }
  const premiumGroupIDs = [1, 2, 3, 4];
  const inGroup = _.intersection(premiumGroupIDs, userGroupIDs);
  if (inGroup.length > 0) {
    return true;
  } else {
    return false;
  }
};

export const modCheck = () => {
  if (!localStorage.getItem(config.authHeader)) {
    return false;
  }
  const { groupIDs: userGroupIDs } = jwtDecode(
    localStorage.getItem(config.authHeader)
  );
  if (userGroupIDs === []) {
    return false;
  }
  const modGroupIDs = [1, 2, 3];
  const inGroup = _.intersection(modGroupIDs, userGroupIDs);
  if (inGroup.length > 0) {
    return true;
  } else {
    return false;
  }
};

export const adminCheck = () => {
  if (!localStorage.getItem(config.authHeader)) {
    return false;
  }
  const { groupIDs: userGroupIDs } = jwtDecode(
    localStorage.getItem(config.authHeader)
  );
  if (userGroupIDs === []) {
    return false;
  }
  const adminGroupIDs = [1, 2];
  const inGroup = _.intersection(adminGroupIDs, userGroupIDs);
  if (inGroup.length > 0) {
    return true;
  } else {
    return false;
  }
};
