import {
  getMyCollectionGames,
  getMyCollectionUnits,
  getMyCollectionGameSets,
  getMyCollectionGameSetUnits,
  getCollection,
} from "./api";
import { getUnits } from "../shared/api";

export const fetchMyCollectionGames = async (
  setLoading,
  setData,
  handleSetAlert
) => {
  try {
    setLoading(true);
    const games = await getMyCollectionGames();
    setData(games);
    setLoading(false);
  } catch (err) {
    setLoading(false);
    if (err.response) {
      handleSetAlert("warning", err.response.data.message);
    } else {
      handleSetAlert("error", err.message);
    }
  }
};

export const fetchMyCollectionUnits = async (
  setLoading,
  page,
  setNextPage,
  gameFilter,
  sortBy,
  currentData,
  setData,
  handleSetAlert
) => {
  try {
    console.log("grabbing collection");
    setLoading(true);
    const { data, nextPage } = await getCollection(page, gameFilter);
    // SORT OPTIONS
    const AZ = "ProductName ASC";
    const priceHigh = "MinPrice DESC";
    const priceLow = "MinPrice ASC";
    const qty = "Qty";
    //
    console.log(sortBy);
    if (sortBy == AZ) {
      console.log("sort a-z");
      const sorteddata = []
        .concat(data)
        .sort((a, b) => (a.name.toUpperCase() > b.name.toUpperCase() ? 1 : -1));
      setData([...sorteddata]);
    } else if (sortBy == priceHigh) {
      console.log("sort high - low");
      const sorteddata = []
        .concat(data)
        .sort((a, b) => (a.name.toUpperCase() > b.name.toUpperCase() ? 1 : -1));
      setData([...sorteddata]);
    } else if (sortBy == priceLow) {
      console.log("sort low - high");
      const sorteddata = []
        .concat(data)
        .sort((a, b) => (a.name.toUpperCase() > b.name.toUpperCase() ? 1 : -1));
      setData([...sorteddata]);
    } else if (sortBy == qty) {
      console.log("sort by quantity");
      const sorteddata = []
        .concat(data)
        .sort((a, b) => (a.collectionUnitQty < b.collectionUnitQty ? 1 : -1));
      setData([...sorteddata]);
    } else {
      console.log("sort not found");
    }
    setNextPage(nextPage);
    setLoading(false);
  } catch (err) {
    setLoading(false);
    if (err.response) {
      handleSetAlert("warning", err.response.data.message);
    } else {
      handleSetAlert("error", err.message);
    }
  }
};

export const fetchMyCollectionGameSets = async (
  setLoading,
  page,
  setNextPage,
  gameID,
  currentData,
  setData,
  handleSetAlert
) => {
  try {
    setLoading(true);
    const { data, nextPage } = await getMyCollectionGameSets(gameID, page);
    setData([...currentData, ...data]);
    setNextPage(nextPage);
    setLoading(false);
  } catch (err) {
    setLoading(false);
    if (err.response) {
      handleSetAlert("warning", err.response.data.message);
    } else {
      handleSetAlert("error", err.message);
    }
  }
};

export const fetchMyCollectionGameSetUnits = async (
  setLoading,
  page,
  setNextPage,
  gameID,
  setID,
  currentData,
  setData,
  handleSetAlert
) => {
  try {
    setLoading(true);
    const { data, nextPage } = await getMyCollectionGameSetUnits(
      gameID,
      setID,
      page
    );
    setData([...currentData, ...data]);
    setNextPage(nextPage);
    setLoading(false);
  } catch (err) {
    setLoading(false);
    if (err.response) {
      handleSetAlert("warning", err.response.data.message);
    } else {
      handleSetAlert("error", err.message);
    }
  }
};
