import http from "../../services/http";
import config from "../../config";
import * as _ from "lodash";
import * as qs from "querystring";

export const getMagicSets = (page = 1) => {
  return new Promise(async (resolve, reject) => {
    try {
      const qsData = {
        page,
      };
      const qString = qs.encode(qsData);
      const setsUrl = `${config.apiUrl}/mtg/sets?${qString}`;
      const { data, headers } = await http.get(setsUrl);
      const nullDates = data.filter((date) => !date.releaseDate);
      const trueDates = data.filter((date) => date.releaseDate);
      const dataSorted = _.orderBy(trueDates, ["releaseDate"], ["desc"]);
      const outData = {
        data: [...dataSorted, ...nullDates],
        nextPage: headers["next-page"] === "null" ? null : headers["next-page"],
      };
      resolve(outData);
    } catch (err) {
      reject(err);
    }
  });
};

export const getMagicSetUnits = (setID, page = 1) => {
  return new Promise(async (resolve, reject) => {
    try {
      const qsData = {
        page,
      };
      const qString = qs.encode(qsData);
      const setUnitsUrl = `${config.apiUrl}/mtg/sets/${setID}/units?${qString}`;
      const { data, headers } = await http.get(setUnitsUrl);
      resolve({
        data,
        nextPage: headers["next-page"] === "null" ? null : headers["next-page"],
      });
    } catch (err) {
      reject(err);
    }
  });
};

export const getMagicUnitByID = (unitID) => {
  return new Promise(async (resolve, reject) => {
    try {
      const getUnitUrl = `${config.apiUrl}/mtg/units/${unitID}`;
      const { data } = await http.get(getUnitUrl);
      resolve(data);
    } catch (err) {
      reject(err);
    }
  });
};

export const getMagicUnits = (page) => {
  return new Promise(async (resolve, reject) => {
    try {
      const qsData = {
        game: "MTG",
        page,
      };
      const qString = qs.encode(qsData);
      const unitsUrl = `${config.apiUrl}/units?${qString}`;
      const { data, headers } = await http.get(unitsUrl);
      resolve({
        data,
        nextPage: headers["next-page"] === "null" ? null : headers["next-page"],
      });
    } catch (err) {
      reject(err);
    }
  });
};

export const getMagicSetDetails = (setID) => {
  return new Promise(async (resolve, reject) => {
    try {
      const getSetDetailsUrl = `${config.apiUrl}/mtg/sets/${setID}`;
      const { data } = await http.get(getSetDetailsUrl);
      resolve(data);
    } catch (err) {
      reject(err);
    }
  });
};
