import React from "react";

const NotFound = () => {
  return (
    <div className="homeContainer">
      <h1>Whoops!</h1>
      <p>This url could not be found. Please try again!</p>
    </div>
  );
};

export default NotFound;
