export const mapMana = (manaArray) => {
  return manaArray.map((m) => {
    const {
      id: unitManaID,
      manaColorID: id,
      name,
      colorName,
      qty,
      sortPosition,
    } = m;
    return {
      unitManaID,
      id,
      name,
      colorName,
      qty,
      sortPosition,
    };
  });
};

export const mapTypeLine = (typeLineArray) => {
  return typeLineArray.map((tl) => {
    const { id: unitTypeLineID, typeLineID: id, type, name, sortPosition } = tl;
    return {
      unitTypeLineID,
      id,
      name,
      typeLineTypeName: type.name,
      sortPosition,
    };
  });
};

export const mapKeywords = (keywordsArray) => {
  return keywordsArray.map((k) => {
    const { id: unitKeywordID, keywordID: id, name, details, sortPosition } = k;
    return {
      unitKeywordID,
      id,
      name,
      details,
      sortPosition,
    };
  });
};
