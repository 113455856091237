import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import {
  Drawer,
  Divider,
  List,
  ListItem,
  ListItemText,
  IconButton,
} from "@material-ui/core";
import { authCheck, modCheck } from "../../services/auth";
import { ChevronLeft } from "@material-ui/icons";
import AccountCircleIcon from "@material-ui/icons/AccountCircle";
import HomeIcon from "@material-ui/icons/Home";
import SettingsIcon from "@material-ui/icons/Settings";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import VerifiedUserIcon from "@material-ui/icons/VerifiedUser";
import {
  FiLogOut,
  FiSettings,
  FiHome,
  FiUser,
  FiTool,
  FiLogIn,
} from "react-icons/fi";
import { BiCollection } from "react-icons/bi";
import mtglogo from "../../img/magicLogo.png";
import pokelogo from "../../img/pokeLogo.png";
import bgLogo from "../../img/boardgame_logo.png";

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth,
  },
  drawerHeader: {
    display: "flex",
    alignItems: "center",
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: "flex-end",
  },
}));

const MenuDrawer = (props) => {
  const { user, menuOpen, handleMenuClose } = props;
  const isAuth = authCheck();
  const isMod = modCheck(user.groupIDs);
  const classes = useStyles();
  const [activePage, setActivePage] = useState("");

  useEffect(() => {
    const pathname = window.location.pathname;
    // const nameKey = toString(pathname).replace("/", "");

    setActivePage(pathname);
  }, []);

  const handleMenuClick = ({ currentTarget }) => {
    handleMenuClose();
    const route = `/${currentTarget.getAttribute("name")}`;
    window.location = route;
    setActivePage(currentTarget.getAttribute("name"));
  };

  return (
    <Drawer
      className={classes.drawer + " drawerContainer desktopNav"}
      variant="persistent"
      anchor="left"
      open={menuOpen}
      classes={{
        paper: classes.drawerPaper,
      }}
    >
      {/* <div className={classes.drawerHeader + " drawerHead"}> */}
      <IconButton className="menuCloseBtn" onClick={handleMenuClose}>
        <ChevronLeft />
      </IconButton>
      {/* </div> */}
      {/* <List>
        <ListItem
          className="sectionLabel"
          button
          name="category"
          key="category"
          disabled
          // onClick={handleMenuClick}
        >
          <ListItemText
            className=""
            primary="YOUR DISPLAYCHASE"
          />
        </ListItem>
        <ListItem
          className={activePage === "/" ? "activePage" : ""}
          button
          name=""
          key="home"
          onClick={handleMenuClick}
        >
          <FiHome className="drawerIcon" />
          <ListItemText className="" primary="Home" />
        </ListItem>
        {!isAuth && (
          <React.Fragment>
            <List>
              <ListItem
                className={activePage === "/login" ? "activePage" : ""}
                button
                name="login"
                key="login"
                onClick={handleMenuClick}
              >
                <FiLogIn className="drawerIcon" />
                <ListItemText primary="Login" />
              </ListItem>
            </List>
          </React.Fragment>
        )}
        {isAuth && (
          <>
            <ListItem
              className={activePage === "/collection" ? "activePage" : ""}
              button
              name="collection"
              key="collection"
              onClick={handleMenuClick}
            >
              <BiCollection className="drawerIcon" />
              <ListItemText primary="Collection" />
            </ListItem>
            <ListItem
              className={activePage === "/nft" ? "activePage" : ""}
              button
              name="nft"
              key="nft"
              onClick={handleMenuClick}
            >
              <BiCollection className="drawerIcon" />
              <ListItemText primary="NFTs" />
            </ListItem>
          </>
        )}
      </List> */}
      <List>
        <ListItem
          className="sectionLabel categoryLabel"
          button
          name="category"
          key="category"
          disabled
          // onClick={handleMenuClick}
        >
          <ListItemText className="" primary="BROWSE" />
        </ListItem>
        <ListItem
          className={activePage === "/mtg" ? "activePage" : ""}
          button
          name="mtg"
          key="mtg"
          onClick={handleMenuClick}
        >
          <img src={mtglogo} />
          <ListItemText
            className=""
            primary="&nbsp;&nbsp;Magic: The Gathering"
          />
        </ListItem>
        <ListItem
          className={activePage === "/pokemon" ? "activePage" : ""}
          button
          name="pokemon"
          key="pokemon"
          onClick={handleMenuClick}
        >
          <img className="pokeLogo" src={pokelogo} />
          <ListItemText className="" primary="&nbsp;&nbsp;Pokemon TCG" />
        </ListItem>
        <ListItem
          className={activePage === "/boardgames" ? "activePage" : ""}
          button
          name="boardgames"
          key="boardgames"
          onClick={handleMenuClick}
        >
          <img className="bgLogo" src={bgLogo} />
          <ListItemText className="" primary="&nbsp;&nbsp;Board Games" />
        </ListItem>
        {/* <ListItem
          className={activePage === "/" ? "activePage" : ""}
          button
          name="board"
          key="board"
          onClick={handleMenuClick}
        >
          <img className="boardLogo" src={""} />
          <ListItemText className="" primary="&nbsp;&nbsp;Board Games" />
        </ListItem> */}
      </List>
      {/* <List>
        <ListItem button name="hcMain" key="hcMain" onClick={handleMenuClick}>
          <ListItemText className="" primary="&nbsp;&nbsp;HeroClix" />
        </ListItem>
      </List> */}
      {/* <Divider />
      <List>
        <ListItem button name="sets" key="sets" onClick={handleMenuClick}>
          <ListItemText primary="Set Master" />
        </ListItem>
        <ListItem
          button
          name="teamBuilder"
          key="teamBuilder"
          onClick={handleMenuClick}
        >
          <ListItemText primary="Team Builder" />
        </ListItem>
        <ListItem
          button
          name="marketplace"
          key="marketplace"
          onClick={handleMenuClick}
        >
          <ListItemText primary="Marketplace" />
        </ListItem>
      </List> */}

      {/* {isMod && (
        <React.Fragment>
          <Divider />
          <List>
            <ListItem button name="admin" key="admin" onClick={handleMenuClick}>
              <FiTool className="drawerIcon" />{" "}
              <ListItemText primary="Admin Portal" />
            </ListItem>
          </List>
        </React.Fragment>
      )} */}

      {/* {isAuth && (
        <React.Fragment>
          <Divider />
          <List>
            <ListItem
              className={activePage === "/userSettings" ? "activePage" : ""}
              button
              name="userSettings"
              key="userSettings"
              onClick={handleMenuClick}
            >
              <FiSettings className="drawerIcon" />
              <ListItemText primary="Settings" />
            </ListItem>
            <ListItem
              button
              name="logout"
              key="logout"
              onClick={handleMenuClick}
            >
              <FiLogOut className="drawerIcon" />
              <ListItemText primary="Logout" />
            </ListItem>
          </List>
        </React.Fragment>
      )} */}
    </Drawer>
  );
};

export default MenuDrawer;
