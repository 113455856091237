import React, { useState, useEffect, useRef, useCallback } from "react";
import { fetchDecks, fetchUnits } from "../controller";
import * as _ from "lodash";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import UnitMod from "../../shared/unit-module";
// import UnitMod from "../../shared/db-unit-module";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import Button from "../../shared/common/button";
import { IoIosAddCircle } from "react-icons/io";
import { RiPlayListAddLine } from "react-icons/ri";
import { concat } from "lodash";
import DotLoader from "../../shared/common/loader-dot";
import { insDeckUnit } from "../../shared/api";
import { fetchDeckUnits } from "../controller";
import { IoCloseSharp } from "react-icons/io5";
import { FormControlUnstyled } from "@mui/material";
import Input from "@mui/material/Input";

const DBCards = (props) => {
  const {
    loading,
    setLoading,
    openModal,
    cardAddList,
    handleAddCards,
    currentDeckName,
    currentDeckID,
    currentDeckUnits,
    setCurrentDeckUnits,
    showAddCards,
    setDeckUnitsChangeFlag,
    deckUnitsChangeFlag,
    handleSetAlert,
    filtersChangeFlag,
    setFiltersChangeFlag,
    filtersObj,
    sortBy,
    sortByChange,
    setSortByChange,
    searchTerm,
    setSearchTerm,
  } = props;

  const [units, setUnits] = useState([]);
  const [hasMore, setHasMore] = useState(true);
  const [nextPage, setNextPage] = useState(1);

  useEffect(() => {
    fetchUnits(
      setLoading,
      nextPage,
      setNextPage,
      units,
      setUnits,
      handleSetAlert,
      filtersChangeFlag,
      setFiltersChangeFlag,
      filtersObj,
      sortBy,
      sortByChange,
      setSortByChange
    );
  }, [filtersObj, filtersChangeFlag, sortBy, sortByChange, currentDeckUnits]);

  const handleLoadMoreUnits = () => {
    fetchUnits(
      setLoading,
      nextPage,
      setNextPage,
      units,
      setUnits,
      handleSetAlert,
      false,
      setFiltersChangeFlag,
      filtersObj,
      sortBy,
      false,
      setSortByChange
    );
  };

  const handleUpdateHasUnit = (unitID, unitQty) => {
    const updUnits = [...units];
    const unitIndex = _.findIndex(updUnits, { id: parseInt(unitID) });
    updUnits[unitIndex].collectionUnitQty = unitQty;
    setUnits(updUnits);
  };

  const handleKeyPress = (e) => {
    if (e.key == "Enter") {
      console.log("enter pressed!");
    }
  };

  const observer = useRef();
  const lastUnitRef = useCallback(
    (node) => {
      if (loading) return;
      if (observer.current) observer.current.disconnect();
      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting && hasMore == true) {
          handleLoadMoreUnits();
        }
      });
      if (node) observer.current.observe(node);
    },
    [loading, hasMore, units]
  );

  const handleAddNewUnit = (unit) => {
    console.log("do something", unit);
  };

  return (
    <div
      className={
        showAddCards ? "units-container" : "units-container mobile-hide"
      }
    >
      {/* <Input
        placeholder="Search Cards..."
        // inputProps={ariaLabel}
        onChange={(e) => setSearchTerm(e.target.value)}
        onKeyPress={handleKeyPress}
        className={"db-card-search"}
      /> */}
      <Button
        classAppend={cardAddList.length > 0 ? "add-btn active-add" : "add-btn"}
        text={concat("Add ", cardAddList.length, " Selected")}
        handleClick={handleAddCards}
      />
      {currentDeckID && (
        <p className="adding-to-deck">
          Adding to <b>{currentDeckName}</b>
        </p>
      )}
      <h1 className="all-cards-title">All Cards</h1>

      {/* {deckUnitsChangeFlag && <p>?</p>} */}
      <Button
        classAppend={" desktop-hide mobile-show hideCardsBtn"}
        text={<IoCloseSharp />}
        // handleClick={() =>
        //   showDecks ? setShowDecks(false) : setShowDecks(true)
        // }
      />
      <div className="units-subcontainer">
        {units.map((unit, index) => {
          if (units.length - 40 === index) {
            return (
              <div
                className="unitContainer lastUnit"
                key={unit.id}
                ref={lastUnitRef}
              >
                <Button
                  classAppend={currentDeckName ? "bulk-add-check" : "hide"}
                  text={<IoIosAddCircle />}
                  handleClick={() => {
                    insDeckUnit(currentDeckID, unit.id, "pokemon");
                  }}
                />
                <UnitMod
                  unit={unit}
                  id={unit.id}
                  name={unit.cleanName}
                  handleUpdateHasUnit={handleUpdateHasUnit}
                  onModalToggle={() => openModal(unit)}
                  builderFlag={true}
                />
              </div>
            );
          } else {
            return (
              <div className="unitContainer" key={unit.id}>
                <Button
                  classAppend={currentDeckName ? "bulk-add-check" : "hide"}
                  text={<IoIosAddCircle />}
                  handleClick={() => {
                    insDeckUnit(currentDeckID, unit.id, "pokemon");
                    for (var i = 0; i < currentDeckUnits.length; i++) {
                      if (currentDeckUnits[i].id == unit.id) {
                        const newQty = currentDeckUnits[i].qty + 1;
                        currentDeckUnits[i].qty = newQty;
                        if (deckUnitsChangeFlag == false) {
                          setDeckUnitsChangeFlag(true);
                        } else {
                          setDeckUnitsChangeFlag(false);
                        }
                      }
                    }
                    if (!currentDeckUnits.find((x) => x.id === unit.id)) {
                      fetchDeckUnits(
                        setLoading,
                        currentDeckID,
                        setCurrentDeckUnits
                      );
                    }
                  }}
                />
                <UnitMod
                  unitQty={unit.qty}
                  unit={unit}
                  id={unit.id}
                  name={unit.cleanName}
                  handleUpdateHasUnit={handleUpdateHasUnit}
                  onModalToggle={() => openModal(unit)}
                  builderFlag={true}
                />
              </div>
            );
          }
        })}
      </div>
      <div className="loaderDBCards">
        <DotLoader margin={"5"} loading={loading} />
      </div>
    </div>
  );
};

export default DBCards;
