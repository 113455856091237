import React, { useState, useEffect } from "react";
import CollectionHeader from "../collections/components/header";
import Tabs from "../shared/tabs";
import config from "../../config";
import Units from "./units";
import BGHeader from "./components/header";
import BGLogo from "../../img/boardgame_logo.png";

const Main = (props) => {
  const { handleSetAlert } = props;
  const [filtersObj, setFiltersObj] = useState([]);
  const [filtersChangeFlag, setFiltersChangeFlag] = useState();
  const [sortBy, setSortBy] = useState("Sales DESC");
  const [sortByChange, setSortByChange] = useState();

  const [view, setView] = useState();
  const [activeTab, setActiveTab] = useState("1");

  useEffect(() => {
    if (!localStorage.getItem("viewPref")) {
      localStorage.setItem("viewPref", "grid");
      setView("grid");
    } else {
      setView(localStorage.getItem("viewPref"));
    }
  }, [view]);

  return (
    <div className="general-game-container">
      <div className="set-list-container">
        <BGHeader
          game="BoardGames"
          gameLogo={BGLogo}
          activeTab={activeTab}
          setActiveTab={setActiveTab}
          setView={setView}
          activeView={view}
          filtersObj={filtersObj}
          sortBy={sortBy}
          setSortBy={setSortBy}
          setFiltersObj={setFiltersObj}
          filtersChangeFlag={filtersChangeFlag}
          sortByChange={sortByChange}
          setSortByChange={setSortByChange}
          setFiltersChangeFlag={setFiltersChangeFlag}
        />
        <div className="tabContainer">
          <div className={activeTab === "1" ? "units-container" : "noneTab"}>
            <Units
              view={view}
              filtersChangeFlag={filtersChangeFlag}
              setFiltersChangeFlag={setFiltersChangeFlag}
              setFiltersObj={setFiltersObj}
              filtersObj={filtersObj}
              handleSetAlert={handleSetAlert}
              sortBy={sortBy}
              sortByChange={sortByChange}
              setSortByChange={setSortByChange}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Main;
