import React, { useState } from "react";
import { ReactSearchAutocomplete } from "react-search-autocomplete";
import { getUnitsSearch } from "../shared/api";

export default function SearchAutocomplete(props) {
  // note: the id field is mandatory
  const {
    classAppend,
    placeholder,
    setNameAdd,
    setIDAdd,
    nameValue,
    inputRef,
  } = props;

  // const [search, setSearch] = useState("");
  const [searchItems, setSearchItems] = useState([]);
  const [displaySearchItems, setDisplaySearchItems] = useState(false);

  const handleOnSearch = (string, results) => {
    // onSearch will have as the first callback parameter
    // the string searched and for the second the results.
    if (string != "") {
      setDisplaySearchItems(true);
      getUnitsSearch(string, 1, setSearchItems);
    }
  };

  // const handleKeypress = (e) => {
  //   //it triggers by pressing the enter key
  //   if (e.key === "Enter" && search !== "") {
  //     console.log("enter pressed");
  //     const query = {
  //       name: search,
  //     };
  //     const qString = qs.stringify(query);
  //     // TODO: Find better way to redirect
  //     // return <Redirect to={`/search?${qString}`} />;
  //     window.location.href = `/search?${qString}`;
  //   }
  // };

  const handleOnSelect = (item) => {
    // the item selected
    setNameAdd(item.cleanName);
    setIDAdd(item.id);
    setDisplaySearchItems(false);

    inputRef.current.focus();
  };

  const formatResult = (item) => {
    return (
      <span>
        <img src={item.images.small ? item.images.small : ""} alt="" />
        {item.cleanName} - {item.id}
      </span>
    );
  };

  return (
    <div
      className={
        displaySearchItems ? classAppend : classAppend + " inactive-search"
      }
    >
      <ReactSearchAutocomplete
        items={searchItems}
        onSearch={handleOnSearch}
        onSelect={handleOnSelect}
        inputDebounce={50}
        formatResult={formatResult}
        placeholder={placeholder}
        maxResults={100}
        inputSearchString={nameValue}
      />
    </div>
  );
}
