import {
  getFilterSets,
  getSorts,
  getFilterRarities,
  getUnits,
  getFilters,
  getUnitsByGame,
} from "../shared/api";

export const fetchUnits = async (
  setLoading,
  page,
  setNextPage,
  currentData,
  setData,
  handleSetAlert
) => {
  try {
    setLoading(true);
    if (parseInt(page) == 1) {
      const { data, nextPage } = await getUnitsByGame("BG", page);
      setData([...data]);
      setNextPage(nextPage);
      setLoading(false);
    } else {
      const { data, nextPage } = await getUnitsByGame("BG", page);
      setData([...currentData, ...data]);
      setNextPage(nextPage);
      setLoading(false);
    }
  } catch (err) {
    console.log("ERROR GRABBING BG");
    setLoading(false);
    // handleSetAlert("error", "Error fetching Units");
  }
};

// export const fetchUnits = async (
//   setLoading,
//   page,
//   setNextPage,
//   currentData,
//   setData,
//   handleSetAlert,
//   filtersChangeFlag,
//   setFiltersChangeFlag,
//   filterObj,
//   sortBy,
//   sortByChange,
//   setSortByChange
// ) => {
//   try {
//     console.log("GRABBING BG");
//     setLoading(true);
//     if (filtersChangeFlag || sortByChange) {
//       console.log("check 1");
//       const filterBod = {
//         sort: sortBy,
//         filters: filterObj,
//       };
//       const { data, nextPage } = await getUnitsByGame("BG", 1, filterBod);
//       setData([...data]);
//       setNextPage(nextPage);
//       setLoading(false);
//       setFiltersChangeFlag(false);
//       setSortByChange(false);
//     } else {
//       console.log("check 2");
//       if (parseInt(page) == 1) {
//         console.log("check 3");
//         const filterBod = {
//           sort: sortBy,
//           filters: filterObj,
//         };
//         const { data, nextPage } = await getUnitsByGame("BG", page, filterBod);
//         setData([...data]);
//         setNextPage(nextPage);
//         setLoading(false);
//         setFiltersChangeFlag(false);
//       } else if (parseInt(page) >= 2) {
//         console.log("check 4");
//         const filterBod = {
//           sort: sortBy,
//           filters: filterObj,
//         };
//         const { data, nextPage } = await getUnitsByGame("BG", page, filterBod);
//         setData([...currentData, ...data]);
//         setNextPage(nextPage);
//         setLoading(false);
//         setFiltersChangeFlag(false);
//       }
//     }
//   } catch (err) {
//     console.log("ERROR GRABBING BG");
//     setLoading(false);
//     // handleSetAlert("error", "Error fetching Units");
//   }
// };

export const fetchBGFilterValues = async (
  setLoading,
  setFilterSets,
  setFilterRarities,
  handleSetAlert
) => {
  try {
    setLoading(true);
    const sets = await getFilterSets("BG");
    const rarities = await getFilterRarities("BG");
    setFilterSets(sets);
    setFilterRarities(rarities);
    setLoading(false);
  } catch (err) {
    setLoading(false);
    // handleSetAlert("error", "Error fetching filter data.");
  }
};

export const fetchFiltersList = async (
  setLoading,
  handleSetAlert,
  setFilters
) => {
  try {
    const data = await getFilters("BG");
    setFilters([...data]);
  } catch (err) {
    setLoading(false);
    // handleSetAlert("error", "Error fetching Units");
  }
};

export const fetchSortsList = async (
  setLoading,
  handleSetAlert,
  setSortsList
) => {
  try {
    const data = await getSorts("BG");
    setSortsList([...data]);
  } catch (err) {
    setLoading(false);
    handleSetAlert("error", "Error fetching Units");
  }
};
