import React, { useState, useEffect } from "react";
import { insWallet } from "./api";
import { fetchWalletTypes } from "./controller";
import DotLoader from "../shared/common/loader-dot";
import Select from "../shared/common/select";
import Input from "../shared/common/input";
import Button from "../shared/common/button";

const AddWallet = (props) => {
  const { handleSetAlert, currentWallets, setWallets, setShowNewWallet } =
    props;
  const [loading, setLoading] = useState(false);
  const [walletTypes, setWalletTypes] = useState([]);
  const [walletTypeID, setWalletTypeID] = useState("");
  const [nickname, setNickname] = useState("");
  const [address, setAddress] = useState("");

  useEffect(() => {
    fetchWalletTypes(setLoading, setWalletTypes, handleSetAlert);
  }, []);

  const handleAddWallet = async () => {
    try {
      const newWalletParams = {
        walletTypeID,
        address,
        nickname,
      };
      const newWallet = await insWallet(newWalletParams);
      setWallets([...currentWallets, newWallet]);
      handleSetAlert("success", "Wallet added!");
      setShowNewWallet(false);
    } catch (err) {
      if (err.response.data.message) {
        handleSetAlert("warning", err.response.data.message);
      } else {
        handleSetAlert("error", err.message);
      }
    }
  };

  return (
    <>
      {!loading && walletTypes.length > 0 && (
        <div className="add-wallet-form">
          <h3>Add Wallet</h3>
          <Select
            classAppend="newWalletDrpDown"
            variant="outlined"
            idAppend="nft-wallet-type"
            label="Type"
            value={walletTypeID}
            data={walletTypes}
            dataKey="id"
            dataValue="id"
            dataName="name"
            handleChange={(e) => setWalletTypeID(e.target.value)}
          />
          <br />
          <Input
            classAppend="newWalletTextBox"
            idAppend="nft-wallet-nickname"
            label="Wallet Nickname"
            variant="outlined"
            value={nickname}
            handleChange={(e) => setNickname(e.target.value)}
          />
          <br />
          <Input
            classAppend="newWalletTextBox"
            idAppend="nft-wallet-address"
            label="Wallet Address"
            variant="outlined"
            value={address}
            handleChange={(e) => setAddress(e.target.value)}
          />
          <br />
          <Button
            variant="outlined"
            size="large"
            color="primary"
            handleClick={handleAddWallet}
            className="newWalletSubmit"
            text="Add Wallet"
            customStyle={{ color: "green", borderColor: "green" }}
          />
        </div>
      )}
    </>
  );
};

export default AddWallet;
