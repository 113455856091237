import React, { useState, useEffect } from "react";
import { fetchWallets } from "./controller";
import { Grid } from "@material-ui/core";
import DotLoader from "../shared/common/loader-dot";
import Wallet from "./wallet";
import CustModal from "./components/modal";

const WalletContainer = (props) => {
  const { handleSetAlert } = props;
  const [loading, setLoading] = useState(false);
  const [wallets, setWallets] = useState([]);
  const [currentUnit, setCurrentUnit] = useState();
  const [toggleModal, setToggleModal] = useState("hide");

  useEffect(() => {
    fetchWallets(setLoading, setWallets, handleSetAlert);
  }, []);

  const openModal = (unit) => {
    setCurrentUnit(unit);
    console.log(unit.name + " clicked");
    console.log(unit);
    setToggleModal("show");
  };

  const closeModal = () => {
    console.log("closing modal");
    setToggleModal("hide");
  };

  return (
    <>
      <CustModal
        unit={currentUnit}
        modalRef={toggleModal}
        hideModal={closeModal}
        nftFlag={true}
      />
      {!loading && wallets.length === 0 && <h1>Add your NFT wallets</h1>}
      {!loading && wallets.length > 0 && (
        <Grid container className="">
          {wallets.map((w) => {
            return (
              <Wallet
                handleSetAlert={handleSetAlert}
                wallet={w}
                openModal={openModal}
              />
            );
          })}
        </Grid>
      )}
      <DotLoader loading={loading} />
    </>
  );
};

export default WalletContainer;
