import React, { useState } from "react";
import { ReactSearchAutocomplete } from "react-search-autocomplete";
import { getUnitsSearch } from "../shared/api";
import qs from "querystring";

export default function SearchAutocomplete(props) {
  // note: the id field is mandatory

  const { classAppend, placeholder, openModal } = props;

  // const [search, setSearch] = useState("");
  const [searchItems, setSearchItems] = useState([]);
  const [displaySearchItems, setDisplaySearchItems] = useState(false);
  const [searchRef, setSearchRef] = useState("");
  const [searchLoading, setSearchLoading] = useState("");

  const handleOnSearch = async (string, results) => {
    setSearchRef(string);
    if (string != "") {
      setDisplaySearchItems(true);
      await getUnitsSearch(string, 1, setSearchItems, setSearchLoading);
    }
  };

  const handleKeyPress = (event) => {
    console.log(event);
    // it triggers by pressing the enter key
    // if (e.key === "Enter" && searchRef !== "") {
    //   console.log("enter pressed");
    //   const query = {
    //     name: searchRef,
    //   };
    //   const qString = qs.stringify(query);
    //   // TODO: Find better way to redirect
    //   // return <Redirect to={`/search?${qString}`} />;
    //   window.location.href = `/search?${qString}`;
    // }
  };

  const handleOnSelect = (item) => {
    openModal(item);
    setDisplaySearchItems(false);
  };

  const formatResult = (item) => {
    return (
      <span>
        <img src={item.images.small ? item.images.small : ""} alt="" />
        {item.cleanName} - {item.id}
      </span>
    );
  };

  return (
    <div
      className={
        displaySearchItems ? classAppend : classAppend + " inactive-search"
      }
    >
      <ReactSearchAutocomplete
        items={searchItems}
        onSearch={handleOnSearch}
        onSelect={handleOnSelect}
        inputDebounce={50}
        formatResult={formatResult}
        placeholder={placeholder}
        maxResults={100}
      />
    </div>
  );
}
