import React, { useState, useEffect, useRef, useCallback } from "react";
import { fetchDecks, fetchUnits } from "../controller";
import * as _ from "lodash";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import UnitMod from "../../shared/db-unit-module";
// import DBUnitMod from "./db-unit-mod";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import Button from "../../shared/common/button";
import { BsTrashFill } from "react-icons/bs";
import { concat } from "lodash";
import { DotLoader } from "react-spinners";
import { IoMdRemoveCircle, IoIosAddCircle, IoMdAdd } from "react-icons/io";
import { HiOutlineMenu } from "react-icons/hi";
import { delDeckUnit, insDeckUnit } from "../../shared/api";

const DBDeckView = (props) => {
  const {
    handleUpdateHasUnit,
    openModal,
    currentDeckUnits,
    setCurrentDeckUnits,
    currentDeckName,
    setCurrentDeckName,
    setShowDecks,
    currentDeckID,
    setCurrentDeckID,
    handleSetAlert,
    setShowAddCards,
    handleDeleteDeck,
  } = props;

  const [newUnitQty, setNewUnitQty] = useState(0);

  return (
    <div className="deck-units-container">
      {currentDeckName && (
        <Button
          classAppend={"delete-deck-btn"}
          text={<BsTrashFill />}
          handleClick={() => {
            handleDeleteDeck(currentDeckID);
            setCurrentDeckUnits([]);
            setShowDecks(true);
            setCurrentDeckID();
            setCurrentDeckName();
          }}
        />
      )}
      <Button
        classAppend={"expand-decks-btn"}
        text={<HiOutlineMenu />}
        handleClick={() => setShowDecks(true)}
      />
      <Button
        classAppend={"add-to-deck-btn"}
        text={<IoMdAdd />}
        handleClick={() => setShowAddCards(true)}
      />
      {/* <h1>Deck Name</h1> */}
      {currentDeckName && <h1>{currentDeckName}</h1>}
      {!currentDeckName && (
        <h1 className="selectDeckLabel">Select a Deck...</h1>
      )}
      {currentDeckUnits.length == 0 && currentDeckName && (
        <h1 className="addCardsLabel">Add Cards to Your Deck!</h1>
      )}
      {true && (
        <div className="units-subcontainer">
          {currentDeckUnits.map((unit, index) => {
            return (
              <div className="unitContainer" key={unit.id}>
                {/* <Button
                  classAppend={
                    selectMode
                      ? cardAddList.includes(unit.id)
                        ? "bulk-add-check active-check-btn"
                        : "bulk-add-check"
                      : "hide"
                  }
                  text={<AiFillCheckCircle />}
                  handleClick={() => {
                    const filterCardList = cardAddList.filter(
                      (card) => card != unit.id
                    );
                    cardAddList.includes(unit.id)
                      ? setCardAddList(filterCardList)
                      : setCardAddList([...cardAddList, unit.id]);
                  }}
                /> */}
                {/* {deckUnitsChangeFlag && <p>?</p>} */}
                <Button
                  classAppend={
                    currentDeckName ? "bulk-add-check deck-remove-btn" : "hide"
                  }
                  text={<IoMdRemoveCircle />}
                  handleClick={() => {
                    delDeckUnit(currentDeckID, unit.id, "mtg");
                    for (var i = 0; i < currentDeckUnits.length; i++) {
                      if (currentDeckUnits[i].id == unit.id) {
                        if (currentDeckUnits[i].qty == 1) {
                          const newCardsList = currentDeckUnits.filter(
                            (card) => card.id != unit.id
                          );
                          setCurrentDeckUnits(newCardsList);
                        } else {
                          const newqty = currentDeckUnits[i].qty - 1;
                          currentDeckUnits[i].qty = newqty;
                          setNewUnitQty(newqty);
                          // setUnitID(unit.id);
                        }
                      }
                    }
                  }}
                />
                <Button
                  classAppend={
                    currentDeckName ? "bulk-add-check deck-add-btn" : "hide"
                  }
                  text={<IoIosAddCircle />}
                  handleClick={() => {
                    insDeckUnit(currentDeckID, unit.id, "mtg", handleSetAlert);
                    for (var i = 0; i < currentDeckUnits.length; i++) {
                      if (currentDeckUnits[i].id == unit.id) {
                        const newqty = currentDeckUnits[i].qty + 1;
                        currentDeckUnits[i].qty = newqty;
                        setNewUnitQty(newqty);
                      }
                    }
                  }}
                />
                <UnitMod
                  unitQty={unit.qty}
                  unit={unit}
                  id={unit.id}
                  name={unit.cleanName}
                  handleUpdateHasUnit={handleUpdateHasUnit}
                  onModalToggle={() => openModal(unit)}
                />
              </div>
            );
          })}
        </div>
      )}
      {/* <DotLoader margin={"5"} loading={loading} /> */}
    </div>
  );
};

export default DBDeckView;
