import React, { useState, useEffect } from "react";
import { Grid } from "@material-ui/core";
import Button from "../../shared/common/button";
import SearchIcon from "@material-ui/icons/Search";
import InputBase from "@material-ui/core/InputBase";
import { IoGrid } from "react-icons/io5";
import { AiFillCaretDown, AiFillCaretUp, AiOutlineMenu } from "react-icons/ai";
import { HiArrowCircleRight } from "react-icons/hi";
import { headerStyles } from "../styles";
import { Autocomplete } from "@material-ui/lab";
import { TextField } from "@material-ui/core";
import Slider from "@mui/material/Slider";
import Input from "../../shared/common/input";
import { fetchFiltersList, fetchSortsList } from "../controller";
import Tabs from "../../shared/tabs";
import qs from "querystring";

import Box from "@mui/material/Box";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import InputLabel from "@mui/material/InputLabel";

const useStyles = headerStyles;

const Header = (props) => {
  const {
    handleSetAlert,
    setActiveTab,
    activeTab,
    setView,
    activeView,
    setFiltersObj,
    filtersObj,
    sortBy,
    setSortBy,
    setSortByChange,
    filtersChangeFlag,
    setFiltersChangeFlag,
  } = props;

  const classes = useStyles();

  const [priceLowRef, setPriceLowRef] = useState();
  const [priceHighRef, setPriceHighRef] = useState();
  const [toggleFlag, setFlag] = useState(false);
  const [loading, setLoading] = useState(false);
  const [filtersList, setFiltersList] = useState([]);
  const [sortsList, setSortsList] = useState([]);
  const [filters, setFilters] = useState([]);
  // const [sortBy, setSortBy] = useState();
  const [selected, setSelected] = useState(false);
  const [showFilters, setShowFilters] = useState("inactive");
  const [showSorts, setShowSorts] = useState("inactive");
  const [searchRef, setSearchRef] = useState("");
  const [costValue, setCostValue] = useState([0, 16]);
  const [powerValue, setPowerValue] = useState([0, 16]);
  const [tlValue, setTLValue] = useState([0, 16]);
  const [activeSort, setActiveSort] = useState("");

  useEffect(() => {
    fetchFiltersList(setLoading, handleSetAlert, setFiltersList);
    fetchSortsList(setLoading, handleSetAlert, setSortsList);
  }, [activeView]);

  const toggleSearchClass = () => {
    console.log("toggle");
    if (toggleFlag) {
      setFlag(false);
    } else {
      setFlag(true);
    }
  };

  const toggleView = (e) => {
    if (e.currentTarget.id == "cmn-btn-id-table" && activeView == "grid") {
      {
        setView("table");
        localStorage.setItem("viewPref", "table");
      }
      // localStorage.setItem("activeView", "grid");
    } else if (
      e.currentTarget.id == "cmn-btn-id-grid" &&
      activeView == "table"
    ) {
      {
        setView("grid");
        localStorage.setItem("viewPref", "grid");
      }
      // localStorage.setItem("activeView", "grid");
    }
  };

  const handleFilter = (event, name, search = false) => {
    let filterValue = "";
    if (search) {
      filterValue = searchRef;
    } else {
      filterValue = event.target.innerText;
    }

    const filterName = name;

    console.log(name, filterValue);

    if (filters.length > 0) {
      for (var i = 0; i < filters.length; i++) {
        if (filters[i].name && filters[i].name == filterName) {
          if (filterValue) {
            console.log("changing exisiting");
            var newFilters = filters;
            newFilters[i] = { name: filterName, values: [filterValue] };
            setFilters(newFilters);
            break;
          } else {
            console.log("taking filter out");
            var newFilters = filters.filter((item) => item.name !== filterName);
            setFilters(newFilters);
            break;
          }
        } else {
          console.log("adding new");
          const newFilters = filters.concat({
            name: filterName,
            values: [filterValue],
          });
          setFilters(newFilters);
        }
      }
    } else {
      console.log("adding first", name, filterValue);
      const newFilters = filters.concat({
        name: filterName,
        values: [filterValue],
      });
      console.log(newFilters);
      setFilters(newFilters);
    }
  };

  const clearFilters = () => {
    setFilters([]);
    setFiltersObj([]);
    setShowFilters("inactive");
  };

  const applyFilters = () => {
    if (showFilters == "inactive") {
      setShowFilters("active");
    } else {
      setFiltersObj(filters);
      setFiltersChangeFlag(true);
      setShowFilters("inactive");
    }
  };

  const applySorts = (sortVal) => {
    setSortBy(sortVal);
    setSortByChange(true);
    setShowSorts("inactive");
  };

  const toggleFilters = () => {
    if (showFilters == "inactive") {
      setShowFilters("active");
      setShowSorts("inactive");
    } else {
      // setFiltersObj(filters)
      // setFiltersChangeFlag(true)
      setShowFilters("inactive");
      setShowSorts("inactive");
    }
  };
  const toggleSorts = () => {
    if (showSorts == "inactive") {
      setShowSorts("active");
      setShowFilters("inactive");
    } else {
      // setFiltersObj(filters)
      // setFiltersChangeFlag(true)
      setShowSorts("inactive");
      setShowFilters("inactive");
    }
  };

  const handleSortChange = () => {
    //Pass
  };

  const handleSliderChange = (filter, newValue) => {
    if (filter == "ConvertedCost") {
      setCostValue(newValue);
    } else if (filter == "Power") {
      setPowerValue(newValue);
    } else if (filter == "Toughness") {
      setTLValue(newValue);
    } else {
      console.log("no filter found");
    }
  };

  const handleKeypress = async (e) => {
    if (e.key === "Enter" && searchRef !== "") {
      console.log("searchref:", searchRef);
      handleFilter(e, "Product Name", true);
      setFiltersObj(filters);
      setFiltersChangeFlag(true);
      setShowFilters("inactive");
    }
  };

  const handleSliderAdd = (filterName) => {
    var filterAdd = "";

    if (filterName == "ConvertedCost") {
      filterAdd = { name: filterName, values: costValue };
    } else if (filterName == "Power") {
      filterAdd = { name: filterName, values: powerValue };
    } else if (filterName == "Toughness") {
      filterAdd = { name: filterName, values: tlValue };
    }

    console.log("FILTER ADD:" + filterAdd);

    if (filters.length > 0) {
      for (var i = 0; i < filters.length; i++) {
        if (filters[i].name && filters[i].name == filterName) {
          console.log("changing exisiting");
          var newFilters = filters;
          newFilters[i] = filterAdd;
          setFilters(newFilters);
          break;
        } else {
          console.log("adding new");
          const newFilters = filters.concat(filterAdd);
          setFilters(newFilters);
        }
      }
    } else {
      console.log("adding first");
      const newFilters = filters.concat(filterAdd);
      setFilters(newFilters);
    }
  };

  // const applyFilters = () => {
  // }

  return (
    <Grid item justify="center" sx={8}>
      <div className={"collHeader " + props.headerClasses}>
        <img src={props.gameLogo} className={props.game + "Logo"} alt="" />
        {/* <div
          className={
            toggleFlag
              ? classes.search + " searchBox searchCollection focused"
              : classes.search + " searchBox searchCollection"
          }
        >
          <div className={classes.searchIcon}>
            <SearchIcon />
          </div>
          <InputBase
            placeholder={"Search " + props.game + "..."}
            classes={{
              root: classes.inputRoot,
              input: classes.inputInput,
            }}
            // value={unitName}
            // onChange={(e) => setUnitName(e.target.value)}
            // onFocus={toggleSearchClass}
            // onBlur={toggleSearchClass}
            className={"searchPlaceholder"}
            inputProps={{ "aria-label": "search" }}
          />
          <div className="searchSend" onClick={applySearch}>
            <HiArrowCircleRight />
          </div>
        </div> */}
        {/* <div className="sorts">
            <Autocomplete
              value={sortBy}
              onChange={(event, newValue) => {
                setSortBy(newValue);
              }}
              // inputValue={inputValue}
              // onInputChange={(event, newInputValue) => {
              //   setInputValue(newInputValue);
              // }}

              id="controllable-states-demo"
              options={sortsList}
              getOptionLabel={(option) => option.text}
              renderInput={(params) => <TextField {...params}
                    variant="outlined"  label="Sort By" />}
            />
          </div> */}

        <div
          className={
            showSorts == "active"
              ? "show sorts-container mtg-sorts"
              : "hide sorts-container mtg-sorts"
          }
        >
          {sortsList.map((sort, index) => (
            <React.Fragment>
              {!sort.value.includes("Price") && (
                <Button
                  key={index}
                  variant="none"
                  size="small"
                  color="primary"
                  // idAppend="grid"
                  text={sort.text}
                  classAppend={
                    sortBy == sort.value ? "active sortby-btn" : "sortby-btn"
                  }
                  handleClick={() => {
                    applySorts(sort.value);
                  }}
                />
              )}
            </React.Fragment>
          ))}
        </div>

        {/* <Tabs
            classAppend="tabButtonsContainer"
            activeTab={activeTab}
            setActiveTab={setActiveTab}
            data={tabData}
          /> */}
        <span>
          {/* {filtersList.map((filter) => <div>{filter.name}</div>)} */}
        </span>
        <div className="head-btn-container">
          <button
            className={sortBy ? "sort-btn" : "sort-btn active-sort-btn"}
            onClick={toggleSorts}
          >
            Sort By{" "}
            {showSorts == "inactive" ? <AiFillCaretUp /> : <AiFillCaretDown />}
          </button>
          <button
            className={
              filtersObj ? "filters-btn" : "filters-btn active-filters-btn"
            }
            onClick={toggleFilters}
          >
            Filters{" "}
            {showFilters == "inactive" ? (
              <AiFillCaretUp />
            ) : (
              <AiFillCaretDown />
            )}
          </button>
        </div>
        {!loading && (
          <div
            className={"filters-container mtg-filters-container " + showFilters}
          >
            {/* <ToggleButton
              value="check"
              selected={selected}
              onChange={() => {
                setSelected(!selected);
              }}
            >
              <BsCheck />
            </ToggleButton> */}
            {filtersList.map((filter, index) => (
              <React.Fragment>
                {filter.inputType == "SingleValue" && (
                  <Autocomplete
                    disablePortal
                    className="textInput filterInput"
                    id="auto-filters-set-mtg"
                    options={filter.items}
                    getOptionLabel={(option) => option.text}
                    onChange={(e) => {
                      handleFilter(e, filter.name);
                    }}
                    // onBlur={(e, value) => {addFilter(e, value, filter.name)}}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label={filter.displayName}
                        variant="outlined"
                        // onBlur={(e) => {addFilter(e, filter.name)}}
                      />
                    )}
                  />
                )}
                {filter.inputType == "MultipleValues" && (
                  <Autocomplete
                    disablePortal
                    className="textInput filterInput"
                    // multiple
                    id="auto-filters-set-mtg"
                    // options={filterSets}
                    options={filter.items}
                    getOptionLabel={(option) => option.text}
                    // onBlur={(e, value) => {addFilter(e, value, filter.name)}}
                    onChange={(e) => {
                      handleFilter(e, filter.name);
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label={filter.displayName}
                        variant="outlined"
                        // onBlur={(e, value) => {addFilter(e, filter.name)}}
                      />
                    )}
                  />
                )}
                {filter.displayName == "Product Name" && (
                  <TextField
                    value={searchRef}
                    onBlur={(e) => {
                      handleFilter(e, filter.name);
                    }}
                    onKeyPress={(e) => handleKeypress(e)}
                    onChange={(e) => setSearchRef(e.target.value)}
                    className="textInput filterInput"
                    id="outlined-basic"
                    label={"Card Name"}
                    variant="outlined"
                  />
                )}
                {/* 
              PRICE FILTERS COMING, NEED TO CLARIFY TCGP API EXPECTATIONS
              {filter.inputType == "Price" &&
                <React.Fragment>
                  <span>
                  <Input
                    classAppend="priceLowInputMTG priceInput filterInput"
                    idAppend="price-low-mtg"
                    label="Price Low"
                    variant="outlined"
                    value={priceLowRef}
                    handleChange={(e) => {e ? setPriceLowRef(e.target.value) : setPriceLowRef(undefined)}}
                    // onBlur={(e) => {handleFilter(e, filter)}}
                  />
                  <p>-</p> 
                  <Input
                    classAppend="priceHighInputMTG priceInput filterInput"
                    idAppend="price-high-mtg"
                    label="Price High"
                    variant="outlined"
                    value={priceHighRef}
                    handleChange={(e) => {e ? setPriceHighRef(e.target.value) : setPriceHighRef(undefined)}}
                    // onBlur={(e) => {handleFilter(e, filter)}}
                  />
                  </span>
                </React.Fragment>
              } */}
                {filter.inputType.includes("Range") && (
                  <React.Fragment>
                    <p className="range-label">{filter.displayName}</p>
                    <Slider
                      getAriaLabel={() => "Temperature range"}
                      value={
                        filter.name == "ConvertedCost"
                          ? costValue
                          : filter.name == "Power"
                          ? powerValue
                          : tlValue
                      }
                      onChange={(e, value) =>
                        handleSliderChange(filter.name, value)
                      }
                      onBlur={() => handleSliderAdd(filter.name)}
                      valueLabelDisplay="auto"
                      // getAriaValueText={""}
                      disableSwap
                      min={0}
                      max={16}
                      marks={[
                        {
                          value: 0,
                          label: "0",
                        },
                        {
                          value: 16,
                          label: "16",
                        },
                      ]}
                    />
                  </React.Fragment>
                )}
                {/* <div>{filter.name}</div> */}
              </React.Fragment>
            ))}
            <br />
            <button
              onClick={clearFilters}
              className="clear-btn filter-action-btn"
            >
              Clear All
            </button>
            <button
              onClick={applyFilters}
              className="done-btn filter-action-btn"
            >
              Apply
            </button>
          </div>
        )}
        {/* <SimpleAccordion/> */}
        <div className="view-btn-container">
          <div className="grid-btn-container">
            <Button
              variant="outlined"
              size="small"
              color="primary"
              idAppend="grid"
              text={<IoGrid />}
              classAppend={
                activeView == "grid" ? "grid-btn active-view-btn" : "grid-btn"
              }
              handleClick={toggleView}
            />
          </div>
          <div className="table-btn-container">
            <Button
              variant="outlined"
              size="small"
              color="primary"
              idAppend="table"
              text={<AiOutlineMenu />}
              classAppend={
                activeView == "table"
                  ? "table-btn active-view-btn"
                  : "table-btn"
              }
              handleClick={toggleView}
            />
          </div>
        </div>
      </div>
    </Grid>
  );
};

export default Header;
