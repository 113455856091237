import React, { useState, useEffect } from "react";
import { Button } from "@material-ui/core";
import { delSubscription } from "../controller";

const CancelSubscription = (props) => {
  const { handleSetUser, handleSetAlert } = props;

  const handleCancelSubscription = async () => {
    try {
      await delSubscription(handleSetUser);
      handleSetAlert("success", "Subscription Cancelled.");
    } catch (err) {
      if (err.response) {
        handleSetAlert("warning", err.response.data.message);
      } else {
        handleSetAlert("error", err.message);
      }
    }
  };

  return (
    <div className="cancel-subscription">
      <h2>Cancel Subscription</h2>
      <br />
      <br />
      <p>
        Cancelling the subscription terminates usage of premium features
        immediately. All premium data (collection, teams, etc) is saved for 1
        year if you want to renew.
      </p>
      <Button
        variant="outlined"
        size="large"
        // TODO: Move this out of inline styling
        style={{ color: "red", borderColor: "red" }}
        onClick={handleCancelSubscription}
      >
        Cancel Subscription
      </Button>
    </div>
  );
};

export default CancelSubscription;
