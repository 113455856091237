import React, { useState, useEffect } from "react";
import { fetchEditSetData } from "./controller";
import { updToggleSetActive, updSet } from "./api";
import { useParams } from "react-router-dom";
import Button from "../../shared/common/button";
import Select from "../../shared/common/select";
import Input from "../../shared/common/input";
import DatePicker from "../../shared/common/date-picker";
import Breadcrumbs from "../../shared/common/breadcrumbs";

const EditMagicSet = (props) => {
  const { handleSetAlert } = props;
  const { setID } = useParams();
  const [loading, setLoading] = useState(false);
  const [setTypes, setSetTypes] = useState([]);
  const [name, setName] = useState();
  const [type, setType] = useState("");
  const [releaseDate, setReleaseDate] = useState(new Date());
  const [isActive, setIsActive] = useState(false);

  useEffect(() => {
    fetchEditSetData(
      handleSetAlert,
      setLoading,
      setSetTypes,
      setID,
      setName,
      setType,
      setReleaseDate,
      setIsActive
    );
  }, [handleSetAlert, setID]);

  const handleSubmit = async () => {
    try {
      const values = {
        setTypeID: type,
        name,
        releaseDate,
      };
      await updSet(setID, values);
      handleSetAlert("success", "Set Updated!");
    } catch (err) {
      if (err.response.data.message) {
        handleSetAlert("warning", err.response.data.message);
      } else {
        handleSetAlert("error", err.message);
      }
    }
  };

  const handleToggle = async () => {
    try {
      await updToggleSetActive(setID);
      setIsActive(!isActive);
      const alertMessage = isActive ? "Set Deactivated" : "Set Activated";
      handleSetAlert("success", alertMessage);
    } catch (err) {
      handleSetAlert("error", "Error Updating Set Active");
    }
  };

  const breadcrumbData = [
    {
      name: "Admin",
      link: "/admin",
    },
    {
      name: "MTG",
      link: "/admin/mtg/sets",
    },
    {
      name: `${name} (set)`,
      color: "textPrimary",
    },
  ];

  return (
    <div className="homeContainer adminContainer">
      <div className="adminLinkList">
        <Breadcrumbs classAppend="editSet" data={breadcrumbData} />
      </div>
      {loading && <h3>Loading...</h3>}
      {!loading && setTypes.length > 0 && (
        <React.Fragment>
          <h1 className="pageTitle">{`Edit ${name}`}</h1>
          <br />
          <br />
          <div className="actualeditSetContainer">
            <Select
              classAppend="setInput"
              variant="outlined"
              idAppend="set-type"
              label="Set Type"
              value={type}
              data={setTypes}
              dataKey="id"
              dataValue="id"
              dataName="name"
              handleChange={(e) => setType(e.target.value)}
            />
            <br />
            <Input
              classAppend="setInput"
              idAppend="set-name"
              label="Set Name"
              variant="outlined"
              value={name}
              handleChange={(e) => setName(e.target.value)}
            />
            <br />
            <DatePicker
              classAppend="setDatePicker"
              idAppend="release-date"
              variant="inline"
              label="Release Date"
              value={releaseDate}
              handleChange={(e) => setReleaseDate(e)}
              disableToolbar={true}
              KeyboardButtonProps={{
                "aria-label": "change date",
              }}
            />
            <br />
            <Button
              variant="outlined"
              size="large"
              color="primary"
              handleClick={handleSubmit}
              className="setBtn"
              text="Update Set"
            />
            <br />
            <br />
            <Button
              className="setBtn"
              variant="outlined"
              size="large"
              customStyle={
                isActive
                  ? { color: "red", borderColor: "red" }
                  : { color: "green", borderColor: "green" }
              }
              handleClick={handleToggle}
              text={isActive ? "Deactivate" : "Activate"}
            />
          </div>
        </React.Fragment>
      )}
    </div>
  );
};

export default EditMagicSet;
