import React, { useState, useEffect } from "react";
import { Switch, Route } from "react-router-dom";
import "./App.css";
import jwtDecode from "jwt-decode";
import config from "./config";
import http from "./services/http";
import { Snackbar } from "@material-ui/core";
import MuiAlert from "@material-ui/lab/Alert";

// Custom Router Components
import ProtectedRoute from "./guards/protectedRoute";
import ModRoute from "./guards/modRoute";

// Containters
// Navigation
import PrimarySearchAppBar from "./modules/navigation/materialNav";
import MenuDrawer from "./modules/navigation/menuDrawer";
import MiniDrawer from "./modules/navigation/miniDrawer";

// Main
import Home from "./modules/main/home";
import NotFound from "./modules/main/notFound";
import Login from "./modules/main/login";
import Logout from "./modules/main/logout";
import Register from "./modules/main/register";

// Users
import UserSettings from "./modules/users/userSettings";
import CollectionMain from "./modules/collections/main";

// Search
import SearchMain from "./modules/search/main";

// Admin
import Admin from "./modules/admin/admin";

// Magic Admin
import MagicAdmin from "./modules/admin/mtg/admin";
import MagicSetsAdmin from "./modules/admin/mtg/sets";
import NewMagicSet from "./modules/admin/mtg/new-set";
import EditMagicSet from "./modules/admin/mtg/edit-set";
import MagicUnitsAdmin from "./modules/admin/mtg/units";
import NewMagicUnit from "./modules/admin/mtg/new-unit";
import EditMagicUnit from "./modules/admin/mtg/edit-unit";

// Magic
import MagicMain from "./modules/mtg/main";
// import MagicSetUnits from "./modules/mtg/set-units";

// Pokemon
import PokemonMain from "./modules/pokemon/main";

// Board Games
import BGMain from "./modules/boardgames/main";

// Heroclix
import HCMain from "./modules/heroclix/hcMain";
import TeamBuilder from "./modules/heroclix/teamBuilder";

// NFTs
import NFTMain from "./modules/nft/main";

//Footer
import Footer from "./modules/shared/footer";
import CustomMobileDrawer from "./modules/navigation/customMobileDrawer";
import CustomMobileTopBar from "./modules/navigation/customMobileTopBar";

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

function App() {
  const [user, setUser] = useState({});
  const [menuOpen, setMenuOpen] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [alertSeverity, setAlertSeverity] = useState("");
  const [alert, setAlert] = useState("");
  const [theme, setTheme] = useState("light");

  useEffect(() => {
    if (localStorage.getItem(config.authHeader)) {
      const storedUser = jwtDecode(localStorage.getItem(config.authHeader));
      setUser(storedUser);
    }
    document.body.className = theme;
  }, [theme]);

  const toggleTheme = () => {
    if (theme === "light") {
      setTheme("dark");
    } else {
      setTheme("light");
    }
  };

  const handleAlertClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setShowAlert(false);
  };

  const handleSetAlert = (severity, message) => {
    setAlertSeverity(severity);
    setAlert(message);
    setShowAlert(true);
  };

  const handleSetUser = (authToken) => {
    localStorage.setItem(config.authHeader, authToken);
    http.setCommonHeader(config.authHeader, authToken);
    const tokenUser = jwtDecode(authToken);
    setUser(tokenUser);
  };

  const handleClearUser = () => {
    localStorage.removeItem(config.authHeader);
    http.setCommonHeader(config.authHeader, "");
    setUser({});
  };

  const handleMenuOpen = () => {
    setMenuOpen(true);
  };

  const handleMenuClose = () => {
    setMenuOpen(false);
  };

  return (
    <div className={`App ${theme}`}>
      <PrimarySearchAppBar
        handleMenuOpen={handleMenuOpen}
        inboxCount={0}
        notficationCount={0}
        user={user}
      />
      {/* <CustomMobileDrawer/>
      <CustomMobileTopBar/> */}
      <MenuDrawer
        user={user}
        menuOpen={menuOpen}
        handleMenuClose={handleMenuClose}
      />
      <MiniDrawer user={user} />
      <Switch>
        {/* Admin Routes */}
        <ModRoute
          path="/admin/mtg/sets/:setID/units/edit/:unitID"
          component={EditMagicUnit}
          user={user}
          handleSetAlert={handleSetAlert}
        />
        <ModRoute
          path="/admin/mtg/sets/:setID/units/new"
          component={NewMagicUnit}
          user={user}
          handleSetAlert={handleSetAlert}
        />
        <ModRoute
          path="/admin/mtg/sets/:setID/units"
          component={MagicUnitsAdmin}
          user={user}
          handleSetAlert={handleSetAlert}
        />
        <ModRoute
          path="/admin/mtg/sets/edit/:setID"
          component={EditMagicSet}
          user={user}
          handleSetAlert={handleSetAlert}
        />
        <ModRoute
          path="/admin/mtg/sets/new"
          component={NewMagicSet}
          user={user}
          handleSetAlert={handleSetAlert}
        />
        <ModRoute
          path="/admin/mtg/sets"
          component={MagicSetsAdmin}
          user={user}
        />
        <ModRoute
          path="/admin/mtg"
          component={MagicAdmin}
          user={user}
          handleSetAlert={handleSetAlert}
        />
        <ModRoute
          path="/admin"
          component={Admin}
          user={user}
          handleSetAlert={handleSetAlert}
        />
        {/* Search Routes */}
        <Route
          path="/search"
          render={(props) => (
            <SearchMain
              {...props}
              handleSetAlert={handleSetAlert}
              user={user}
            />
          )}
        />
        {/* Magic: The Gathering Routes */}
        {/* <Route
          path="/mtg/sets/:setID/units"
          render={(props) => (
            <MagicSetUnits
              {...props}
              handleSetAlert={handleSetAlert}
              user={user}
            />
          )}
        /> */}
        <Route
          path="/mtg"
          render={(props) => (
            <MagicMain {...props} handleSetAlert={handleSetAlert} user={user} />
          )}
        />
        {/* Pokemon TCG Routes */}
        <Route
          path="/pokemon"
          render={(props) => (
            <PokemonMain
              {...props}
              handleSetAlert={handleSetAlert}
              user={user}
            />
          )}
        />
        {/* Board Game Routes */}
        <Route
          path="/boardgames"
          render={(props) => (
            <BGMain {...props} handleSetAlert={handleSetAlert} user={user} />
          )}
        />
        {/* NFT Routes */}
        <Route
          path="/nft"
          render={(props) => (
            <NFTMain {...props} handleSetAlert={handleSetAlert} user={user} />
          )}
        />
        {/* User Managment Routes */}
        <ProtectedRoute
          path="/userSettings"
          component={UserSettings}
          user={user}
          handleSetUser={handleSetUser}
          handleSetAlert={handleSetAlert}
        />
        <Route
          path="/login"
          render={(props) => <Login {...props} handleSetUser={handleSetUser} />}
        />
        <Route
          path="/logout"
          render={(props) => (
            <Logout {...props} handleClearUser={handleClearUser} />
          )}
        />
        <Route
          path="/register"
          render={(props) => (
            <Register {...props} handleSetUser={handleSetUser} />
          )}
        />
        {/* Collection Routes */}
        <Route
          path="/collection"
          render={(props) => (
            <CollectionMain
              {...props}
              handleSetAlert={handleSetAlert}
              user={user}
            />
          )}
        />
        {/* <Route
          path="/profile"
          render={(props) => (
            <Profile {...props} handleSetUser={handleSetUser} user={user} />
          )}
        /> */}
        <Route
          path="/teamBuilder"
          render={(props) => (
            <TeamBuilder {...props} handleSetUser={handleSetUser} user={user} />
          )}
        />
        <Route
          path="/hcMain"
          render={(props) => (
            <HCMain {...props} handleSetUser={handleSetUser} user={user} />
          )}
        />
        <Route
          path="/"
          render={(props) => (
            <Home {...props} user={user} handleSetUser={handleSetUser} />
          )}
          exact
        />
        <Route path="*" component={NotFound} />
      </Switch>
      <Snackbar
        open={showAlert}
        autoHideDuration={6000}
        onClose={handleAlertClose}
      >
        <Alert onClose={handleAlertClose} severity={alertSeverity}>
          {alert}
        </Alert>
      </Snackbar>
      <Footer />
    </div>
  );
}

export default App;
