import React, { useState, useEffect, useRef, useCallback } from "react";
import { fetchDecks, fetchDeckUnits, fetchUnits } from "./controller";
import * as _ from "lodash";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import UnitMod from "../shared/unit-module";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import DBCards from "./components/db-cards";
import DBDeckView from "./components/db-deck-view";
import DBDecks from "./components/db-decks";
import CustModal from "./components/modal";
// import { insDeckUnit } from "../shared/api";
import { delDeck } from "../shared/api";
import Button from "../shared/common/button";
import { IoMdArrowDropright } from "react-icons/io";
import { IoMdArrowDropleft } from "react-icons/io";
import { id } from "date-fns/locale";

const DeckBuilder = (props) => {
  const {
    handleSetAlert,
    filtersObj,
    setFiltersObj,
    filtersChangeFlag,
    setFiltersChangeFlag,
    sortBy,
    sortByChange,
    setSortByChange,
  } = props;
  const [loading, setLoading] = useState(false);
  const [toggleModal, setToggleModal] = useState("hide");
  const [currentUnit, setCurrentUnit] = useState();
  const [activeTab, setActiveTab] = useState("Cards");
  const [selectMode, setSelectMode] = useState(false);
  const [cardAddList, setCardAddList] = useState([]);
  const [selectIndicator, setSelectIndicator] = useState();
  const [currentDeckUnits, setCurrentDeckUnits] = useState([]);
  const [currentDeckName, setCurrentDeckName] = useState();
  const [currentDeckID, setCurrentDeckID] = useState();
  const [deckViewToggle, setDeckViewToggle] = useState(false);
  const [showDecks, setShowDecks] = useState(true);
  const [showAddCards, setShowAddCards] = useState(false);
  const [newUnitQty, setNewUnitQty] = useState(0);
  const [deckUnitsChangeFlag, setDeckUnitsChangeFlag] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  // const [deckUnits, setDeckUnits] = useState([]);

  const [decks, setDecks] = useState([]);

  useEffect(() => {
    fetchDecks(setLoading, setDecks);
    // fetchUnits(
    //   setLoading,
    //   nextPage,
    //   setNextPage,
    //   units,
    //   setUnits,
    //   handleSetAlert,
    //   filtersChangeFlag,
    //   setFiltersChangeFlag,
    //   filtersObj,
    //   sortBy,
    //   sortByChange,
    //   setSortByChange
    // );
    // console.log("check db top level");
  }, [
    filtersObj,
    filtersChangeFlag,
    sortBy,
    sortByChange,
    currentDeckUnits,
    decks,
  ]);

  // const handleLoadMoreUnits = () => {
  //   fetchUnits(
  //     setLoading,
  //     nextPage,
  //     setNextPage,
  //     units,
  //     setUnits,
  //     handleSetAlert,
  //     false,
  //     setFiltersChangeFlag,
  //     filtersObj,
  //     sortBy,
  //     false,
  //     setSortByChange
  //   );
  // };

  // const observer = useRef();
  // const lastUnitRef = useCallback(
  //   (node) => {
  //     if (loading) return;
  //     if (observer.current) observer.current.disconnect();
  //     observer.current = new IntersectionObserver((entries) => {
  //       if (entries[0].isIntersecting && hasMore == true) {
  //         handleLoadMoreUnits();
  //       }
  //     });
  //     if (node) observer.current.observe(node);
  //   },
  //   [loading, hasMore, units]
  // );

  // const handleUpdateHasUnit = (unitID, unitQty) => {
  //   const updUnits = [...units];
  //   const unitIndex = _.findIndex(updUnits, { id: parseInt(unitID) });
  //   updUnits[unitIndex].collectionUnitQty = unitQty;
  //   setUnits(updUnits);
  // };

  const handleDeckUnits = async (id) => {
    fetchDeckUnits(setLoading, id, setCurrentDeckUnits);
  };

  const handleToggleDeckView = async (id) => {
    if (deckViewToggle) {
      setDeckViewToggle(false);
    } else {
      handleDeckUnits(id);
      setDeckViewToggle(true);
    }
  };

  const handleDeckClick = () => {
    console.log("test");
  };

  const handleDeleteDeck = (deckid) => {
    delDeck("pokemon", deckid);
  };

  const openModal = (unit) => {
    setCurrentUnit(unit);
    console.log(unit.name + " clicked");
    setToggleModal("show");
  };

  const closeModal = () => {
    console.log("closing modal");
    setToggleModal("hide");
  };

  return (
    <div>
      <CustModal
        unit={currentUnit}
        modalRef={toggleModal}
        hideModal={closeModal}
        handleSetAlert={handleSetAlert}
        // handleUpdateHasUnit={handleUpdateHasUnit}
      />
      <div className="deck-builder-container">
        <DBDecks
          decks={decks}
          setDecks={setDecks}
          selectMode={selectMode}
          setSelectMode={setSelectMode}
          setCardAddList={setCardAddList}
          selectIndicator={selectIndicator}
          setSelectIndicator={setSelectIndicator}
          handleDeckClick={handleDeckClick}
          loading={loading}
          setLoading={setLoading}
          deckViewToggle={deckViewToggle}
          handleToggleDeckView={handleToggleDeckView}
          currentDeckUnits={currentDeckUnits}
          setCurrentDeckUnits={setCurrentDeckUnits}
          currentDeckID={currentDeckID}
          currentDeckName={currentDeckName}
          setCurrentDeckID={setCurrentDeckID}
          setCurrentDeckName={setCurrentDeckName}
          handleDeckUnits={handleDeckUnits}
          showDecks={showDecks}
          setShowDecks={setShowDecks}
          handleDeleteDeck={handleDeleteDeck}
        />
        <DBDeckView
          openModal={openModal}
          selectMode={selectMode}
          setSelectMode={setSelectMode}
          cardAddList={cardAddList}
          setCardAddList={setCardAddList}
          currentDeckID={currentDeckID}
          setCurrentDeckID={setCurrentDeckID}
          currentDeckUnits={currentDeckUnits}
          setCurrentDeckUnits={setCurrentDeckUnits}
          currentDeckName={currentDeckName}
          setCurrentDeckName={setCurrentDeckName}
          handleDeleteDeck={handleDeleteDeck}
          setDeckViewToggle={setDeckViewToggle}
          setShowDecks={setShowDecks}
          setShowAddCards={setShowAddCards}
          newUnitQty={newUnitQty}
          setNewUnitQty={setNewUnitQty}
          deckUnitsChangeFlag={deckUnitsChangeFlag}
        />
        <DBCards
          openModal={openModal}
          setLoading={setLoading}
          loading={loading}
          // handleUpdateHasUnit={handleUpdateHasUnit}
          handleSetAlert={handleSetAlert}
          filtersChangeFlag={filtersChangeFlag}
          setFiltersChangeFlag={setFiltersChangeFlag}
          filtersObj={filtersObj}
          sortBy={sortBy}
          sortByChange={sortByChange}
          setSortByChange={setSortByChange}
          selectMode={selectMode}
          setSelectMode={setSelectMode}
          cardAddList={cardAddList}
          setCardAddList={setCardAddList}
          currentDeckName={currentDeckName}
          currentDeckID={currentDeckID}
          currentDeckUnits={currentDeckUnits}
          setCurrentDeckUnits={setCurrentDeckUnits}
          showAddCards={showAddCards}
          setShowAddCards={setShowAddCards}
          setNewUnitQty={setNewUnitQty}
          setDeckUnitsChangeFlag={setDeckUnitsChangeFlag}
          deckUnitsChangeFlag={deckUnitsChangeFlag}
          setSearchTerm={setSearchTerm}
          searchTerm={searchTerm}
        />
      </div>
    </div>
  );
};

export default DeckBuilder;
