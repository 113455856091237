import React, { Component } from 'react';

const TrendModule = (props) => {
    const { user, unit } = props;
    return ( 
        <div className="trend-mod-container">
            <h1>{unit.name}</h1>
            <div className="trend-details-container">
                <div className="trend-change trend-data">Change</div>
                <div className="trend-game trend-data">Game</div>
                <div className="trend-current-price trend-data">Price</div>
                <div className="trend-unit-pic trend-data">Unit Pic</div>
            </div>
            <h2>See More...</h2>
        </div>
     );
}
 
export default TrendModule;