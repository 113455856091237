import React, { useEffect, useState } from "react";
import { fade, makeStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
// import Typography from "@material-ui/core/Typography";
import InputBase from "@material-ui/core/InputBase";
import Badge from "@material-ui/core/Badge";
import MenuItem from "@material-ui/core/MenuItem";
import Menu from "@material-ui/core/Menu";
import MenuIcon from "@material-ui/icons/Menu";
import SearchIcon from "@material-ui/icons/Search";
// import AccountCircle from "@material-ui/icons/AccountCircle";
import {
  FiMail,
  FiBell,
  FiUser,
  FiHome,
  FiMoreVertical,
  FiLogIn,
  FiLogOut,
} from "react-icons/fi";
import { BsFillHouseDoorFill, BsGear } from "react-icons/bs";
import { HiCollection } from "react-icons/hi";
import { AiOutlineUserAdd } from "react-icons/ai";
import { FaWallet, FaMoon, FaSun } from "react-icons/fa";
// import { RiHome2Fill } from "react-icons/ri";
// import NotificationsIcon from "@material-ui/icons/Notifications";
import MoreIcon from "@material-ui/icons/MoreVert";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import { authCheck, modCheck } from "../../services/auth";
import DClogo from "../../img/dc_logoV2.png";
// import { Redirect } from "react-router-dom";
import { userInfo } from "os";
import { locale } from "moment";
import SearchAutocomplete from "./search-autocomplete";
import CustModal from "../shared/common/modal";

import { insCollectionUnit, delCollectionUnit } from "../shared/api";

const useStyles = makeStyles((theme) => ({
  grow: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    display: "none",
    [theme.breakpoints.up("sm")]: {
      display: "block",
    },
  },
  search: {
    "position": "relative",
    "borderRadius": theme.shape.borderRadius,
    "backgroundColor": fade(theme.palette.common.white, 0.15),
    "&:hover": {
      backgroundColor: fade(theme.palette.common.white, 0.25),
    },
    "marginRight": theme.spacing(2),
    "marginLeft": 0,
    "width": "100%",
    [theme.breakpoints.up("sm")]: {
      marginLeft: theme.spacing(3),
      width: "auto",
    },
  },
  searchIcon: {
    padding: theme.spacing(0, 2),
    height: "100%",
    position: "absolute",
    pointerEvents: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  inputRoot: {
    color: "inherit",
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("md")]: {
      width: "20ch",
    },
  },
  sectionDesktop: {
    display: "none",
    [theme.breakpoints.up("md")]: {
      display: "flex",
    },
  },
  sectionMobile: {
    display: "flex",
    [theme.breakpoints.up("md")]: {
      display: "none",
    },
  },
}));

export default function PrimarySearchAppBar(props) {
  const classes = useStyles();
  const { handleMenuOpen, user, notficationCount, inboxCount, handleSetAlert } =
    props;
  const [anchorEl, setAnchorEl] = useState(null);
  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = useState(null);
  const isAuth = authCheck();
  const [activePage, setActivePage] = useState("");
  const [toggleFlag, setFlag] = useState(false);
  const [modeRef, setModeRef] = useState("light");
  const [toggleModal, setToggleModal] = useState("hide");
  const [currentUnit, setCurrentUnit] = useState();

  // const isMod = modCheck(user.groupIDs);

  const isMenuOpen = Boolean(anchorEl);
  const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);

  // const handleKeypress = (e) => {
  //   //it triggers by pressing the enter key
  //   if (e.key === "Enter" && search !== "") {
  //     const query = {
  //       name: search,
  //     };
  //     const qString = qs.stringify(query);
  //     // TODO: Find better way to redirect
  //     // return <Redirect to={`/search?${qString}`} />;
  //     window.location.href = `/search?${qString}`;
  //   }
  // };

  useEffect(() => {
    const pathname = window.location.pathname;

    setActivePage(pathname);
  }, []);

  const toggleSearchClass = () => {
    console.log("toggle");
    if (toggleFlag) {
      setFlag(false);
    } else {
      setFlag(true);
    }
  };

  const openModal = (unit) => {
    setCurrentUnit(unit);
    console.log(unit.name + " clicked");
    setToggleModal("show");
  };

  const closeModal = () => {
    console.log("closing modal");
    setToggleModal("hide");
  };

  const handleProfileMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMobileMenuClose = () => {
    setMobileMoreAnchorEl(null);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    handleMobileMenuClose();
  };

  const handleMobileMenuOpen = (event) => {
    setMobileMoreAnchorEl(event.currentTarget);
  };

  // const handleCollectionAdd = async (e) => {
  //   try {
  //     const idArray = e.currentTarget.id.split("-");
  //     const unitID = idArray[idArray.length - 1];
  //     const { qty } = await insCollectionUnit(unitID);
  //   } catch (err) {
  //     if (err.response) {
  //       handleSetAlert("warning", err.response.data.message);
  //     } else {
  //       handleSetAlert("error", err.message);
  //     }
  //   }
  // };

  const setDark = () => {
    localStorage.setItem("theme", "dark");
    document.documentElement.setAttribute("data-theme", "dark");
  };

  const setLight = () => {
    localStorage.setItem("theme", "light");
    document.documentElement.setAttribute("data-theme", "light");
  };

  const handleModeChange = () => {
    console.log("todo");
  };

  // const handlePopupClick = () => {
  //   handleMenuClose();
  // }

  const handleMenuClick = ({ currentTarget }) => {
    handleMenuClose();
    const route = `/${currentTarget.getAttribute("name")}`;
    window.location = route;
    setActivePage(currentTarget.getAttribute("name"));
  };

  const menuId = "primary-search-account-menu";
  const renderMenu = (
    <Menu
      anchorEl={anchorEl}
      className="nav-popup-menu"
      anchorOrigin={{ vertical: "top", horizontal: "right" }}
      id={menuId}
      keepMounted
      transformOrigin={{ vertical: "top", horizontal: "right" }}
      open={isMenuOpen}
      onClose={handleMenuClose}
    >
      {isAuth && (
        <MenuItem onClick={handleMenuClick} name="usersettings">
          <BsGear /> &nbsp; Settings
        </MenuItem>
      )}
      {/* <MenuItem onClick={handleModeChange} name="usersettings">
        {modeRef == "dark" ? <FaSun /> : <FaMoon />} &nbsp;{" "}
        {modeRef == "dark" ? "Light Mode" : "Dark Mode"}
      </MenuItem> */}
      {/* <MenuItem onClick={handleMenuClick}>Dark Mode</MenuItem> */}
      {isAuth && (
        <MenuItem onClick={handleMenuClick} name="logout">
          <FiLogOut /> &nbsp; Sign Out
        </MenuItem>
      )}
      {!isAuth && (
        <MenuItem onClick={handleMenuClick} name="login">
          <FiLogIn /> &nbsp; Sign In
        </MenuItem>
      )}
      {/* TODO: Alpha */}
      {/* {!isAuth && (
        <MenuItem onClick={handleMenuClick} name="register">
          <AiOutlineUserAdd /> &nbsp; Register
        </MenuItem>
      )} */}
    </Menu>
  );

  const mobileMenuId = "primary-search-account-menu-mobile";
  // const renderMobileMenu = (
  //   <Menu
  //     anchorEl={mobileMoreAnchorEl}
  //     anchorOrigin={{ vertical: "top", horizontal: "right" }}
  //     id={mobileMenuId}
  //     keepMounted
  //     transformOrigin={{ vertical: "top", horizontal: "right" }}
  //     open={isMobileMenuOpen}
  //     onClose={handleMobileMenuClose}
  //   >
  //     <MenuItem>
  //       <IconButton aria-label="show 4 new mails" color="inherit">
  //         <Badge badgeContent={4} color="secondary">
  //           <FiMail />
  //         </Badge>
  //       </IconButton>
  //       <p>Messages</p>
  //     </MenuItem>
  //     <MenuItem>
  //       <IconButton aria-label="show 11 new notifications" color="inherit">
  //         <Badge badgeContent={11} color="secondary">
  //           <FiBell />
  //         </Badge>
  //       </IconButton>
  //       <p>Notifications</p>
  //     </MenuItem>
  //   </Menu>
  // );

  return (
    <React.Fragment>
      <CustModal
        unit={currentUnit}
        modalRef={toggleModal}
        hideModal={closeModal}
        handleSetAlert={handleSetAlert}
      />
      <div className={classes.grow + " navBarContainer desktopNav"}>
        <AppBar className="navBar" position="static">
          <Toolbar className="navnav">
            <IconButton
              edge="start"
              className={classes.menuButton + " hamburgerBtn"}
              color="inherit"
              aria-label="open drawer"
              onClick={handleMenuOpen}
            >
              <MenuIcon />
            </IconButton>
            <img src={DClogo} alt="" />
            {/* <h3>DC</h3> */}
            <div className="nav-container">
              <h1 className="navUname">{user.username}</h1>
              {/* <div
              className={
                toggleFlag
                  ? classes.search + " searchBox focused"
                  : classes.search + " searchBox"
              }
            >
              <div className={classes.searchIcon}>
                <SearchIcon />
              </div>
              <InputBase
                placeholder="Search Displaychase..."
                classes={{
                  root: classes.inputRoot,
                  input: classes.inputInput,
                }}
                className={"searchPlaceholder"}
                inputProps={{ "aria-label": "search" }}
                onKeyPress={(e) => handleKeypress(e)}
                onFocus={toggleSearchClass}
                onBlur={toggleSearchClass}
                onChange={(e) => setSearch(e.target.value)}
              />
            </div> */}
              <SearchAutocomplete
                classAppend={"nav-searchbox"}
                placeholder={"Search Displaychase..."}
                openModal={openModal}
              />
            </div>
            <div className={classes.grow} />
            <div className="mainBtns">
              <IconButton
                className={
                  activePage === "/" ? "activeMainBtn mainBtn" : "mainBtn"
                }
                color="inherit"
                onClick={handleMenuClick}
                name=""
              >
                <Badge>
                  <BsFillHouseDoorFill className="homeBtn" />
                </Badge>
              </IconButton>
              {isAuth && (
                <React.Fragment>
                  <IconButton
                    className={
                      activePage === "/collection"
                        ? "activeMainBtn mainBtn"
                        : "mainBtn"
                    }
                    color="inherit"
                    onClick={handleMenuClick}
                    name="collection"
                  >
                    <Badge>
                      <HiCollection />
                    </Badge>
                  </IconButton>
                  <IconButton
                    className={
                      activePage === "/nft"
                        ? "activeMainBtn mainBtn"
                        : "mainBtn"
                    }
                    color="inherit"
                    onClick={handleMenuClick}
                    name="nft"
                  >
                    <svg className="nft-nav-logo">
                      <path
                        d="M58.43,0L0,33.73V101.2l58.43,33.73,58.43-33.73V33.73
                      L58.43,0Zm-15.73,93.59h-12.19l-7.08-23.87
                      c-.23-.69-.38-1.3-.66-2.76h-.28c.23,2.92,.28,3.76,.28,4.68
                      v21.95H11.66V42.4h13.59l4.64,15.73c.38,1.23,.7,2.61,1.69,7.37
                      h.23c-.19-2.69-.23-3.68-.23-5.3v-17.81h11.11v51.19Zm31.78-34.38
                      h-13.69v5.53h13.69v14.35h-13.69v14.51h-11.77V42.4h25.45v16.81
                      Zm32.25,.69h-8.16v33.69h-12.19V59.9h-7.97v-17.5h28.31v17.5Z"
                      />
                    </svg>
                  </IconButton>
                </React.Fragment>
              )}
            </div>
            <div className={classes.sectionDesktop + " navIcons"}>
              {/* <IconButton className="navBarButton" aria-label="show 4 new mails">
              <Badge badgeContent={inboxCount}>
                <FiMail className="navIcon mailIcon" />
              </Badge>
            </IconButton>
            <IconButton className="navBarButton" aria-label="show 17 new notifications" color="inherit">
              <Badge badgeContent={notficationCount}>
                <FiBell className="navIcon bellIcon" />
              </Badge>
            </IconButton> */}
              <IconButton
                className="navBarButton moreIcon"
                aria-label="show 17 new notifications"
                color="inherit"
                onClick={handleProfileMenuOpen}
              >
                <FiMoreVertical className="navIcon" />
              </IconButton>
              {/* <IconButton
              className="navBarButton"
              edge="end"
              aria-label="account of current user"
              aria-controls={menuId}
              aria-haspopup="true"
              onClick={handleProfileMenuOpen}
              color="inherit"
            >
              <FiUser className="navIcon userIcon" />
            </IconButton> */}
            </div>
            {isAuth && (
              <div className={classes.sectionMobile}>
                <IconButton
                  aria-label="show more"
                  aria-controls={mobileMenuId}
                  aria-haspopup="true"
                  onClick={handleMobileMenuOpen}
                  color="inherit"
                >
                  <Badge badgeContent={15} color="secondary">
                    <MoreIcon />
                  </Badge>
                </IconButton>
              </div>
            )}
            {!isAuth && (
              <div className={classes.sectionMobile}>
                <IconButton
                  aria-label="show more"
                  aria-controls={mobileMenuId}
                  aria-haspopup="true"
                  onClick={handleMenuClick}
                  color="inherit"
                  name="login"
                >
                  <ExitToAppIcon />
                </IconButton>
              </div>
            )}
          </Toolbar>
        </AppBar>
        {/* {renderMobileMenu} */}
        {renderMenu}
      </div>
    </React.Fragment>
  );
}
