import React, { useState, useEffect } from "react";
import config from "../../../config";
import Moment from "moment";
// import { Button } from "@material-ui/core";
import Button from "../../shared/common/button";
import { insCollectionUnit, delCollectionUnit } from "../../collections/api";

const CustModal = (props) => {
  const { unit, modalRef, hideModal, handleSetAlert, handleUpdateHasUnit } =
    props;
  const [loading, setLoading] = useState(false);
  const [unitCountRef, setUnitCountRef] = useState(0);
  const [units, setUnits] = useState();

  useEffect(() => {
    if (unit) {
      const unitQty = unit.collectionUnitQty;
      setUnitCountRef(unitQty);
    }
  }, [unit]);

  const handleCollectionAdd = async (e) => {
    try {
      const idArray = e.currentTarget.id.split("-");
      const unitID = idArray[idArray.length - 1];
      const { qty } = await insCollectionUnit(unitID);
      unit.collectionUnitQty = qty;
      setUnitCountRef(qty);
      // handleUpdateHasUnit(unitID, qty);
    } catch (err) {
      if (err.response) {
        handleSetAlert("warning", err.response.data.message);
      } else {
        handleSetAlert("error", err.message);
      }
    }
  };

  const handleCollectionRemove = async (e) => {
    try {
      const idArray = e.currentTarget.id.split("-");
      const unitID = idArray[idArray.length - 1];
      const { qty } = await delCollectionUnit(unitID);
      unit.collectionUnitQty = qty;
      setUnitCountRef(qty);
      // handleUpdateHasUnit(unitID, qty);
    } catch (err) {
      if (err.response) {
        handleSetAlert("warning", err.response.data.message);
      } else {
        handleSetAlert("error", err.message);
      }
    }
  };

  return (
    <React.Fragment>
      <div
        className={"modalBG " + modalRef}
        onClick={() => {
          hideModal();
          setUnitCountRef(0);
        }}
      ></div>
      <div className={"modal-details-container " + modalRef}>
        {unit && (
          <>
            {unit && (
              <div className="img-container">
                <div className="img-center">
                  <img
                    src={unit.images.large ? `${unit.images.large}` : ""}
                    alt={unit.name}
                  />
                </div>
                <div className="modal-btn-container">
                  <Button
                    classAppend={
                      unit.collectionUnitQty > 0
                        ? "collection-remove"
                        : "collection-remove disable-remove"
                    }
                    idAppend={unit.id}
                    handleClick={
                      unit.collectionUnitQty > 0 ? handleCollectionRemove : ""
                    }
                    text={"-"}
                  />
                  <span className="modal-qty">
                    {unitCountRef > 0 ? unitCountRef : unit.collectionUnitQty}
                  </span>
                  <Button
                    classAppend="collection-add"
                    idAppend={unit.id}
                    handleClick={handleCollectionAdd}
                    text={"+"}
                  />
                </div>
              </div>
            )}
            <div className="modal-metadata">
              <h1 className="unit-title">{unit.cleanName || unit.name}</h1>
              <>
                <ul>
                  {unit.attributes && (
                    <>
                      {Object.keys(unit.attributes).map((attribute, i) => (
                        <React.Fragment>
                          {unit.attributes[attribute].name !== "OracleText" &&
                            unit.attributes[attribute].name !== "FlavorText" &&
                            unit.attributes[attribute].name !== "CardText" &&
                            unit.attributes[attribute].name !== "description" &&
                            !unit.attributes[attribute].name.includes(
                              "Attack"
                            ) && (
                              <li key={i}>
                                {unit.attributes[attribute].displayName}:{" "}
                                <b> {unit.attributes[attribute].value}</b>
                              </li>
                            )}
                        </React.Fragment>
                      ))}
                    </>
                  )}
                  {unit.prices && unit.prices[0] && (
                    <>
                      <li>
                        Market Price:{" "}
                        <b>${unit.prices[0].market.toFixed(2)} (USD)</b>
                      </li>
                    </>
                  )}
                  {unit.prices && !unit.prices[0] && (
                    <li>
                      <b>Still Gathering Pricing Data</b>
                    </li>
                  )}
                  {unit.collectionUnitQty > 0 &&
                    unit.prices &&
                    unit.prices[0] && (
                      <li>
                        Your Pot:{" "}
                        <b>
                          $
                          {(
                            parseInt(unitCountRef) *
                            parseFloat(unit.prices[0].market)
                          ).toFixed(2)}{" "}
                          (USD)
                        </b>
                      </li>
                    )}
                </ul>
              </>
            </div>
          </>
        )}
      </div>
    </React.Fragment>
  );
};

export default CustModal;
