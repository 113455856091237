import React from "react";
import DateFnsUtils from "@date-io/date-fns";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";

const CommonDatePicker = (props) => {
  const {
    classAppend,
    idAppend,
    variant,
    label,
    value,
    handleChange,
    disableToolbar,
    format,
    margin,
    KeyboardButtonProps,
  } = props;
  const className = classAppend
    ? `cmn-date-pick ${classAppend}`
    : "cmn-date-pick";
  const id = idAppend ? `cmn-date-pick-id-${idAppend}` : "cmn-date-pick-id";
  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <KeyboardDatePicker
        className={className}
        id={id}
        variant={variant}
        format={format || "MM/dd/yyyy"}
        margin={margin || "normal"}
        label={label}
        value={value}
        onChange={handleChange}
        disableToolbar={disableToolbar}
        KeyboardButtonProps={KeyboardButtonProps}
      />
    </MuiPickersUtilsProvider>
  );
};

export default CommonDatePicker;
