import http from "../../services/http";
import config from "../../config";

export const getUserCustomerID = () => {
  return new Promise(async (resolve, reject) => {
    try {
      const getCustomerIDUrl = `${config.apiUrl}/users/subscriptions/customer`;
      const { data } = await http.get(getCustomerIDUrl);
      resolve(data);
    } catch (err) {
      reject(err);
    }
  });
};

export const insSubscription = (stripePaymentMethodID, handleSetUser) => {
  return new Promise(async (resolve, reject) => {
    try {
      const body = {
        stripePaymentMethodID,
      };
      const insSubscriptionUrl = `${config.apiUrl}/users/subscriptions`;
      const { headers } = await http.post(insSubscriptionUrl, body);
      const authToken = headers[config.authHeader];
      handleSetUser(authToken);
      resolve(true);
    } catch (err) {
      reject(err);
    }
  });
};

export const delSubscription = (handleSetUser) => {
  return new Promise(async (resolve, reject) => {
    try {
      const insSubscriptionUrl = `${config.apiUrl}/users/subscriptions`;
      const { headers } = await http.delete(insSubscriptionUrl);
      const authToken = headers[config.authHeader];
      handleSetUser(authToken);
      resolve(true);
    } catch (err) {
      reject(err);
    }
  });
};
