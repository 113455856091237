import * as React from "react";
import { useState, useEffect } from "react";
import MenuItem from "@mui/material/MenuItem";
import Button from "../../shared/common/button";
import { HiCollection } from "react-icons/hi";
import { IoGrid } from "react-icons/io5";
import { AiOutlineMenu, AiFillCaretUp, AiFillCaretDown } from "react-icons/ai";
import { FiMoreVertical } from "react-icons/fi";
import { BiAddToQueue } from "react-icons/bi";
import { headerStyles } from "../styles";
import { Autocomplete } from "@material-ui/lab";
import { TextField } from "@material-ui/core";
import Menu from "@material-ui/core/Menu";
import IconButton from "@material-ui/core/IconButton";
import { FilterList } from "@material-ui/icons";

const useStyles = headerStyles;

const Header = (props) => {
  const {
    handleSetAlert,
    filtersObj,
    setFiltersObj,
    filtersChangeFlag,
    setFiltersChangeFlag,
    gameFilter,
    setGameFilter,
    sortBy,
    setSortBy,
    setShowBulkAdd,
  } = props;
  const classes = useStyles();
  const [toggleFlag, setFlag] = useState(false);
  const [loading, setLoading] = useState(false);
  const [activeView, setActiveView] = useState("grid");
  const [filterSets, setFilterSets] = useState([]);
  const [filterRarities, setFilterRarities] = useState([]);
  const [showFilters, setShowFilters] = useState("inactive");
  const [showSorts, setShowSorts] = useState("inactive");
  const [activeTab, setActiveTab] = useState("1");
  const [sortRef, setSortRef] = useState("A-Z");
  // const [activeFilter, setActiveFilter] = useState("1");
  const [priceLow, setPriceLow] = useState("");
  const [priceHigh, setPriceHigh] = useState("");
  const [filters, setFilters] = useState([]);
  const [gameVal, setGameVal] = useState();
  const [anchorEl, setAnchorEl] = useState(null);
  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = useState(null);

  const gameFilters = [
    {
      name: "All Catgories",
      code: "All",
    },
    {
      name: "Magic, The Gathering",
      code: "MTG",
    },
    {
      name: "Pokemon TCG",
      code: "PM",
    },
    {
      name: "Board Games",
      code: "BG",
    },
  ];

  const sortsList = [
    {
      text: "A-Z",
      value: "ProductName ASC",
      btnRef: "A-Z",
    },
    {
      text: "Price: High to Low",
      value: "MinPrice DESC",
      btnRef: "$ High-Low",
    },
    {
      text: "Price: Low to High",
      value: "MinPrice ASC",
      btnRef: "$ Low-High",
    },
    {
      text: "Quantity",
      value: "Qty",
      btnRef: "Quantity",
    },
  ];

  const isMenuOpen = Boolean(anchorEl);

  useEffect(() => {
    setGameFilter("All");
    setSortBy("ProductName ASC");
  }, [activeView]);

  const toggleSearchClass = () => {
    console.log("toggle");
    if (toggleFlag) {
      setFlag(false);
    } else {
      setFlag(true);
    }
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClick2 = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  const open2 = Boolean(anchorEl);
  const id2 = open2 ? "simple-popover" : undefined;

  const handleChangeSort = (sort) => {
    console.log(sort.value);
    setSortBy(sort.value);
    setSortRef(sort.btnRef);
    toggleSorts();
  };

  const handleChangeFilter = (filter) => {
    setGameFilter(filter.code);
    toggleFilters();
  };

  const toggleView = (e) => {
    if (e.currentTarget.id == "cmn-btn-id-table" && activeView == "grid") {
      setActiveView("table");
      {
        props.setView("table");
      }
    } else if (
      e.currentTarget.id == "cmn-btn-id-grid" &&
      activeView == "table"
    ) {
      setActiveView("grid");
      {
        props.setView("grid");
      }
    }
  };

  const toggleFilters = () => {
    if (showFilters == "inactive") {
      setShowFilters("active");
      setShowSorts("inactive");
    } else {
      setShowFilters("inactive");
      setShowSorts("inactive");
    }
  };

  const toggleSorts = () => {
    if (showSorts == "inactive") {
      setShowSorts("active");
      setShowFilters("inactive");
    } else {
      // setFiltersObj(filters)
      // setFiltersChangeFlag(true)
      setShowSorts("inactive");
      setShowFilters("inactive");
    }
  };

  const toggleBulkAdd = () => {
    handleMenuClose();
    setShowBulkAdd(true);
  };

  const handleMoreMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMobileMenuOpen = (event) => {
    setMobileMoreAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    // handleMobileMenuClose();
  };

  const menuId = "primary-search-account-menu";
  const mobileMenuId = "primary-search-account-menu-mobile";

  const renderMenu = (
    <Menu
      anchorEl={anchorEl}
      className="header-popup-menu"
      anchorOrigin={{ vertical: "top", horizontal: "right" }}
      id={menuId}
      keepMounted
      transformOrigin={{ vertical: "top", horizontal: "right" }}
      open={isMenuOpen}
      onClose={handleMenuClose}
    >
      <MenuItem
        name="bulk-add-menu-btn"
        className="bulk-add-menu-btn"
        onClick={toggleBulkAdd}
      >
        <BiAddToQueue /> &nbsp; Bulk Add
      </MenuItem>
    </Menu>
  );

  return (
    <React.Fragment>
      <div className={"collHeader " + props.headerClasses}>
        <HiCollection className="collLogo" />
        <div className="head-btn-container">
          {/* <button
            className={showSorts ? "sort-btn" : "sort-btn active-sort-btn"}
            onClick={toggleSorts}
          >
            {sortRef + " "}
            {showSorts == "inactive" ? <AiFillCaretUp /> : <AiFillCaretDown />}
          </button> */}

          <button
            className={
              gameFilter != "All"
                ? "filters-btn"
                : "filters-btn active-filters-btn"
            }
            onClick={toggleFilters}
          >
            {gameFilter}{" "}
            {showFilters == "inactive" ? (
              <AiFillCaretUp />
            ) : (
              <AiFillCaretDown />
            )}
          </button>
        </div>
        <div
          className={
            showSorts == "active" ? "show sorts-container coll-sorts" : "hide"
          }
        >
          {sortsList.map((sort, index) => (
            <Button
              key={index}
              variant="none"
              size="small"
              color="primary"
              classAppend={
                sortBy == sort.value ? "sortby-btn active" : "sortby-btn"
              }
              handleClick={() => {
                handleChangeSort(sort);
              }}
              text={sort.text}
            />
          ))}
        </div>
        <div
          className={
            showFilters == "active"
              ? "show filters-container coll-filters-container"
              : "hide"
          }
        >
          {gameFilters.map((filter, index) => (
            <Button
              key={index}
              variant="none"
              size="small"
              color="primary"
              classAppend={
                gameFilter == filter.code
                  ? "filterby-btn active"
                  : "filterby-btn"
              }
              handleClick={() => {
                handleChangeFilter(filter);
              }}
              text={filter.name}
            />
          ))}
          {/* <Autocomplete
            // disablePortal
            id="auto-filters-set-mtg"
            className="textInput filterInput"
            options={gameFilters}
            getOptionLabel={(option) => option.name}
            style={{ width: 300 }}
            renderInput={(params) => (
              <TextField {...params} label="Category" variant="outlined" />
            )}
            onChange={(e, nv) => setGameVal(nv.code)}
          />
          <br />
          <button
            onClick={clearFilters}
            className="clear-btn filter-action-btn"
          >
            Clear All
          </button>
          <button onClick={applyFilters} className="done-btn filter-action-btn">
            Apply
          </button> */}
        </div>
        <div className="view-btn-container">
          <div className="grid-btn-container">
            <Button
              variant="outlined"
              size="small"
              color="primary"
              idAppend="grid"
              text={<IoGrid />}
              classAppend={
                activeView == "grid" ? "grid-btn active-view-btn" : "grid-btn"
              }
              handleClick={toggleView}
            />
          </div>
          <div className="table-btn-container">
            <Button
              variant="outlined"
              size="small"
              color="primary"
              idAppend="table"
              text={<AiOutlineMenu />}
              classAppend={
                activeView == "table"
                  ? "table-btn active-view-btn"
                  : "table-btn"
              }
              handleClick={toggleView}
            />
          </div>
        </div>
        <div className={classes.sectionDesktop}>
          <IconButton
            className="moreIcon"
            aria-label="show 17 new notifications"
            color="inherit"
            onClick={handleMoreMenuOpen}
            disableRipple
          >
            <FiMoreVertical />
          </IconButton>
        </div>
      </div>
      {renderMenu}
    </React.Fragment>
  );
};

export default Header;
