import React, { useState } from "react";
import http from "../../../services/http";
import config from "../../../config";
import { Grid, Typography, TextField, Button } from "@material-ui/core";
import AccountBoxIcon from "@material-ui/icons/AccountBox";

const UpdateUsername = ({ handleSetUser, handleSetAlert }) => {
  const [newUsername, setNewUsername] = useState("");

  const handleUpdateUsername = async () => {
    try {
      const reqBody = {
        newUsername,
      };
      const reqUrl = `${config.apiUrl}/users/updateUsername`;
      const res = await http.put(reqUrl, reqBody);
      const authToken = res.headers[config.authHeader];
      handleSetUser(authToken);
      handleSetAlert("success", "Username successfully updated.");
    } catch (err) {
      console.log(err);
      handleSetAlert("error", err.response.data.message);
    }
  };

  return (
    <div className="update-username">
      <Grid
        container
        direction="column"
        justify="flex-start"
        alignItems="center"
        spacing={5}
      >
        <Grid
          container
          item
          direction="row"
          justify="flex-start"
          alignItems="center"
        >
          <Grid item xs={12}>
            <h2>
              <AccountBoxIcon className="settingsIcon" />
              Update Username
            </h2>
          </Grid>
        </Grid>
        <Grid
          container
          item
          direction="row"
          justify="space-evenly"
          className="settingsBox"
        >
          <TextField
            id="new-username"
            label="New Username"
            variant="outlined"
            onChange={(e) => setNewUsername(e.target.value)}
          />
        </Grid>
        <Grid
          container
          item
          direction="row"
          justify="space-evenly"
          className="settingsBox"
        >
          <Button className="settingsBtn" onClick={handleUpdateUsername}>
            Update Username
          </Button>
        </Grid>
      </Grid>
    </div>
  );
};

export default UpdateUsername;
