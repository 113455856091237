import {
  getUnitsByGame,
  getUnits,
  getFilters,
  getSorts,
  getFilteredUnits,
  getDecks,
  getDeckUnits,
  newDeck,
  getUnitData,
} from "../shared/api";

import { sortBy } from "lodash";

export const fetchUnits = async (
  setLoading,
  page,
  setNextPage,
  currentData,
  setData,
  handleSetAlert,
  filtersChangeFlag,
  setFiltersChangeFlag,
  filterObj,
  sortBy,
  sortByChange,
  setSortByChange
) => {
  try {
    setLoading(true);
    if (filtersChangeFlag || sortByChange) {
      const filterBod = {
        sort: sortBy,
        filters: filterObj,
      };
      const { data, nextPage } = await getUnitsByGame("MTG", 1, filterBod);
      setData([...data]);
      setNextPage(nextPage);
      setLoading(false);
      setFiltersChangeFlag(false);
      setSortByChange(false);
    } else {
      if (parseInt(page) == 1) {
        const filterBod = {
          sort: sortBy,
          filters: filterObj,
        };
        const { data, nextPage } = await getUnitsByGame("MTG", page, filterBod);
        setData([...data]);
        setNextPage(nextPage);
        setLoading(false);
        setFiltersChangeFlag(false);
      } else if (parseInt(page) >= 2) {
        const filterBod = {
          sort: sortBy,
          filters: filterObj,
        };
        const { data, nextPage } = await getUnitsByGame("MTG", page, filterBod);
        setData([...currentData, ...data]);
        setNextPage(nextPage);
        setLoading(false);
        setFiltersChangeFlag(false);
      }
    }
  } catch (err) {
    console.log("error...");
    setLoading(false);
    // handleSetAlert("error", "Error fetching Units");
  }
};

export const fetchDecks = async (setLoading, setData) => {
  try {
    setLoading(true);
    const { data } = await getDecks("MTG");
    setData([...data]);
    setLoading(false);
  } catch (err) {
    setLoading(false);
    console.log("error fetching decks");
    // handleSetAlert("error", "Error fetching Units");
  }
};

export const fetchUnitDetails = async (setData, unitID) => {
  try {
    const { data } = await getUnitData("MTG", unitID);
    setData([...data]);
  } catch (err) {
    console.log("error fetching decks");
    // handleSetAlert("error", "Error fetching Units");
  }
};

export const fetchDeckUnits = async (setLoading, deckID, setDeckUnits) => {
  try {
    const { data } = await getDeckUnits(deckID, "mtg");
    const sortedData = sortBy(data, "name");
    // console.log("DATA:", data);
    setDeckUnits(sortedData);
  } catch (err) {
    // handleSetAlert("error", "Error fetching Units");
  }
};

export const createNewDeck = async (
  currentData,
  deckName,
  setDecks,
  setLoading
) => {
  try {
    setLoading(true);
    const body = {
      name: deckName,
    };
    const { data } = await newDeck("mtg", body);
    setLoading(false);
  } catch (err) {
    console.log("error");
    // handleSetAlert("error", "Error fetching Units");
  }
};

export const fetchFiltersList = async (
  setLoading,
  handleSetAlert,
  setFilters
) => {
  try {
    const data = await getFilters("MTG");
    setFilters([...data]);
  } catch (err) {
    setLoading(false);
    handleSetAlert("error", "Error fetching Units");
  }
};

export const fetchSortsList = async (
  setLoading,
  handleSetAlert,
  setSortsList
) => {
  try {
    const data = await getSorts("MTG");
    setSortsList([...data]);
  } catch (err) {
    setLoading(false);
    handleSetAlert("error", "Error fetching Units");
  }
};
