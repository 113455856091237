import React, { useState, useEffect } from "react";
import { fetchSearchUnits } from "./controller";
import { Grid } from "@material-ui/core";
import CircleLoader from "../shared/common/loader-circle";
import * as _ from "lodash";
import Button from "../shared/common/button";
import UnitMod from "../shared/unit-module";
import CustModal from "../shared/common/modal";

const Units = (props) => {
  const { handleSetAlert, name } = props;
  const [loading, setLoading] = useState(false);
  const [nextLoading, setNextLoading] = useState(false);
  const [units, setUnits] = useState([]);
  const [nextPage, setNextPage] = useState(1);
  const [toggleModal, setToggleModal] = useState("hide");
  const [currentUnit, setCurrentUnit] = useState();

  useEffect(() => {
    fetchSearchUnits(
      setLoading,
      name,
      nextPage,
      setNextPage,
      units,
      setUnits,
      handleSetAlert
    );
  }, []);

  const handleLoadMoreUnits = () => {
    fetchSearchUnits(
      setNextLoading,
      name,
      nextPage,
      setNextPage,
      units,
      setUnits,
      handleSetAlert
    );
  };

  const handleUpdateHasUnit = (unitID, unitQty) => {
    const updUnits = [...units];
    const unitIndex = _.findIndex(updUnits, { id: parseInt(unitID) });
    updUnits[unitIndex].collectionUnitQty = unitQty;
    setUnits(updUnits);
  };

  const openModal = (unit) => {
    setCurrentUnit(unit);
    console.log(unit.name + " clicked");
    setToggleModal("show");
  };
  const closeModal = () => {
    console.log("closing modal");
    setToggleModal("hide");
  };

  return (
    <Grid container>
      <CustModal
        unit={currentUnit}
        modalRef={toggleModal}
        hideModal={closeModal}
        handleUpdateHasUnit={handleUpdateHasUnit}
        handleSetAlert={handleSetAlert}
      />
      <div className="mtg-units-container search-results">
        {!loading && (
          <div className="collectionUnitDiv collection-container">
            {units.map((unit, index) => {
              return (
                <div
                  className={
                    index + 1 === units.length
                      ? "unitContainer lastUnit"
                      : "unitContainer"
                  }
                >
                  <UnitMod
                    unit={unit}
                    id={unit.id}
                    name={unit.name}
                    handleUpdateHasUnit={handleUpdateHasUnit}
                    onModalToggle={() => openModal(unit)}
                  />
                </div>
              );
            })}
          </div>
        )}
        <CircleLoader loading={nextLoading} />
        {nextPage && (
          <Button
            handleClick={handleLoadMoreUnits}
            classAppend={"loadUnitsBtn"}
            text="Load More..."
          ></Button>
        )}
      </div>
    </Grid>
  );
};

export default Units;
