import React, { useState, useEffect, useRef, useCallback } from "react";
import { fetchUnits } from "./controller";
import { Grid } from "@material-ui/core";
import DotLoader from "../shared/common/loader-dot";
import * as _ from "lodash";
import Button from "../shared/common/button";
import UnitMod from "../shared/unit-module";
import CustModal from "./components/modal";
import DenseTable from "./components/table-view";

const MTGUnits = (props) => {
  const {
    handleSetAlert,
    filtersObj,
    setFiltersObj,
    filtersChangeFlag,
    setFiltersChangeFlag,
    sortBy,
    sortByChange,
    setSortByChange,
    setCards,
  } = props;
  const [loading, setLoading] = useState(false);
  const [units, setUnits] = useState([]);
  const [nextPage, setNextPage] = useState(1);
  const [hasMore, setHasMore] = useState(true);
  const [toggleModal, setToggleModal] = useState("hide");
  const [currentUnit, setCurrentUnit] = useState();
  const [activeTab, setActiveTab] = useState("Cards");

  useEffect(() => {
    fetchUnits(
      setLoading,
      nextPage,
      setNextPage,
      units,
      setUnits,
      handleSetAlert,
      filtersChangeFlag,
      setFiltersChangeFlag,
      filtersObj,
      sortBy,
      sortByChange,
      setSortByChange
    );
  }, [filtersObj, filtersChangeFlag, sortBy, sortByChange]);

  const handleLoadMoreUnits = () => {
    fetchUnits(
      setLoading,
      nextPage,
      setNextPage,
      units,
      setUnits,
      handleSetAlert,
      false,
      setFiltersChangeFlag,
      filtersObj,
      sortBy,
      false,
      setSortByChange
    );
  };

  const observer = useRef();
  const lastUnitRef = useCallback(
    (node) => {
      if (loading) return;
      if (observer.current) observer.current.disconnect();
      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting && hasMore == true) {
          handleLoadMoreUnits();
        }
      });
      if (node) observer.current.observe(node);
    },
    [loading, hasMore, units]
  );

  const handleUpdateHasUnit = (unitID, unitQty) => {
    const updUnits = [...units];
    const unitIndex = _.findIndex(updUnits, { id: parseInt(unitID) });
    updUnits[unitIndex].collectionUnitQty = unitQty;
    setUnits(updUnits);
  };

  const openModal = (unit) => {
    setCurrentUnit(unit);
    // setAttributes(unit.attributes)
    console.log(unit.name + " clicked");
    setToggleModal("show");
  };

  const closeModal = () => {
    console.log("closing modal");
    setToggleModal("hide");
  };

  return (
    <Grid container>
      {props.view == "table" && (
        <DenseTable
          className="mtgTable"
          loadMore={handleLoadMoreUnits}
          lastref={lastUnitRef}
          units={units}
          handleSetAlert={handleSetAlert}
          handleUpdateHasUnit={handleUpdateHasUnit}
        />
      )}
      {props.view == "grid" && (
        <>
          <CustModal
            unit={currentUnit}
            modalRef={toggleModal}
            hideModal={closeModal}
            handleSetAlert={handleSetAlert}
            handleUpdateHasUnit={handleUpdateHasUnit}
          />
          <div className="mtg-units-container">
            <div className="collectionUnitDiv collection-container">
              {units.map((unit, index) => {
                if (units.length - 40 === index) {
                  return (
                    <div
                      className="unitContainer lastUnit"
                      ref={lastUnitRef}
                      key={unit.id}
                    >
                      <UnitMod
                        unit={unit}
                        id={unit.id}
                        name={unit.cleanName}
                        handleUpdateHasUnit={handleUpdateHasUnit}
                        onModalToggle={() => openModal(unit)}
                      />
                    </div>
                  );
                } else {
                  return (
                    <div className="unitContainer" key={unit.id}>
                      <UnitMod
                        unit={unit}
                        id={unit.id}
                        name={unit.cleanName}
                        handleUpdateHasUnit={handleUpdateHasUnit}
                        onModalToggle={() => openModal(unit)}
                      />
                    </div>
                  );
                }
              })}
            </div>
          </div>
          <DotLoader
            classAppend={"load-anim-container"}
            margin={"5"}
            loading={loading}
          />
        </>
      )}
    </Grid>
  );
};

export default MTGUnits;
