import React, { useState, useEffect } from 'react';
import config from "../../../config";
import Moment from "moment";

const CustModal = (props) => {
    const [loading, setLoading] = useState(false);
    const [attributes, setAttributes] = useState();

    useEffect(() => {
        console.log(props.unit);
    }, []);

    return (
    <React.Fragment>
        <div className={"modalBG " + props.modalRef} 
            onClick={props.hideModal}>
        </div>
        <div className={"modal-details-container nft-modal " + props.modalRef}>
            {props.unit && (
            <>
                <div className="img-container">
                    <img src={
                        props.unit.imageLink
                        ? `${props.unit.imageLink}`
                        : ""}
                        alt={props.unit.name}
                    />
                </div>
                <div className="modal-metadata">
                    <b><h1 className="unit-title">{props.unit.name}</h1></b>
                    <ul>
                        {props.unit.attributes && 
                            <>
                            {Object.keys(props.unit.attributes).map((attribute, i) => (
                                <React.Fragment>
                                {props.unit.attributes[attribute].name && 
                                props.unit.attributes[attribute].value &&
                                    <li key={i}>
                                        {props.unit.attributes[attribute].name}: 
                                        <b> {props.unit.attributes[attribute].value}</b>
                                    </li>
                                }
                                </React.Fragment>
                            ))}
                            </>
                        }
                    </ul>
                        {/* {Object.keys(props.unit.attributes).map((item, i) => {
                            <>
                                <li className="nft-attribute" key={i}>
                                    {props.unit.attributes[item].name}: {props.unit.attributes[item].value}
                                </li>
                            </>
                        })}
                    </ul> */}
                </div>
            </>
            )}
        </div>
    </React.Fragment>
    );
}
 
export default CustModal;
