import React, { useState, useEffect } from "react";
import * as _ from "lodash";
import { fetchMyCollectionGames } from "./controller";
import DotLoader from "../shared/common/loader-dot";
import Tabs from "../shared/tabs";
import Sets from "../shared/sets";
import Button from "../shared/common/button";
import { FiMessageSquare } from "react-icons/fi";
import { BiDollar } from "react-icons/bi";
import CollectionView from "./collection-view";

const Main = (props) => {
  const { handleSetAlert, user } = props;
  const [loading, setLoading] = useState(false);
  // const [collection, setCollection] = useState([]);
  // const [collectionView, setCollectionView] = useState([]);
  // const [tabData, setTabData] = useState([{ name: "All" }]);

  // useEffect(() => {
  //   const gameTabs = collection.map((game) => {
  //     console.log(game.name);
  //     return {
  //       name: game.name,
  //     };
  //   });
  //   setTabData([{ name: "All" }, ...gameTabs]);
  //   if (collection.length > 0) {
  //     setActiveTab("1");
  //   }
  // }, [collection]);

  // useEffect(() => {
  //   console.log(activeTab);
  //   if (collection.length > 0) {
  //     if (activeTab === "1") {
  //       setCollectionView(collection);
  //     } else {
  //       console.log([collection[parseInt(activeTab) - 2]]);
  //       setCollectionView([collection[parseInt(activeTab) - 2]]);
  //     }
  //   }
  // }, [activeTab]);

  // const handleGameBtns((target)=>{
  //   if (target.text === "All"){
  //     setActiveTab(0)
  //   }
  //   else if (target.text === "All"){
  //     setActiveTab(0)
  //   }
  //   else if (target.text === "All"){
  //     setActiveTab(0)
  //   }
  // })

  return (
    <div className="profileContainer">
      <DotLoader loading={loading} />
      {!loading && (
        <>
          <div className="tabContainer">
            <div className="collection-page-container">
              <div className={"units-container"}>
                <CollectionView handleSetAlert={handleSetAlert} user={user} />
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default Main;
