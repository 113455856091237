import React, { useState, useEffect } from "react";
import MTGUnits from "./mtgUnits";
import config from "../../config";
import MTGHeader from "./components/header";
import Tabs from "../shared/tabs";
import splash from "../../img/splash.png";
import DeckBuilder from "./deck-builder";
import TabHead from "./components/tab-header";

const Main = (props) => {
  const { handleSetAlert } = props;
  const [filtersObj, setFiltersObj] = useState([]);
  const [filtersChangeFlag, setFiltersChangeFlag] = useState();
  const [sortBy, setSortBy] = useState("Sales DESC");
  const [sortByChange, setSortByChange] = useState();
  const [cards, setCards] = useState();

  const [view, setView] = useState();
  const [searchMTG, setSearchMTG] = useState("");
  const [activeTab, setActiveTab] = useState("Cards");

  useEffect(() => {
    if (!localStorage.getItem("viewPref")) {
      localStorage.setItem("viewPref", "grid");
      setView("grid");
    } else {
      setView(localStorage.getItem("viewPref"));
    }
  }, [view]);

  return (
    <div className="general-game-container">
      <TabHead setActiveTab={setActiveTab} activeTab={activeTab} />
      <MTGHeader
        game="MTG"
        gameLogo={`${config.mediaUrl}/dch/mtg/mtgLogo.png`}
        activeTab={activeTab}
        setActiveTab={setActiveTab}
        setFiltersObj={setFiltersObj}
        filtersObj={filtersObj}
        setView={setView}
        activeView={view}
        sortBy={sortBy}
        setSortBy={setSortBy}
        filtersChangeFlag={filtersChangeFlag}
        sortByChange={sortByChange}
        setSortByChange={setSortByChange}
        setFiltersChangeFlag={setFiltersChangeFlag}
        handleSetAlert={handleSetAlert}
      />
      <div className="tabContainer">
        {activeTab === "Cards" && (
          <div className="units-container">
            <MTGUnits
              view={view}
              filtersObj={filtersObj}
              handleSetAlert={handleSetAlert}
              filtersChangeFlag={filtersChangeFlag}
              setFiltersChangeFlag={setFiltersChangeFlag}
              sortBy={sortBy}
              sortByChange={sortByChange}
              setSortByChange={setSortByChange}
              setCards={setCards}
            />
          </div>
        )}
        {activeTab === "Deck Builder" && (
          <DeckBuilder
            view={view}
            filtersObj={filtersObj}
            handleSetAlert={handleSetAlert}
            filtersChangeFlag={filtersChangeFlag}
            setFiltersChangeFlag={setFiltersChangeFlag}
            sortBy={sortBy}
            sortByChange={sortByChange}
            setSortByChange={setSortByChange}
            setCards={setCards}
          />
        )}
      </div>
    </div>
  );
};

export default Main;
