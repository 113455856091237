import React, { useState, useEffect } from "react";
import { authCheck } from "../../services/auth";
import TrendModule from "./components/trend-mod";
import { BiTrendingUp, BiTrendingDown } from "react-icons/bi";
import { fetchMovers, fetchMyCollectionUnits } from "./controller";
import UnitMod from "../shared/unit-module";
import CustModal from "../shared/common/modal";
import * as _ from "lodash";

const Home = (props) => {
  const { user, handleSetAlert } = props;
  const isAuth = authCheck();
  const [loading, setLoading] = useState(false);
  const [gainers, setGainers] = useState([]);
  const [losers, setLosers] = useState([]);
  const [currentUnit, setCurrentUnit] = useState();
  const [toggleModal, setToggleModal] = useState("hide");
  const [units, setUnits] = useState([]);
  const [nextPage, setNextPage] = useState(1);

  useEffect(() => {
    fetchMovers(setLoading, setGainers, setLosers);
    fetchMyCollectionUnits(
      setLoading,
      nextPage,
      setNextPage,
      units,
      setUnits,
      handleSetAlert
    );
  }, []);

  const openModal = (unit) => {
    setCurrentUnit(unit);
    console.log(unit.name + " clicked");
    setToggleModal("show");
  };

  const closeModal = () => {
    console.log("closing modal");
    setToggleModal("hide");
  };

  return (
    <>
      <CustModal
        unit={currentUnit}
        modalRef={toggleModal}
        hideModal={closeModal}
        handleSetAlert={handleSetAlert}
      />
      {!loading && (
        <div className="home-page-container">
          <div className="trend-parent-container">
            <div className="dc-top-ten">
              <h1 className="home-title">
                Displaychase Hot 25 <BiTrendingUp color="green" />
              </h1>
              <div className="trend-container">
                {gainers.map((unit, index) => {
                  return (
                    <UnitMod
                      trendFlag={true}
                      trendDirection={"up"}
                      unit={unit}
                      units={units}
                      setUnits={setUnits}
                      onModalToggle={() => openModal(unit)}
                    />
                  );
                  // return <TrendModule user={user} unit={unit} />;
                })}
              </div>
            </div>
            <div className="dc-bottom-ten">
              <h1 className="home-title">
                Displaychase Not 25 <BiTrendingDown color="red" />
              </h1>
              <div className="trend-container">
                {losers.map((unit, index) => {
                  return (
                    <UnitMod
                      trendFlag={true}
                      trendDirection={"down"}
                      unit={unit}
                      units={units}
                      setUnits={setUnits}
                      onModalToggle={() => openModal(unit)}
                    />
                  );
                  // return <TrendModule user={user} unit={unit} />;
                })}
              </div>
            </div>
          </div>
        </div>
      )}

      {/* <div className="news-container">
        <h1 className="home-title">News</h1>
        
      </div> */}
    </>
  );
};

export default Home;
