import http from "../../services/http";
import config from "../../config";

export const fetchGames = () => {
  return new Promise(async (resolve, reject) => {
    try {
      const gamesUrl = `${config.apiUrl}/games`;
      const { data } = await http.get(gamesUrl);
      resolve(data);
    } catch (err) {
      reject(err);
    }
  });
};
