console.log(process.env["REACT_APP_DC_API_ENV"]);
const config = {
  apiUrl:
    process.env["REACT_APP_DC_API_ENV"] === "development"
      ? "http://localhost:4000/dc"
      : "https://api.circlecitycoders.com/dc",
  appAccessKey:
    "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJhcHAiOiJkY2giLCJpYXQiOjE2MTQ2MzY2NDN9.RiymS2cONQDH4u1iqFXHG12tspNz6mIWpaBnAIg2WIQ",
  appAccessHeader: "x-app-access",
  authHeader: "x-auth-token",
  mediaUrl: "http://c3store.nyc3.digitaloceanspaces.com",
  stripePublishableKey: "pk_test_8Y0r72Rf1tyQeQPFlPdhEGus",
  nsfwImageLink: "dch/nft/nsfw.png",
};

export default config;
