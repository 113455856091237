import React, { useState } from "react";
import Header from "./components/header";
import config from "../../config";
import WalletContainer from "./walletContainer";

const Main = (props) => {
  const { handleSetAlert } = props;

  const [view, setView] = useState("grid");
  const [activeTab, setActiveTab] = useState("1");

  const handleView = (currentView) => {
    setView(currentView);
  };

  return (
    <div className="general-game-container nft-page-container">
      <Header
        game="NFT"
        gameLogo={""}
        // onHandleView={handleView}
        activeTab={activeTab}
        setActiveTab={setActiveTab}
        // view={view}
        setView={setView}
      />
      <div className="tabContainer">
        <div className={activeTab === "1" ? "" : "noneTab"}>
          <WalletContainer handleSetAlert={handleSetAlert} />
        </div>
        <div className={activeTab === "2" ? "" : "noneTab"}>
          <h1>Stocks</h1>
        </div>
      </div>
    </div>
  );
};

export default Main;
